import React from 'react'
import PropTypes from 'prop-types'
import { div, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  label: {
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightLight,
    fontSize: '14px',
    textTransform: normalCase => (normalCase ? 'normal' : 'uppercase'),
  },
  content: {
    color: theme.palette.secondary.darkest,
    fontSize: theme.typography.fontSize,
    textTransform: normalCase => (normalCase ? 'normal' : 'capitalize'),
  },
}))

const InfoText = ({ className, label, children, normalCase }) => {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.label}>{label}</div>
      <div className={classes.content}>{children}</div>
    </div>
  )
}

InfoText.propTypes = {}

export default InfoText
