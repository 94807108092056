import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import MuiButton from '@material-ui/core/Button';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    textTransform: 'none',
    width: 200,
    height: 48,
    fontSize: 16,
    fontWeight: 600,
    background: theme.palette.primary.main,
    color: theme.palette.text.alternate,
  },
}));

const Button = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <MuiButton 
      variant="contained" 
      {...props}
      className={clsx(classes.root, className)}
    />
  );
}

Button.propTypes = {
};

export default Button;
