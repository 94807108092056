import React from 'react'
import { Route } from 'mobx-router'
import store from '../../store'
// components
import Login from './users/Login'
import Wizard from './wizard/Start.jsx'
import CaseLoad from './case-load/CaseLoad.jsx'
import Dashboard from './dashboard/Dashboard.jsx'
import TempComponentList from '../../TempComponentList.jsx'
import Navbar from '../components/navbar/Navbar.jsx'
import Notifications from './notifications/Notifications.jsx'
import TempCreateWizard from '../../TempCreateWizard.jsx'
import NotFound from 'app/views/NotFound.jsx'

function AuthRoute (routeObject) {
  const originalBeforeEnter = routeObject.beforeEnter
  routeObject.beforeEnter = (route, params, store) => {
    if (window.app && window.app.jwtToken) {
      if (originalBeforeEnter) {
        originalBeforeEnter(route, params, store)
      }
    } else {
      store.router.goTo(views.login, null, store)

      return false
    }
  }

  return new Route(routeObject)
}

function getWrappedComponent (component, navbarProps) {
  return (
    <>
      <Navbar {...navbarProps} />
      {component}
    </>
  )
}

const views = {
  login: new Route({
    path: '/',
    component: <Login />,
    beforeEnter: (route, params, store) => {
      if (window.app && window.app.jwtToken) {
        store.router.goTo(views.caseLoad, null, store)

        return false
      }
    }
  }),
  wizard: new AuthRoute({
    path: '/new-case',
    component: getWrappedComponent(<Wizard />, { showNewCase: false, showCaseList: true }),
    beforeEnter: (route, params, store) => {
      const { caseStore } = store
      caseStore.reset()
    }
  }),
  case: new AuthRoute({
    path: '/case/:id',
    component: getWrappedComponent(<Dashboard />, { showNewCase: true, showCaseList: true }),
    beforeEnter: (route, params, store) => {
      const { caseStore, activityStore } = store
      caseStore.fetch(params.id)
      activityStore.fetchCaseActivities(params.id)
      activityStore.fetchUserNotifications()
    }
  }),
  caseLoad: new AuthRoute({
    path: '/case',
    component: getWrappedComponent(<CaseLoad />, { showNewCase: true, showCaseList: false }),
    beforeEnter: (route, params, store) => {
      const { caseStore } = store
      caseStore.fetchList()
    },
    beforeExit: (route, params, store) => {
      const { caseStore } = store
      caseStore.resetList()
    }
  }),
  notifications: new AuthRoute({
    path: '/notifications',
    component: getWrappedComponent(<Notifications />, { showNewCase: true, showCaseList: true }),
  }),
  tempComponentList: new AuthRoute({
    path: '/cl/:state?',
    component: <TempComponentList />
  }),
  TestWizard: new AuthRoute({
    path: '/wtest',
    component: <TempCreateWizard />
  }),
  catchAll: new AuthRoute({
    path: '/:unknown_url',
    component: getWrappedComponent(<NotFound />, { showNewCase: true, showCaseList: true }),
  })
}

function gotoRoute (routeName, params) {
  store.router.goTo(routeName, params, store)
}

export const goto = {
  newCase () {
    gotoRoute(views.wizard)
  },

  caseLoad () {
    gotoRoute(views.caseLoad)
  },

  case (id) {
    gotoRoute(views.case, { id })
  },

  notifications () {
    gotoRoute(views.notifications)
  },

  login () {
    gotoRoute(views.login)
  }
}

export default views
