export default ({ theme, white, attach, nest, ICON, INPUT_ADORNMENT }) => ({
  MuiInput: {
    root: {
      [`& .${ICON.root}, & input::placeholder`]: {
        color: '#657786',
        opacity: 1
      },
      '&$focused': {
        backgroundColor: white.text,
        [`& input, & .${ICON.root}`]: {
          color: theme.palette.primary.main
        }
      },
      '& placeholder': {
        textOverflow: 'ellipsis !important'
      }
    }
  },
  MuiInputAdornment: {
    positionStart: {
      [attach(INPUT_ADORNMENT.root)]: {
        [nest(ICON.root)]: {
          marginLeft: 12,
          fontSize: 20
        }
      }
    }
  }
})
