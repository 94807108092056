import { createMuiTheme } from '@material-ui/core/styles'
import classes from '../core/classes'

const primary = {
  main: '#443699'
}

const secondary = {
  main: '#e5e5e5',
  dark: '#d3d4d8',
  darker: '#a0a4b1',
  darkest: '#232735'
}

const background = {
  primary: '#443699'
}

const text = {
  primary: '#443699',
  secondary: '##646470',
  alternate: '#FFFFFF',
  disabled: '#a0a4b1',
  black: '#000'
}

const theme = createMuiTheme({
  typography: {
    fontSize: 16,
    fontWeightRegular: 500,
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Source Sans Pro', '"Lato"', 'sans-serif'].join(','),
    body1: {
      fontSize: '1rem'
    }
  },
  palette: {
    background,
    primary,
    secondary,
    text
  },
  spacing: 4,
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  }
})

const white = {
  text: '#ffffff',
  primary: 'rgba(255, 255, 255, 0.7)',
  secondary: 'rgba(255, 255, 255, 0.54)',
  disabled: 'rgba(255, 255, 255, 0.38)',
  hint: 'rgba(255, 255, 255, 0.24)'
}

const red = {
  main: '#ff5252',
  dark: '#e04848'
}

const linked = {
  cursor: 'pointer',
  color: primary.main,
  display: 'inline-block'
}

const linkInverted = {
  ...linked,
  color: white.primary,
  '&:hover': {
    color: theme.palette.common.white
  }
}

export default {
  ...classes,
  theme,
  primary,
  red,
  linked,
  linkInverted,
  white
}
