import React from "react";
import { Slider } from '@material-ui/core';
import { withStyles, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const StyledSlider = withStyles({
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "#fff",
    border: "2px solid"
  },
  track: {
    height: "2px",
    border: "2px solid",
    borderRadius: "3px"
  }
})(Slider);


const useStyles = makeStyles(theme => ({
  root: {
  },
  mark: {
    fontSize: 12,
  },
}));

const sliderPoints = [
  {text: 'Mellow', value: 'mellow'},
  {text: 'Low', value: 'low', style: {marginLeft: "-18px"}},
  {text: 'Medium', value: 'medium'},
  {text: 'High', value: 'high', style: {marginRight: "-14px"}},
  {text: 'Critical', value: 'critical'},
]

const CustomSlider = ({onChange, initialValue}) => {

  const initIndex = sliderPoints.findIndex((elem) => elem.value === initialValue)
  let [value, setValue] = React.useState(initIndex);

  const classes = useStyles();

  const onSliderChange = (event, index) => {
    if (value === index) {
      return
    }
    setValue(index);
    const sliderState = sliderPoints[index].value
    onChange(sliderState)
  };

  const marks = () => {
    return [...Array(5)].map((i, index) =>
      (<Grid key={index} item className={classes.mark}>|</Grid>)
    )
  }

  return (
    <React.Fragment>
      <Grid container justify="space-between">
        {sliderPoints.map((point, index) => (
          <Grid item key={point.value} >
            <small  
              onClick={() => onSliderChange(null, index)}
              style={point.style}
            >
              {point.text} 
            </small>
          </Grid>
        ))
        }
      </Grid>
      <Grid container justify="space-between" spacing={3}>
        {marks()}
      </Grid>
      <StyledSlider
        component="div"
        value={value}
        min={0}
        max={4}
        step={1}
        onChange={onSliderChange}
      />
    </React.Fragment>
  );
};

export default CustomSlider;
