import { observable, action } from 'mobx'

const emptyCaseDetailsInitialValues = {
  policyNumber: '',
  caseNumber: '',
  patientName: '',
  dateOfBirth: null,
  homeAddress: '',
  policyStatus: 'pending',
  condition: '',
  otherDetails: '',
  hospital: '',
  dateOfAdmission: null,
  hospitalRelations: 'preferred',
  coverage: 'pending',
  currency: 'USD',
  incurredCharges: [],
  notify: {
    case_manager: false,
    medical_director: false,
    operations: false,
  },
  owner: {
    id: null,
    avatar: null,
    name: null,
    role: null,
  },
  ownerId: null,
  urgency: 'medium', // mellow, low, medium, high, critical,
  notes: '',
  strategies: [],
  activitiesAndComments: [],
  additionalMembers: [],
}

class CaseStore {
  caseList = []
  @observable isCaseListLoaded = false

  constructor (api) {
    this.api = api
    this.case = observable.map({})
    this.reset()
  }

  @action reset () {
    this.set(emptyCaseDetailsInitialValues)
  }

  @action set (caseData) {
    this.case.replace(caseData)
  }

  @action async fetch (id) {
    const fetchedCase = await this.api.icase.getCase(id)
    this.case.merge(fetchedCase)
  }

  @action async resetList () {
    this.caseList = []
  }

  @action async fetchList () {
    this.isCaseListLoaded = false
    this.caseList = await this.api.icase.getCaseList()
    this.isCaseListLoaded = true
  }

  @action merge (details) {
    this.case.merge(details)
  }

  @action async save () {
    const caseData = this.case.toJSON()
    delete caseData.__typename
    const fetchedCase = await this.api.icase.save(caseData)
    this.set(fetchedCase)
  }

  @action async addStrategy (strategyType) {
    const caseId = this.case.get('id')
    const fetchedCase = await this.api.icase.addStrategy(caseId, strategyType)
    this.case.set('strategies', fetchedCase.strategies)
  }

  @action async saveEvent (event, strategyId) {
    const caseId = this.case.get('id')
    if (strategyId) {
      event.strategyId = strategyId
    }
    const updatedCase = await this.api.icase.saveEvent(caseId, event)
    this.case.set('strategies', updatedCase.strategies)
  }

  @action async deleteEvent (eventId) {
    const caseId = this.case.get('id')
    const updatedCase = await this.api.icase.deleteEvent(caseId, eventId)
    this.case.set('strategies', updatedCase.strategies)
  }

  @action async lockStrategy (strategyId) {
    const caseId = this.case.get('id')
    const updatedCase = await this.api.icase.lockStrategy(caseId, strategyId)
    this.case.set('strategies', updatedCase.strategies)
  }

  @action async deleteStrategy (strategyId) {
    const caseId = this.case.get('id')
    const updatedCase = await this.api.icase.deleteStrategy(caseId, strategyId)
    this.case.set('strategies', updatedCase.strategies)
  }

  @action async toggleLike (strategyId, likeType) {
    const caseId = this.case.get('id')
    const updatedCase = await this.api.icase.toggleLike(caseId, strategyId, likeType)
    this.case.set('strategies', updatedCase.strategies)
  }
}

export default CaseStore
