import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  mapImg:{
    display: 'block',
    position: 'relative',
    '& img': {
      width: '100%',
    },
    '& .map-marker': {
      position: 'absolute',
      borderRadius: '100%',
      backgroundColor: 'red',
      width: '20px',
      height: '20px',
      cursor: 'pointer',
    },
    '& .marker1': {
      top: 83,
      left: 243,
    },
    '& .marker2': {
      top: 217,
      left: 379,
    },
    '& .marker3': {
      top: 500,
      left: 537,
    },
  },
  '&.active mapImg': {
    color:'red',
  },
}));

