import React, { Fragment } from 'react'
import clsx from 'clsx'
import { observer, inject } from 'mobx-react'
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import GraphCell from '../GraphCell.jsx'
import UrgencyIcon from '../UrgencyIcon.jsx'
import CaseLoadTableStyle from './CaseLoadTableStyle.jsx'
import CheckBox from '../CheckBox.jsx'
import StarIcon from '../../../assets/icons/StarIcon.jsx'
import StarIconFilled from '../../../assets/icons/StarIconFilled.jsx'
import CalendarIcon from '../../../assets/icons/CalendarIcon.jsx'
import SortIcons from '../../../assets/icons/SortIcons.jsx'
import { formatStringDate, formatDate } from '../../../../helpers/dates.js'
import { moneyDispaly } from '../../../../helpers/numbers.js'
import { differenceInDays, differenceInCalendarDays } from 'date-fns'

const CaseLoadTable = ({ setPreviewProps, isCaseListLoaded, caseList, gotoCase, onCheckBoxChanged }) => {
  const classes = CaseLoadTableStyle()
  let [selectedRows, setSelectedRows] = React.useState({})

  function onBodyClick (e) {
    // e.persist()
    const element = e.target.closest('[data-type]')
    if (!element) {
      return
    }
    const type = element.getAttribute('data-type')
    const id = element.closest('[data-id]').getAttribute('data-id')
    switch (type) {
      case 'check':
        if (selectedRows[id]) {
          delete selectedRows[id]
        } else {
          selectedRows[id] = true
        }
        onCheckBoxChanged(selectedRows)
        break
      case 'los':
        setPreviewProps({
          element: element,
          caseId: id,
        })
        break
      case 'favorite':
        toggleFavoriteClick(e)
        break
      case 'row':
        gotoCase(id)
        break
    }
  }
  const handlePreviewClose = () => {
    setPreviewProps(null)
  }

  function toggleFavoriteClick (e) {
    const element = e.target.closest('.starIconWrap')
    element.classList.toggle('active')
  }

  function getEstimatedCharges (row) {
    if (row.strategies && row.strategies[0] && row.strategies[0].cost) {
      const cost = row.strategies[0].cost

      return moneyDispaly(cost)
    }
  }
  const calculateTotalLos = strategies => {
    let totalLos
    if (strategies.length) {
      strategies.forEach(strategy => {
        if (strategy.isSelected) {
          totalLos = strategy.lengthOfStay
        }
      })
    }
    return totalLos
  }
  const calculateCurrentLos = (strategies, startDate) => {
    let totalLos
    if (strategies.length) {
      strategies.forEach(strategy => {
        if (strategy.isSelected) {
          totalLos = strategy.lengthOfStay
        }
      })
    }

    const currentLos = differenceInCalendarDays(new Date(), new Date(startDate))
    if (currentLos > totalLos) {
      return totalLos
    } else {
      return currentLos
    }
  }

  return (
    <Table className={clsx(classes.table)}>
      <TableHead>
        <TableRow>
          <TableCell size="small" className="medCellHide">
            <span className="line"></span>
          </TableCell>
          <TableCell size="small">
            <span className="line"></span>
          </TableCell>
          <TableCell>
            <span className="line"></span>
            CASE <SortIcons className="sortIcons" />
          </TableCell>
          <TableCell>
            <span className="line"></span>
            ADMISSION <SortIcons className="sortIcons" />
          </TableCell>
          <TableCell className="medCellHide">
            <span className="line"></span>
            NAME <SortIcons className="sortIcons" />
          </TableCell>
          <TableCell width="200">
            <span className="line"></span>
            LOS <SortIcons className="sortIcons" />
          </TableCell>
          <TableCell>
            <span className="line"></span>
            H <SortIcons className="sortIcons" />
          </TableCell>
          <TableCell>
            <span className="line"></span>
            CONDITION <SortIcons className="sortIcons" />
          </TableCell>
          <TableCell className="medCellHide">
            <span className="line"></span>
            LOCATION <SortIcons className="sortIcons" />
          </TableCell>
          <TableCell width="100">
            <span className="line"></span>
            EST <SortIcons className="sortIcons" />
          </TableCell>
          <TableCell>
            <span className="line"></span>
            URGENCY <SortIcons className="sortIcons" />
          </TableCell>
        </TableRow>
      </TableHead>
      {isCaseListLoaded && (
        <TableBody onClick={onBodyClick} data-type="body">
          {caseList.map((row, index) => {
            return (
              <TableRow key={row.id} data-id={row.id} data-type="row">
                <TableCell className="medCellHide">
                  <div className="colRow">
                    <div className="Icons">
                      <span className="icon-checkBox" data-type="check">
                        <CheckBox />
                      </span>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <span data-type="favorite">
                    <span className="starIconWrap">
                      <StarIconFilled />
                      <StarIcon />
                    </span>
                  </span>
                </TableCell>
                <TableCell data-id={row.id}>{row.caseNumber}</TableCell>
                <TableCell className="dateColumn">{formatStringDate(row.dateOfAdmission)}</TableCell>
                <TableCell className="medCellHide">{row.patientName}</TableCell>
                <TableCell data-type="los">
                  <GraphCell
                    los={calculateCurrentLos(row.strategies, row.dateOfAdmission)}
                    totalLos={calculateTotalLos(row.strategies)}
                    urgency={row.urgency}
                    id={row.id}
                  />
                </TableCell>
                <TableCell>
                  <span className={`hospitalRelations ${row.hospitalRelations}`}></span>
                </TableCell>
                <TableCell>{row.condition.label}</TableCell>
                <TableCell className="medCellHide">{row.homeAddress}</TableCell>
                <TableCell>${getEstimatedCharges(row)}</TableCell>
                <TableCell>
                  <UrgencyIcon level={row.urgency} />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      )}
    </Table>
  )
}

export default CaseLoadTable
