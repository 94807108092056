import icase from './graphql/icase/icase'
import activity from './graphql/activity/activity'
import { getHospitals, getConditions, getUsers } from './graphql/general/general'
import auth from './auth'
import comment from './graphql/comment/comment'

const api = {
  auth,
  icase,
  activity,
  getHospitals,
  getConditions,
  getUsers,
  comment,
}

export default api
