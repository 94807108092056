import { addDays, parse, format, differenceInCalendarDays, formatDistanceToNow, parseISO, differenceInHours, subDays } from 'date-fns'
import { DATE_FORMAT, ACTIVITY_DATE_FORMAT } from '../constants'

export function formatStringDate (date) {
  return format(new Date(date), DATE_FORMAT)
}

export function formatDate (date) {
  return format(date, DATE_FORMAT)
}

export function strategyDay (currentDate, startDate) {
  return 1 + differenceInCalendarDays(currentDate, startDate)
}

export function getDateFromStrategyDay (startDate, day) {
  return addDays(startDate, day - 1)
}

export function formatActivityDate (date) {
  let now = Date.now()
  let difference = differenceInHours(now, parseISO(date))
  if (difference > 23) {
    return format(parseISO(date), ACTIVITY_DATE_FORMAT)
  } else {
    return `${formatDistanceToNow(parseISO(date))} ago`
  }
}