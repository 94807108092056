import React from 'react';
import clsx from 'clsx'

function ListIcon({ className }) {

  return (
    <span className={clsx('icon-listIcon', className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z" />
       </svg>
    </span>
  )
}

export default ListIcon;


