import http from 'axios'
import { handleApiErrors } from '../apiHelpers'
const host = process.env.REACT_APP_API_HOST

const login = async (email, password) => {
  const url = `${host}/api/v1/auth/login`
  try {
    const response = await http.post(url, { email, password })
    return response.data
  } catch (error) {
    handleApiErrors(`error in ${url} `, error)
  }
}

export default {
  login
}
