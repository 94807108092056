import React, { useState } from 'react'
import GridContainer from '../../../layout/GridContainer'
import { Button, Paper, List, ListItem } from '@material-ui/core'
import GridItem from '../../../layout/GridItem'
import useStyles from './StrategiesStyles'
import { inject, observer } from 'mobx-react'
import { Lens } from '@material-ui/icons'
import { strategyTypes } from '../../../../constants'
import { numbersPrettier } from '../../../../helpers/numbers.js'

const Strategies = inject('caseStore')(
  observer(({ caseStore }) => {
    const classes = useStyles()
    const caseData = caseStore.case.toJSON()

    const [showStrategies, setShowStrategies] = useState(false)
    const getStrategyCostAndLos = type => {
      let cost, lengthOfStay
      switch (type) {
        case 'repatriate':
          cost = caseData.repatriateCost
          lengthOfStay = caseData.repatriateLOS
          return { cost, lengthOfStay }

        case 'transfer':
          cost = caseData.transferCost
          lengthOfStay = caseData.transferLOS
          return { cost, lengthOfStay }

        case 'evacuate':
          cost = caseData.evacuateCost
          lengthOfStay = caseData.evacuateLOS
          return { cost, lengthOfStay }

        default:
          break
      }
    }
    const strategies = [strategyTypes.REPATRIATE, strategyTypes.EVACUATE, strategyTypes.TRANSFER, strategyTypes.CUSTOM]
    const addStrategy = strategyType => {
      caseStore.addStrategy(strategyType)
    }

    return (
      <GridContainer>
        <GridItem>
          <Button
            className={classes.button}
            onClick={() => {
              setShowStrategies(!showStrategies)
            }}
          >
            Add Strategy
          </Button>
        </GridItem>
        <GridItem>
          {showStrategies ? (
            <Paper elevation={1} className={classes.root}>
              <List className={classes.list}>
                {strategies.map(strategyType => {
                  let lengthOfStay, cost
                  if (caseData) {
                    const costAndLos = getStrategyCostAndLos(strategyType)
                    if (costAndLos) {
                      cost = costAndLos.cost
                      lengthOfStay = costAndLos.lengthOfStay
                    }
                  }

                  return (
                    <div key={strategyType} className={classes.listItemContainer}>
                      <ListItem
                        className={classes.listItem}
                        onClick={() => {
                          addStrategy(strategyType)
                        }}
                      >
                        <span className={classes.listName}>{strategyType}</span>
                        {lengthOfStay && (
                          <span>
                            <span>
                              <Lens className={classes.greyCircle} />
                            </span>
                            {lengthOfStay} days
                            <span>
                              <Lens className={classes.greyCircle} />
                            </span>
                            ${numbersPrettier(cost)}
                          </span>
                        )}
                      </ListItem>
                      {strategies.indexOf(strategyType) !== strategies.length - 1 ? (
                        <hr className={classes.divider} />
                      ) : (
                          <div className={classes.divider} />
                        )}
                    </div>
                  )
                })}
              </List>
            </Paper>
          ) : null}
        </GridItem>
      </GridContainer>
    )
  })
)

export default Strategies
