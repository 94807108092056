import gql from 'graphql-tag'


export const GET_CASE_ACTIVITIES_QUERY = gql`
    query($caseId: ID!){
      getCaseActivities(caseId: $caseId) {
        activities
      }
    }`

export const GET_USER_NOTIFICATIONS_QUERY = gql`
    query {
        getUserNotifications {
          activities
        }
      }`

export const MARK_ACTIVITY_AS_READ = gql`
    mutation ($activityId: ID!){
        markActivityAsRead (activityId: $activityId) {
          activities
        }
      }`

export const DELETE_NOTIFICATION = gql`
    mutation ($activityId: ID!){
        deleteNotification (activityId: $activityId) {
          activities
        }
      }`
