import React from 'react';
import clsx from 'clsx'

function ClipboardList({ className }) {

  return (
    <span className={clsx('actionIcon', className)}>
      <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 3V24H0V3H6C6 2.58594 6.07812 2.19922 6.23438 1.83984C6.39062 1.47266 6.60547 1.15234 6.87891 0.878906C7.15234 0.605469 7.46875
         0.390625 7.82812 0.234375C8.19531 0.078125 8.58594 0 9 0C9.41406 0 9.80078 0.078125 10.1602 0.234375C10.5273 0.390625 10.8477 0.605469 11.1211 
         0.878906C11.3945 1.15234 11.6094 1.47266 11.7656 1.83984C11.9219 2.19922 12 2.58594 12 3H18ZM4.5 6H13.5V4.5H10.5V3C10.5 2.78906 10.4609 
        2.59375 10.3828 2.41406C10.3047 2.23438 10.1953 2.07812 10.0547
         1.94531C9.92188 1.80469 9.76562 1.69531 9.58594 1.61719C9.40625 1.53906 9.21094 1.5 9
        1.5C8.78906 1.5 8.59375 1.53906 8.41406 1.61719C8.23438 1.69531 8.07422 1.80469 7.93359 1.94531C7.80078 2.07812 7.69531 2.23438 7.61719 
        2.41406C7.53906 2.59375 7.5 2.78906 7.5 3V4.5H4.5V6ZM16.5 4.5H15V7.5H3V4.5H1.5V22.5H16.5V4.5ZM6 10.5H15V12H6V10.5ZM6 15H15V16.5H6V15ZM6 
        19.5H15V21H6V19.5ZM3 10.5H4.5V12H3V10.5ZM3 15H4.5V16.5H3V15ZM3 19.5H4.5V21H3V19.5Z" fill="#443699"/>
      </svg>
    </span>
  )
}

export default ClipboardList;

