import React from 'react'
// import scriptLoader from "react-async-script-loader";

export const API_KEY = 'AIzaSyCbwtIOyXDICwg12ZcmP-qgs7yumJcje1Q'
const LoaderView = () => {
  return <div> Loading Your app ...</div>
}

// ERROR REGARDING LOADING MULTIPLE GOOGLE APIS WAS THIS.

// const ScriptLoader = scriptLoader([
//   `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`
// ])(LoaderView);

const AppScriptLoader = props => {
  const [showComponent, setShowComponent] = React.useState(false)

  const { children } = props

  const onScriptLoaded = () => {
    setShowComponent(true)
  }

  const appView = () => {
    // UNSURE OF HOW YOU WANT THIS NOW
    // if (!showComponent) {
    //   return <ScriptLoader onScriptLoaded={onScriptLoaded} />;
    // } else {
    return children
    // }
  }

  return appView()
}

export default AppScriptLoader
