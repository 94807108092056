import React from 'react'
import { makeStyles, Box, Paper } from '@material-ui/core'
import GridContainer from 'app/layout/GridContainer'
import GridItem from 'app/layout/GridItem'

const NotFound = () => {
  return (
    <GridContainer justify="center">
      <GridItem >
        <Paper>
          <Box p={30} mt={3}>
            <h2> Sorry, Page is not found </h2>
          </Box>
        </Paper>
      </GridItem>
    </GridContainer>
  )
}

export default NotFound
