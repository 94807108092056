import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  root: {
  },
  AttachIcon:{
     marginRight:15,
     marginTop:4,
  },
});

function AttachIcon({ className }) {
  const classes = useStyles();
  return (
    <span className={clsx(classes.AttachIcon, className)}>
        <svg width="14" height="30" viewBox="0 0 14 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.5 5.625V23.4375C13.5 24.3457 13.3242 25.2002 12.9727 26.001C12.6309 26.792 12.1621 27.4854 11.5664 28.0811C10.9805 28.667 10.2871 29.1357 
          9.48633 29.4873C8.69531 29.8291 7.8457 30 6.9375 30C6.0293 30 5.1748 29.8291 4.37402 29.4873C3.58301 29.1357 2.88965 28.667 2.29395 28.0811C1.70801 27.4854 
          1.23926 26.792 0.887695 26.001C0.545898 25.2002 0.375 24.3457 0.375 23.4375V4.6875C0.375 4.04297 0.49707 3.4375 0.741211 2.87109C0.995117 2.30469 1.33203
          1.81152 1.75195 1.3916C2.18164 0.961914 2.67969 0.625 3.24609 0.380859C3.8125 0.126953 4.41797 0 5.0625 0C5.70703 0 6.3125 0.126953 6.87891 0.380859C7.44531
          0.625 7.93848 0.961914 8.3584 1.3916C8.78809 1.81152 9.125 2.30469 9.36914 2.87109C9.62305 3.4375 9.75 4.04297 9.75 4.6875V23.4375C9.75 23.8281 9.67676 
          24.1943 9.53027 24.5361C9.38379 24.8779 9.18359 25.1758 8.92969 25.4297C8.67578 25.6836 8.37793 25.8838 8.03613 26.0303C7.69434 26.1768 7.32812 26.25
          6.9375 26.25C6.54687 26.25 6.18066 26.1768 5.83887 26.0303C5.49707 25.8838 5.19922 25.6836 4.94531 25.4297C4.69141 25.1758 4.49121 24.8779 4.34473 
          24.5361C4.19824 24.1943 4.125 23.8281 4.125 23.4375V7.5H6V23.4375C6 23.6914 6.09277 23.9111 6.27832 24.0967C6.46387 24.2822 6.68359 24.375 6.9375
          24.375C7.19141 24.375 7.41113 24.2822 7.59668 24.0967C7.78223 23.9111 7.875 23.6914 7.875 23.4375V4.6875C7.875 4.29687 7.80176 3.93066 7.65527 
          3.58887C7.50879 3.24707 7.30859 2.94922 7.05469 2.69531C6.80078 2.44141 6.50293 2.24121 6.16113 2.09473C5.81934 1.94824 5.45312 1.875 5.0625 1.875C4.67188 
          1.875 4.30566 1.94824 3.96387 2.09473C3.62207 2.24121 3.32422 2.44141 3.07031 2.69531C2.81641 2.94922 2.61621 3.24707 2.46973 3.58887C2.32324 3.93066 2.25 
          4.29687 2.25 4.6875V23.4375C2.25 24.082 2.37207 24.6875 2.61621 25.2539C2.87012 25.8203 3.20703 26.3184 3.62695 26.748C4.05664 27.168 4.55469 27.5049
          5.12109 27.7588C5.6875 28.0029 6.29297 28.125 6.9375 28.125C7.58203 28.125 8.1875 28.0029 8.75391 27.7588C9.32031 27.5049 9.81348 27.168 10.2334 26.748C10.6631 26.3184 11 25.8203 
          11.2441 25.2539C11.498 24.6875 11.625 24.082 11.625 23.4375V5.625H13.5Z" fill="#443699"/>
        </svg>
    </span>
  )
}

export default AttachIcon;

