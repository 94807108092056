import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: -8,
  },
  label: {
    marginBottom: 8,
    color: '#232735',
  },
  button: {
    textTransform: 'none',
    height: 27,

    minWidth: 70,
    color: '#6c6c6c',
    backgroundColor: '#e5e5e5',
    fontSize: 14,
    '&:hover': {
      backgroundColor: '#c3c3c3',
    },
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#FFF',
    },
  },
  leftButton: {
    '&.Mui-selected': {
      color: '#FFF',
      backgroundColor: '#e96158',
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#d84d44',
    },
    '&:first-child': {
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
    }
  },
  rightButton: {
    '&.Mui-selected': {
      backgroundColor: '#68c1b4',
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#41bd9c',
    },
    '&:last-child': {
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
    }
  },
}))

export default useStyles
