export default ({ nest, primary, NOTCHED_OUTLINE }) => ({
  MuiInputLabel: {
    root: {
      fontSize: 16
    },
    outlined: {
      transform: 'translate(14px, 14px) scale(1)'
    }
  }
})
