export const WIZARD_NEXT_STATE = 'WIZARD_NEXT_STATE'

// WIZARD STEPS
export const WIZARD_PATIENT_AND_POLICY = 'WIZARD_PATIENT_AND_POLICY'
export const WIZARD_SET_POLICY = 'WIZARD_SET_POLICY'
export const WIZARD_ADMISSION_AND_CLINICAL = 'WIZARD_ADMISSION_AND_CLINICAL'
export const WIZARD_SET_COVERAGE = 'WIZARD_SET_COVERAGE'
export const WIZARD_INCURRED_CHARGES = 'WIZARD_INCURRED_CHARGES'
export const WIZARD_STATUS = 'WIZARD_STATUS'
export const WIZARD_SUBMISSION = 'WIZARD_SUBMISSION'
export const WIZARD_SUMMARY = 'WIZARD_SUMMARY'

export const wizardStates = [
  WIZARD_PATIENT_AND_POLICY,
  WIZARD_SET_POLICY,
  WIZARD_ADMISSION_AND_CLINICAL,
  WIZARD_SET_COVERAGE,
  WIZARD_INCURRED_CHARGES,
  WIZARD_STATUS,
  WIZARD_SUMMARY,
  WIZARD_SUBMISSION
]
