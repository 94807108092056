import { observable, action, computed } from "mobx"
import { getActivityText, getActivitiesText, getUserNotificationsWithText, getUserNotificationWithText } from "./activityMessageGenerator"
import { activityTypes } from './../../constants' // have to use relative path as 'constants' is a used module

class ActivityStore {
  caseId = ''
  @observable caseActivities = []
  @observable userNotifications = []

  constructor (api) {
    this.api = api
  }

  @action async fetchCaseActivities (caseId) {
    this.caseId = caseId
    const res = await this.api.activity.getCaseActivities(caseId)
    if (res) {
      this.caseActivities = getActivitiesText(res.activities)
    }
  }

  @computed get notificationCount () {
    const notificationCount = this.userNotifications.filter(notification => notification.isRead === false).length
    console.log('getNotificationCount', notificationCount, this.userNotifications.length)
    return notificationCount
  }

  @action async addActivity (activityObject) {
    if (activityObject.isNotification) {
      if (this._checkIfUserShouldRecieveNotification(activityObject)) {
        const newNotification = getUserNotificationWithText(activityObject)
        newNotification.isRead = false
        this.userNotifications.unshift(newNotification)
      }
    }
    if (activityObject.caseId == this.caseId) {
      this.caseActivities.push(getActivityText(activityObject))
    }
  }

  _checkIfUserShouldRecieveNotification (activity) {
    if (activity.type === activityTypes.OWNER_ASSIGNED) {
      if (activity.assignerId !== activity.ownerId && activity.ownerId === window.app.userData.userId) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  @action async fetchUserNotifications () {
    const res = await this.api.activity.getUserNotifications()
    if (res) {
      this.userNotifications = getUserNotificationsWithText(res.activities)
    }
  }

  @action async saveComment (caseId, comment) {
    this.caseId = caseId
    const newcomment = await this.api.comment.saveComment(caseId, comment)
  }

  @action async markNotificationAsRead (notificationId) {
    await this.api.activity.markActivityAsRead(notificationId)
    const userNotification = this.userNotifications.find(un => un.id == notificationId)
    if (userNotification) {
      userNotification.isRead = true
    }
  }

  @action async deleteNotification (notificationId) {
    await this.api.activity.deleteNotification(notificationId)
    const notification = this.userNotifications.find(n => n.id == notificationId)
    this.userNotifications.remove(notification)
  }

  @action async resetAll () {
    this.caseActivities = []
    this.userNotifications = []
  }
}

export default ActivityStore
