import _ from 'lodash'
import { observable, action } from "mobx"

const initCancelProps = {
  text: 'Are you sure you want to cancel?',
  cancelText: 'No',
  continueText: 'Yes'
}

class DialogStore {

  @observable component = null
  @observable showConfirmDialog = false
  @observable component = null
  @observable loadingDialogInfo = false 

  onContinue = null

  constructor() {
    this.confirmProps = _.clone(initCancelProps)
  }

  updateConfirmProps(newProps) {
    const props = newProps || {}
    this.confirmProps = _.assign({}, initCancelProps, props)
  }

  @action open (component) {
    this.component = component
  }

  @action close () {
    this.component = null 
    this.showConfirmDialog = false
  }

  @action openConfimrationDialog (onContinue, confirmProps) {
    this.updateConfirmProps(confirmProps)
    this.onContinue = onContinue
    this.showConfirmDialog = true
  }

  @action confirmationApproved () {
    if (this.onContinue) {
      this.onContinue()
    }
    this.closeConfimrationDialog()
  }

  @action closeConfimrationDialog () {
    this.onContinue = null 
    this.showConfirmDialog = false
  }

  @action openLoadingDialog (text) {
    this.loadingDialogInfo = {
      text: text || 'Saving ...'
    }
  }
  
  @action closeLoadingDialog () {
    this.loadingDialogInfo = false 
  }

}

export default DialogStore

