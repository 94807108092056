import React from 'react';
import clsx from 'clsx'

function TransferIcon({ className }) {
  return (
    <span className={clsx('icon-TransferIcon', className)}>
      <svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.65773 21.7533L9 22.3276L8.34227 21.7533C2.81131 16.9237 0 12.7068 0 9C0 3.75066 
        4.09705 0 9 0C13.9029 0 18 3.75066 18 9C18 12.7068 15.1887 16.9237 9.65773 21.7533ZM2 9C2 11.8492 4.30661 15.4335 9 19.6634C13.6934 15.4335
        16 11.8492 16 9C16 4.8966 12.8359 2 9 2C5.16411 2 2 4.8966 2 9ZM4 10H10.5858L8.29289 12.2929L9.70711 13.7071L14.4142 9L9.70711
        4.29289L8.29289 5.70711L10.5858 8H4V10Z" fill="#443699"/>
      </svg>
    </span>
  )
}

export default TransferIcon;


