import 'date-fns'
import React from 'react'
import PropTypes from 'prop-types'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { makeStyles, FormControl, FormLabel, Icon } from '@material-ui/core'
import { DATE_FORMAT } from '../../constants.js'


const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: '5px',
    color: '#232735',
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
  input: {
    display: 'flex',
    padding: 0,
    height: '48px',
    boxSizing: 'border-box',
    borderRadius: '4px',
  },
}))

const RcDatePicker = ({
  name,
  formik,
  required,
  formlabel,
  ...rest
}) => {

  const classes = useStyles()

  const initialValue = formik && formik.values &&  formik.values[name] || null

  const [selectedDate, setSelectedDate] = React.useState(initialValue)

  const handleDateChange = date => {
    if (formik && !date || date == 'Invalid Date') {
      formik.setFieldValue(name, null)
      return
    }

    setSelectedDate(date)
    if (formik) {
      formik.setFieldTouched(name, true, false)
      formik.setFieldValue(name, date)
    }
  }

  const validationProps = () => {
    if (required && formik) {
      return {
        helperText: formik.touched[name] ? formik.errors[name] : '',
        error: formik.touched[name] && Boolean(formik.errors[name]),
        touched: formik.touched[name] ? 'true' : 'false',
      }
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <FormControl fullWidth >
        <FormLabel className={classes.label}> {formlabel}</FormLabel>
        <KeyboardDatePicker
          {...validationProps()}
          {...rest}
          name={name}
          value={selectedDate}
          onChange={handleDateChange}
          clearable
          autoOk
          InputProps={{ className: classes.input }}
          placeholder="Please input date"
          inputVariant="outlined"
          type="text"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          format={DATE_FORMAT}
          minDate={new Date('01/01/1900')}
        />
      </FormControl>
    </MuiPickersUtilsProvider>
  )
}

RcDatePicker.propTypes = {}

export default RcDatePicker
