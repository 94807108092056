import gql from 'graphql-tag'

const inputCaseFields = `
  id
  policyNumber
  caseNumber
  patientName
  dateOfBirth
  homeAddress
  policyStatus
  condition
  otherDetails
  hospital
  dateOfAdmission
  coverage
  currency
  strategies
  incurredCharges
  notify
  ownerId
  additionalMembers
  urgency
  notes
`

const getCaseFields = `
  ${inputCaseFields}
  hospitalRelations
  strategies
  owner
  originalLengthOfStay
  predictedAAPrice
  activitiesAndComments
  repatriateCost
  repatriateLOS
  transferCost
  transferLOS
  evacuateCost
  evacuateLOS
  mcgGuideLines
`

const caseFields = getCaseFields

export const GET_LIST_QUERY = gql`
  {
    icases {
     ${caseFields}
    }
  }`

export const GET_CASE_QUERY = gql`
  query($id: ID!){
    icase(id: $id) {
      ${caseFields}
    }
  }`

export const SAVE_CASE = gql`
  mutation($icase: IcaseInput! ) {
    saveIcase(input: $icase) {
      ${caseFields}
    }
  }`

export const ADD_STRATEGY = gql`
  mutation($caseId: ID!,  $strategyType: String) {
    addStrategy(caseId: $caseId, strategyType: $strategyType) {
      ${caseFields}
    }
  }`

export const SAVE_EVENT = gql`
  mutation($caseId: ID, $event: JSON) {
    saveEvent(caseId:$caseId,  event: $event) {
      ${caseFields}
    }
  }`

export const DELETE_EVENT = gql`
  mutation($caseId: ID!, $eventId: ID!) {
    deleteEvent(caseId: $caseId,  eventId: $eventId) {
      ${caseFields}
    }
  }`

export const LOCK_STRATEGY = gql`
  mutation($caseId: ID!, $strategyId: ID!) {
    lockStrategy(caseId: $caseId,  strategyId: $strategyId) {
      ${caseFields}
    }
  }`

export const DELETE_STRATEGY = gql`
  mutation($caseId: ID!, $strategyId: ID!) {
    deleteStrategy(caseId: $caseId,  strategyId: $strategyId) {
      ${caseFields}
    }
  }`

export const TOGGLE_LIKE = gql`
  mutation($caseId: ID!, $strategyId: ID!,  $likeType: String!) {
    toggleLike(caseId: $caseId,  strategyId: $strategyId, likeType: $likeType) {
      ${caseFields}
    }
  }`
