import React from 'react'

function UrgencyIcon({ level }) {

  const levels = {
    'mellow': '#98989C',
    'low': '#98989C',
    'medium': '#98989C',
    'high': '#FF0000',
    'critical': '#F78E53',
  }

  const inlineStyles = {
    color: levels[level],
    textTransform: 'capitalize',
  }

  return (
    <h4 style={inlineStyles}>{level}</h4>
  )

}

export default UrgencyIcon
