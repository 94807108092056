import React from 'react'
import { observer, inject } from 'mobx-react'
import { Dialog } from "@material-ui/core";

const ComponentDialog = inject('dialogStore')(
  observer(({ dialogStore }) => {

  return (
    <Dialog open={dialogStore.component}>
      {dialogStore.component}
    </Dialog>
  )
}))

export default ComponentDialog
