import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  Burger: {
    'padding-left': '30px',
    'padding-top': '5px',
    '& img': {
      color: 'red',
    }
  },
  headerLogoWrap: {
    'text-align': 'center'
  },
  navBarTop: {
    backgroundColor: 'white',
    '& .MuiToolbar-gutters': {
      'min-height': '60px',
    }
  },
  buttons: {
    textAlign: 'right',
  },
  buttonsInner: {
    display: 'inline-flex',
    alignItems: 'center',
    '& .actionIcon': {
      display: 'inline-flex',
      alignItems: 'center',
      padding: 0,
      margin: 0,
    }
  },
  button: {
    color: '#F1F1F1',
    backgroundColor: '#68C1B4',
    padding: '4px 42px',
    '&:hover': {
      backgroundColor: '#109C93',
    }
  },
  actionIcon: {
    margin: '0 10px',
    height: 30,
    width: 30,
    cursor: 'pointer',
    '& img': {
      display: 'block',
    },
    '& .MuiBadge-colorPrimary': {
      backgroundColor: '#9e1a1a',
      fontWeight: 'bold',
      padding: '0px 5px',
    },
  },
  clipboardbtn: {
    padding: 0,
    '&:hover ': {
      backgroundColor: 'transparent'
    }
  },
  spacer: {
    margin: '0 10px',
    width: 44,
  }
}))

export default useStyles
