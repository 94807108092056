import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Typography, InputLabel, Checkbox, Button } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import useStyles from './EventFormStyles.jsx'
import GridContainer from 'app/layout/GridContainer.jsx'
import GridItem from 'app/layout/GridItem.jsx'
import Select from 'app/components/Select'
import TextField from 'app/components/TextField.jsx'
import DatePicker from 'app/components/RcDatePicker.jsx'
import { validateFrom } from 'helpers/formik-helpers.js'
import { numbersPrettier } from 'helpers/numbers.js'
import {
  eventTypes,
  eventTypesSelectOptions,
} from '../../../../../constants.js'
import { ALL_STRATEGIES_EVENT, UPDATE_EVENT } from './EventFormInitiator.jsx'

const getValidationSchemaByType = (eventType) => {
  const title = Yup.string('Please Enter Title')
    .required('Title is required')
  const cost = Yup.number('Please Enter Cost')
    .required('Cost is required')

  const validationTypes = {
    date: Yup.string('Please Enter EventForm Date')
      .nullable()
      .required('EventForm Date is required'),
    description: Yup.string('Please Enter Description'),
  }

  const addValidations = (additions) => {
    return Object.assign(validationTypes, additions)
  }

  switch (eventType) {
    case eventTypes.PROCEDURE:
      addValidations({ title })
    case eventTypes.OTHER:
      addValidations({ title })
    case eventTypes.COMMERCIAL_FLIGHT:
    // addValidations({ cost })
    case eventTypes.AIR_AMBULANCE:
    default:
      break
  }

  return Yup.object(validationTypes)
}

const EventForm = inject('caseStore')(
  observer(
    ({ caseStore, strategyId, event, date, buttonText, closeForm, action }) => {
      const classes = useStyles()

      const emptyEvent = {
        title: '',
        type: eventTypes.PROCEDURE,
        date,
        description: '',
        cost: null,
      }

      const initialEvent = event ? event : emptyEvent

      const [eventType, setEventType] = useState(initialEvent.type)

      const EventForm = ({ formikProps }) => {
        const [quoteFromAeroPlan, setQuoteFromAeroPlan] = useState(true)
        const [quote, setQuote] = useState()
        const [enableCostQuote, setEnableCostQuote] = useState(action === UPDATE_EVENT && event.cost)

        const onTypeChange = eventType => {
          formikProps.values.type = eventType
          setEventType(eventType)
        }

        const onSubmit = async () => {
          const isValid = await validateFrom(formikProps)
          if (isValid) {
            const eventData = formikProps.values
            if (action === ALL_STRATEGIES_EVENT) {
              await caseStore.saveEvent(eventData)
            } else {
              await caseStore.saveEvent(eventData, strategyId)
            }
            closeForm()
          }
        }

        const onDelete = async () => {
          await caseStore.deleteEvent(event.id)
          closeForm()
        }

        const TitleHtml = () => {
          return (
            <GridItem xs={12}>
              <InputLabel className={classes.label}>Title *</InputLabel>
              <TextField
                name="title"
                placeholder="Title.."
                className={classes.textField}
                fullWidth
                formik={formikProps}
              />
            </GridItem>
          )
        }

        const AssociatedCostTitle = () => {
          return (
            <GridItem>
              <Typography className={classes.viewSegmentLabel}>
                Associated Cost
              </Typography>
            </GridItem>
          )
        }

        const AddCost = () => {
          return (
            <GridContainer item>
              <GridItem xs={1}>
                <Checkbox
                  className={classes.checkBox}
                  color="primary"
                  onChange={() => {
                    setEnableCostQuote(!enableCostQuote)
                  }}
                  checked={enableCostQuote}
                />
              </GridItem>
              <GridItem xs={7}>
                <TextField
                  disabled={!enableCostQuote}
                  name="cost"
                  className={classes.TextField}
                  placeholder="$000,000"
                  type="number"
                  formik={formikProps}
                />
              </GridItem>
            </GridContainer>
          )
        }

        const GetAeroPlanQuote = () => {
          return (
            <GridContainer item className={classes.text}>
              <GridItem xs={1}>
                <Checkbox
                  className={classes.checkBox}
                  color="primary"
                  onChange={() => setQuoteFromAeroPlan(!quoteFromAeroPlan)}
                  checked={quoteFromAeroPlan}
                />
              </GridItem>
              <GridItem className={classes.Quote} xs={7}>
                Get quotes from Aero-plan
              </GridItem>
            </GridContainer>
          )
        }

        const PredictedQuote = () => {
          return (
            <GridItem>
              <Typography className={classes.text}>
                Predicted Quote: $
                {numbersPrettier(caseStore.case.get('predictedAAPrice') || 0)}
              </Typography>
            </GridItem>
          )
        }

        const chooseViewSegment = () => {
          console.log(eventType)
          switch (eventType) {
            case eventTypes.PROCEDURE:
              return TitleHtml()
            case eventTypes.AIR_AMBULANCE:
              return (
                <GridContainer item direction="column" spacing={2} xs={12}>
                  <AssociatedCostTitle />
                  <PredictedQuote />
                  <GetAeroPlanQuote />
                </GridContainer>
              )
            case eventType.AIR_AMBULANCE:
              return (
                <GridContainer item direction="column" xs={12}>
                  <AssociatedCostTitle />
                  {AddCost()}
                  <GetAeroPlanQuote />
                </GridContainer>
              )
            case eventTypes.OTHER:
              return (
                <GridContainer item direction="column" xs={12}>
                  <GridItem>{TitleHtml()}</GridItem>
                  <AssociatedCostTitle />
                  {AddCost()}
                </GridContainer>
              )
            case eventTypes.COMMERCIAL_FLIGHT:
              return (
                <GridContainer item direction="column" xs={12}>
                  <AssociatedCostTitle />
                  {AddCost()}
                  <GetAeroPlanQuote />
                </GridContainer>
              )
            default:
              break
          }
        }

        const isAdmissionOrDischarge = () => {
          return [eventTypes.ADMISSION, eventTypes.DISCHARGE].includes(eventType)
        }

        const getByEventType = () => {
          if (!isAdmissionOrDischarge()) {
            return (
              <React.Fragment>
                <GridItem xs={12}>
                  <Select
                    name="type"
                    label="Type"
                    options={eventTypesSelectOptions}
                    onSelect={onTypeChange}
                    value={eventType}
                  />
                </GridItem>
                {chooseViewSegment()}
                <hr className={classes.divider1} />
              </React.Fragment>
            )
          }
        }

        const getDateBox = () => {
          if(eventType !== eventTypes.ADMISSION) {
            return (
              <GridContainer
                className={classes.strategyAndTimeContainer}
                spacing={5}
              >
                <GridItem xs={12}>
                  <DatePicker
                    name="date"
                    formlabel="Date"
                    placeholder=""
                    required
                    fullWidth
                    formik={formikProps}
                  />
                </GridItem>
                <hr className={classes.divider2} />
              </GridContainer>
            )
          }
        }

        const DeleteButton = () => {
          if (action !== 'UPDATE_EVENT' || isAdmissionOrDischarge()) {
            return null
          } else {
            return (
              <GridItem>
                <Button className={classes.deleteButton} onClick={onDelete}>
                  <Delete />
                  Delete
                </Button>
              </GridItem>
            )
          }
        }

        const ActionButton = () => {
          return (
            <GridItem>
              <Button className={classes.button} onClick={onSubmit}>
                {buttonText}
              </Button>
            </GridItem>
          )
        }

        return (
          <GridContainer className={classes.root}>
            <hr className={classes.headerDivider} />
            <GridContainer className={classes.mainContainer} spacing={5}>
              {getByEventType()}
              {getDateBox()}
              <GridItem xs={12}>
                <InputLabel className={classes.label}>Description</InputLabel>
                <TextField
                  multiline
                  name="description"
                  fullWidth
                  rows={4}
                  placeholder="Event Description..."
                  formik={formikProps}
                />
              </GridItem>
              <GridContainer alignItems="center" justify="flex-end">
                <DeleteButton />
                <ActionButton />
              </GridContainer>
            </GridContainer>
          </GridContainer>
        )
      }

      return (
        <Formik
          initialValues={initialEvent}
          render={props => <EventForm formikProps={props} />}
          validationSchema={getValidationSchemaByType(eventType)}
        />
      )
    },
  ),
)

export default EventForm
