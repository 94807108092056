const citationRegEx = /\[\[Cit:(.*)\]\]/
const footnoteRegEx = /\[\[Foot:(.*)\]\]/


export const getCitationLinksFromText = (contentItem) => {
    const linkIds = contentItem.Text.match(citationRegEx)
    const textWithCitations = {}
    const stringWithOutLinks = contentItem.Text.split('[')[0]
    textWithCitations.text = stringWithOutLinks

    linkIds.forEach((linkId) => {
        let links = []
        contentItem.CitationLinks.forEach((citation) => {
            if (citation.Id === linkId) {
                links.push({ link: citation.Link, displayId: citation.DisplayValue })
            }
        })
        textWithCitations.links = links
    })
    return textWithCitations
}

const getFootNoteLinksFromText = (contentItem) => {
    const linkIds = contentItem.Text.match(footnoteRegEx)
    const textWithFootnotes = {}
    const stringWithOutLinks = contentItem.Text.split('[')[0]
    textWithFootnotes.text = stringWithOutLinks

    linkIds.forEach((linkId) => {
        let links = []
        contentItem.FootnoteLinks.forEach((footnote) => {
            if (footnote.Id === linkId) {
                links.push({ link: footnote.Link, displayId: footnote.DisplayValue })
            }
        })
        textWithFootnotes.links = links
    })
    return textWithFootnotes

}

export const checkForLinksAndRetrieveFromContent = (contentItem) => {
    if (citationRegEx.test(contentItem.Text)) {
        return getCitationLinksFromText(contentItem)
    }
    else if (footnoteRegEx.test(contentItem.Text)) {
        return getFootNoteLinksFromText(contentItem)
    }
    else return null
}