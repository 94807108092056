import React from 'react'
import { observer, inject } from 'mobx-react'
import { Container, makeStyles, Paper } from '@material-ui/core'
import WizardBox from '../../layout/boxes/wizard//WizardBox.jsx'

import PatientAndPolicyContainer from './patient-and-policy/PatientAndPolicyContainer.jsx'
import AdmissionAndClinicalContainer from './admission-and-clinical/AdmissionAndClinicalContainer.jsx'
import PolicyValidation from './PolicyValidation.jsx'
import Coverage from './Coverage.jsx'
import IncurredCharges from './incurred-charges/IncurredCharges.jsx'
import StatusAndNotificationsContainer from './status-and-notifications/StatusAndNotificationsContainer.jsx'
import CaseSubmission from './case-submission/CaseSubmission.jsx'
import SummaryAndConfirmation from './summary/SummaryAndConfirmation.jsx'
import {
  WIZARD_PATIENT_AND_POLICY,
  WIZARD_SET_POLICY,
  WIZARD_ADMISSION_AND_CLINICAL,
  WIZARD_SET_COVERAGE,
  WIZARD_INCURRED_CHARGES,
  WIZARD_STATUS,
  WIZARD_SUMMARY,
  WIZARD_SUBMISSION,
} from '../../../store/types'
import WizardCancellation from '../../layout/boxes/wizard/WizardCancellation.jsx'
import WizStepper from '../../layout/boxes/wizard/WizStepper.jsx'

const useStyles = makeStyles(theme => ({
  wizSteps: {
    width: '100%',
    height: 90,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
  },
}))

const Start = inject('wizardStore', 'caseStore', 'dialogStore')(
  observer(({ wizardStore, caseStore, dialogStore }) => {
    const classes = useStyles()

    const moveToNextStep = () => {
      wizardStore.nextState()
    }

    const moveToBackStep = () => {
      wizardStore.backState()
    }

    const submitNewCase = async () => {
      dialogStore.openLoadingDialog() 
      await caseStore.save()
      dialogStore.closeLoadingDialog() 
      moveToNextStep()
    }

    const stepProps = {
      isOnWizard: true,
      nextStep: moveToNextStep,
      backStep: moveToBackStep,
      submitNewCase,
    }

    const getComponentDetails = () => {
      switch (wizardStore.currentState) {
        case WIZARD_PATIENT_AND_POLICY:
          return {
            stepNumber: 0,
            title: 'Patient & Policy',
            component: <PatientAndPolicyContainer {...stepProps} />,
          }
        case WIZARD_SET_POLICY:
          return {
            stepNumber: 0,
            title: 'Policy Validation',
            component: <PolicyValidation {...stepProps} />,
          }
        case WIZARD_ADMISSION_AND_CLINICAL:
          return {
            stepNumber: 1,
            title: 'Admission & Clinical',
            component: <AdmissionAndClinicalContainer {...stepProps} />,
          }
        case WIZARD_SET_COVERAGE:
          return {
            stepNumber: 1,
            title: 'Coverage',
            component: <Coverage {...stepProps} />,
          }
        case WIZARD_INCURRED_CHARGES:
          return {
            stepNumber: 2,
            title: 'Incurred Charges',
            component: <IncurredCharges {...stepProps} />,
          }
        case WIZARD_STATUS:
          return {
            stepNumber: 3,
            title: 'Status & Notification',
            component: <StatusAndNotificationsContainer {...stepProps} />,
          }
        case WIZARD_SUMMARY:
          return {
            stepNumber: 4,
            title: 'Summary & Confirmation',
            component: <SummaryAndConfirmation {...stepProps} />,
          }
        case WIZARD_SUBMISSION:
          return {
            stepNumber: 4,
            title: 'Submission',
            component: <CaseSubmission {...stepProps} />,
            hideTitlesAndSteps: true, 
          }
      }
    }

    const componentDetails = getComponentDetails()

    const showWizardContent = () => {
      return (
        <WizardBox
          title={componentDetails.title}
          currentStepNumber={componentDetails.stepNumber}
          currentStep={wizardStore.currentState}
          isOnWizard={true}
          hideTitles={componentDetails.hideTitlesAndSteps}
        >
          <WizardCancellation />
          {componentDetails.component}
        </WizardBox>
      )
    }

    return (
      <div>
        {componentDetails.hideTitlesAndSteps || (
          <Paper className={classes.wizSteps}>
            <WizStepper currentStepNumber={componentDetails.stepNumber} />
          </Paper>
        )}
        <Container>{showWizardContent()}</Container>
      </div>
    )
  })
)

export default Start
