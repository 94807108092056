import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { updateEvent } from './EventFormInitiator.jsx'
import { eventTypeOptions } from '../../../../../constants.js'
import { eventTypesWithLabels } from '../../../../../constants.js'

const useStyles = makeStyles(theme => ({
  container: { display: 'flex', flexDirection: 'column' },
  eventItem: {
    width: '100%',
    padding: '5px',
    textAlign: 'center',
    fontSize: '10px',
    boxShadow: '0 0 3px #aaa',
    borderRadius: '3px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    zIndex: 1,
  },
}))

const EventContainer = ({ events, strategyId }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {events.map(event => {
        return (
          <div
            key={event.id}
            onClick={() => {
              updateEvent(strategyId, event)
            }}
            className={classes.eventItem}
          >
            {event.type === eventTypeOptions.PROCEDURE ||
            event.type === eventTypeOptions.OTHER
              ? event.title
              : eventTypesWithLabels[event.type]}
          </div>
        )
      })}
    </div>
  )
}
export default EventContainer
