import api from 'api'
import { goto } from 'app/views/ViewsRoutes'

class UserLogin {
  static async signIn (email, password) {
    const res = await api.auth.login(email, password)
    if (res.status === 'OK') {
      const data = res.data
      localStorage.setItem('userDataAndToken', JSON.stringify(data))
      window.app.initApp()
    }

    return res
  }

  static async logout () {
    localStorage.removeItem('userDataAndToken')
    delete window.app.jwtToken
    setTimeout(() => {
      // delete it just like that cause problem in the navbar
      // so we do it in the next cycle
      delete window.app.userData
    }, 0)
    goto.login()
  }
}

export default UserLogin
