import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import useStyles from './RecoveryCourseStyle.jsx'
import ChevupIcon from '../../../../assets/icons/ChevupIcon.jsx'
import Dropdown from '../../../../assets/icons/DropdownIcon.jsx'
import { inject, observer } from 'mobx-react'
import { getCitationLinksFromText } from './guidelineHelpers'

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F1F1F1',
    },
  },
}))(TableRow)


const RecoveryCourse = inject('caseStore')(
  observer(({ caseStore }) => {
    const mcgGuideLines = caseStore.case.get('mcgGuideLines')
    const recoveryCourseData = mcgGuideLines ? mcgGuideLines.recoveryCourseData : null
    const [showRecoveryCourse, setShowRecoveryCourse] = React.useState(false)
    const [showIcon, setShowIcon] = React.useState(false)
    const classes = useStyles()

    const createTableHeaders = () => {
      const headerTitles = ['Day', 'LEVEL OF CARE', 'CLINICAL STATUS', 'ACTIVITY', 'ROUTES', 'INTERVENTIONS', 'MEDICATONS']
      return headerTitles.map((title, index) => <StyledTableCell key={index}>{title}</StyledTableCell>)
    }

    const transformRecoveryData = () => {
      const rows = []
      recoveryCourseData.Stages.forEach((stage) => {
        rows.push({
          day: stage.Order,
          routes: stage.Routes ? stage.Routes.Indications.map((indication) => indication.Text) : [],
          activity: stage.Activity ? stage.Activity.Indications.map((indication) => indication.Text) : [],
          levelOfCare: stage.LevelOfCare ? stage.LevelOfCare.Indications.map((indication) => indication.Text) : [],
          medications: stage.Medications ? stage.Medications.Indications.map((indication) => indication.Text) : [],
          interventions: stage.Interventions ? stage.Interventions.Indications.map((indication) => indication.Text) : [],
          clinicalStatus: stage.ClinicalStatus ? stage.ClinicalStatus.Indications.map((indication) => indication.Text) : [],
        })
      })
      return rows
    }
    if (recoveryCourseData) {
      const rows = transformRecoveryData()
      return (
        <Paper className={classes.root}>
          <h3 className="mainHeading" onClick={() => { setShowIcon(!showIcon); setShowRecoveryCourse(!showRecoveryCourse) }}>
            <span className="ChevupIcon">
              {showIcon ? <ChevupIcon /> : <Dropdown />}
            </span>
            Recovery Course
      </h3>
          {showRecoveryCourse &&
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {createTableHeaders()}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <StyledTableRow key={index} className="Tbody">
                    <StyledTableCell >{row.day}</StyledTableCell>
                    <StyledTableCell >
                      <ul className="TableRow">
                        {
                          row.levelOfCare.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))
                        }
                      </ul>
                    </StyledTableCell>
                    <StyledTableCell >
                      <ul className="TableRow">
                        {
                          row.clinicalStatus.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))
                        }
                      </ul>
                    </StyledTableCell>
                    <StyledTableCell >
                      <ul className="TableRow">
                        {
                          row.activity.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))
                        }
                      </ul>
                    </StyledTableCell>
                    <StyledTableCell >
                      <ul className="TableRow">
                        {
                          row.routes.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))
                        }
                      </ul>
                    </StyledTableCell>
                    <StyledTableCell >
                      <ul className="TableRow">
                        {
                          row.interventions.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))
                        }
                      </ul>
                    </StyledTableCell>
                    <StyledTableCell >
                      <ul className="TableRow">
                        {
                          row.medications.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))
                        }
                      </ul>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          }
        </Paper>
      )
    }
  }))

export default RecoveryCourse
