import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CasePreview from '../preview/CasePreview';
import clsx from 'clsx';
import useStyles from './CaseLoadMapStyle.jsx'

const markers = [
  { id:1, top: 83, left: 243 },
  { id:2, top: 217, left: 379 } ,
  { id:3, top: 500, left: 537 },
]

const CaseLoadMap = () => {
  const classes = useStyles();
  const [showPreview, setShowPreview]= React.useState(false);

  const [previewProps, setPreviewProps] = React.useState(false);

  function getCasePreview() {
    if (previewProps) {
      return (
        <CasePreview
          previewProps={previewProps}
          setPreviewProps={setPreviewProps}
        />
      )
    }
  }

  function CaseShow(e) {
    const element = e.target.closest('.map-marker')
    console.log('element', element)
    if (!element) {
      return
    }
    setPreviewProps({ 
      element: element,
      caseId: 1,
    });
  }

  return(
    <React.Fragment>
      <div className={clsx(classes.mapImg, 'mapImg',)}>
        <img src="/assets/images/Mapsicle-map.png"/> 
        { markers.map(mark => (
          <span 
            key={mark.id}
            className="map-marker" 
            style={{ top: mark.top, left: mark.left }} 
            data-id={mark.id} onClick={CaseShow}>
            </span>
        ))}
        { getCasePreview() }
      </div>
    </React.Fragment>


  );
};

export default CaseLoadMap;
