import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import MuiCheckbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles(theme => ({
  root: {},
}));

const Checkbox = ({ className, name, formik, setState, onChange, ...rest }) => {
  const classes = useStyles();
  let initialValue = false
  if (formik) {
    initialValue = formik.values[name]
  }
  const [checked, setChecked] = useState(initialValue)

  const toggleCheckbox = () => {
    const newCheckedState = !checked
    setChecked(newCheckedState)
    if(setState) {
      setState(newCheckedState)
    }
    if(formik) {
      formik.values[name] = newCheckedState
    }
    if(onChange) {
      onChange(newCheckedState)
    }
  }

  return (
    <MuiCheckbox 
      variant="contained"
      color="primary"
      className={clsx(classes.root, className)}
      style={{ width: 36, height: 36 }}
      icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 23 }} />}
      checkedIcon={<CheckBoxIcon style={{ fontSize: 23 }} />}

      onChange={toggleCheckbox}
      checked={checked}
      {...rest}
    />
  );
}

Checkbox.propTypes = {
};

export default Checkbox;
