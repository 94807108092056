import { observable, action, computed } from "mobx"

class GeneralStore {

  @observable loaded = {
    hospitals: false,
    conditions: false,
    users: false
  }

  hospitals = []
  conditions = []
  users = []
  usersSelectOptions = []

  constructor (api) {
    this.api = api
  }

  @action async fetchHospitals () {
    if (this.hospitals.length > 0) return
    this.loaded.hospitals = false
    this.hospitals = await this.api.getHospitals()
    this.loaded.hospitals = true
  }

  @action async fetchConditions () {
    if (this.conditions.length > 0) return
    this.loaded.conditions = false
    this.conditions = await this.api.getConditions()
    this.loaded.conditions = true
  }

  @action async fetchUsers () {
    if (this.users.length > 0) return
    this.loaded.users = false
    this.users = await this.api.getUsers()
    this.usersSelectOptions = this.users.map((user) => ({
      label: user.name,
      value: user.id,
    }))
    this.loaded.users = true
  }

}

export default GeneralStore


