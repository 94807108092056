import React from 'react'
import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  root: {
    padding: 0,
  },
  dateTimeline: {
  	display: 'flex',
    width: 'calc(100% + 67px)',
    maxWidth: 'none',
    marginLeft: -22,
  },
  title: {
    color: theme.palette.text.primary,
    padding: '10px 20px',
  },
  arrow: {
   width: 20,
   color: theme.palette.text.primary,
   display: 'flex',
   alignItems: 'center',
   '& .icon:hover': {
     cursor: 'pointer'
   },
   '& .RightArrow': {
      position: 'relative',
      left: -20,
   }
 },
  buttonWrap: {
    '& Button':{
      padding: '6px 45px', 
    },
     '& sub':{
      color: theme.palette.text.alternate,
      position:'absolute',
      left: 0,
      right: 0,
      top: 20,
      bottom: 0,
     },
  },
  dayItems: {
    marginBottom: 20,
    padding: ' 12px 20px',
  },
  contentBox: { width: '415px' },
}))
