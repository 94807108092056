import React from 'react'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'
import { makeStyles, Dialog, Box, Button, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {},
}))

export const CONTINUE = 'continue'
export const CANCEL = 'cancel'

const ConfirmationDialog = inject('dialogStore')(
  observer(({ dialogStore }) => {

    const onCancel = () => {
      dialogStore.closeConfimrationDialog()
    }

    const onContinue = () => {
      dialogStore.confirmationApproved()
    }

    return (
      <Dialog open={dialogStore.showConfirmDialog}>
        <Box p={10}>
          <Box p={3} mb={4}>
            <Typography variant="h6">{dialogStore.confirmProps.text}</Typography>
          </Box>
          <Box display="flex" justifyContent="center" >
            <Button
              color="secondary"
              variant="contained"
              style={{ marginRight: '8px' }}
              onClick={onCancel}
            >
              {dialogStore.confirmProps.cancelText}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={onContinue}
            >
              {dialogStore.confirmProps.continueText}
            </Button>
          </Box>
        </Box>
      </Dialog>
    )
  }))

ConfirmationDialog.propTypes = {
}

export default ConfirmationDialog
