import React from 'react';
import Icon from '@material-ui/core/Icon';
import StarIcon from '@material-ui/icons/Star';
import clsx from 'clsx'


function StarIconFilled({ className }) {
  return (
    <span className={clsx('icon-star icon-star-filled', className)}>
      <Icon style={{color:'#1C0F66'}} >star</Icon>
    </span>
  )
}

export default StarIconFilled;

