import React from 'react'
import { Avatar } from '@material-ui/core'
import { Person as PersonIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 30,
    width: 30,
  }
}))

const UserAvatar = ({ avatarImage }) => {
  const classes = useStyles()
  if (avatarImage) {
    return (
      <Avatar
        alt={"avatar"}
        src={avatarImage}
        className={classes.avatar}
      />
    )
  } else {
    return (
      <Avatar alt={'avatar'} className={classes.avatar}>
        <PersonIcon />
      </Avatar>
    )
  }
}

export default UserAvatar
