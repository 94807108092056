import React from 'react'
import clsx from 'clsx'

function SortIcons({ className }) {

  return (
    <span className={clsx('icon-sort', className)}>
      <span className="icon-arrow-up iconArrow">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 24 24">
          <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"/>
        </svg>
      </span>
      <span className="icon-arrow-down iconArrow">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 24 24">
          <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"/>
        </svg>
      </span>
    </span>
  )
}

export default SortIcons


