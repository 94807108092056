import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import DateTimeLine from './date-time-line/DateTimeLine.jsx'
import Strategy from './strategy/Strategy.jsx'
import GridContainer from 'app/layout/GridContainer.jsx'
import GridItem from 'app/layout/GridItem.jsx'
import useStyles from './CarePlanStyle.jsx'
import { inject, observer } from 'mobx-react'
import EventFormInitiator, {
  createAllStrategiesEvent,
} from './event/EventFormInitiator.jsx'
import { fieldNames } from "constants.js"


const CarePlan = inject('caseStore')(
  observer(({ caseStore }) => {
    const dateOfAdmission = new Date(caseStore.case.get(fieldNames.DATE_OF_ADMISSION))
    const [startDay, setStartDay] = useState(0)
    const classes = useStyles()
    const strategies = caseStore.case.get('strategies') || []
    
    let maxStrategyLOS = 0
    strategies.forEach(strategy => {
      if (maxStrategyLOS < strategy.lengthOfStay) {
        maxStrategyLOS = strategy.lengthOfStay
      }
    })

    function canMoveLeft() {
      return startDay > 0
    }

    function canMoveRight() {
      return startDay + 10 < maxStrategyLOS 
    }

    function moveLeft() {
      setStartDay(startDay - 1)
    }

    function moveRight() {
      setStartDay(startDay + 1)
    }

    return (
      <div className={classes.root}>
        <EventFormInitiator />
        <h2 className={classes.title}>Care Plan</h2>
        <GridContainer direction="row-reverse" spacing={2} className={classes.dayItems}>
          <GridItem container xs={12} lg={2} direction="row-reverse" className={classes.buttonWrap}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => createAllStrategiesEvent(new Date())}
            >
              Add Event
              <sub>to all</sub>
            </Button>
          </GridItem>
          <GridItem xs={12} lg={10} >
            <div className={classes.dateTimeline}>
              <span className={classes.arrow} > 
                {canMoveLeft() &&
                    <a className='icon' onClick={moveLeft} >
                      <img src="/assets/images/left-arrow.svg"/>
                    </a>
                }
              </span>
              <DateTimeLine 
                startDay={startDay}
                dateOfAdmission={dateOfAdmission}
              />
              <span className={classes.arrow}> 
                {canMoveRight() &&
                  <a className='icon RightArrow' onClick={moveRight}>
                    <img src="/assets/images/right-arrow.svg"/>
                  </a>
                }
              </span>
            </div>
          </GridItem>
        </GridContainer>
        {strategies.map((strategy, index) => {
          return (
            <Strategy
              key={index}
              startDay={startDay}
              startDate={dateOfAdmission}
              strategy={strategy}
              caseStore={caseStore}
            />
          )
        })}
      </div>
    )
  })
)

export default CarePlan
