import React from 'react';
import clsx from 'clsx'

function LockIcon({ className }) {

  return (
    <span className={clsx('icon-Lock', className)}>
      <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.6594 8.17484C13.4325 7.94638 13.1572 7.83224 12.8329 7.83224H12.4443V5.48246C12.4443 3.98131 11.9097 2.69239 10.8402 1.61546C9.77075 
      0.538486 8.49089 0 6.99994 0C5.50903 0 4.22891 0.538486 3.15957 1.61541C2.09006 2.69239 1.55548 3.98127 1.55548 5.48246V7.83224H1.16664C0.842674 
      7.83224 0.567146 7.94638 0.340271 8.17484C0.113395 8.40313 0 8.68058 0 9.00711V16.056C0 16.3822 0.113438 16.6598 0.340271 16.8883C0.567146 17.1165 
      0.842674 17.2308 1.16664 17.2308H12.8332C13.1575 17.2308 13.4328 17.1166 13.6597 16.8883C13.8864 16.6598 14 16.3822 14 16.056V9.00698C14.0002 8.68071 
      3.8864 8.4033 13.6594 8.17484ZM10.111 7.83224H3.88885V5.48246C3.88885 4.6177 4.19273 3.8793 4.80035 3.26752C5.40806 2.65565 6.14122 2.34978 7.00006 
      2.34978C7.859 2.34978 8.59198 2.65561 9.19973 3.26752C9.80719 3.87926 10.111 4.6177 10.111 5.48246V7.83224Z" fill="white"/>
      </svg>
    </span>
  )

}

export default LockIcon;


