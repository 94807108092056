import React from 'react';
import clsx from 'clsx'

function FilterIcon({ className }) {

  return (
    <span className={clsx('icon-filter', className)}>
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 4.37574L7 12.3757V20.943L13 18.693V12.3757L20 4.37574V2C20 0.89543 19.1046 0 18 0H2C0.89543 0 0 0.89543 0 2V4.37574ZM18 2V3.62426L11 11.6243V17.307L9 18.057V11.6243L2 3.62426V2H18Z" fill="#443699"/>
      </svg>
    </span>
  )
}

export default FilterIcon;


