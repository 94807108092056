import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { GoogleApiWrapper } from 'google-maps-react'
import { API_KEY } from '../../../AppScriptLoader'
import IntegrationReactSelect from '../ReactSelect'

const resultsFormatter = results => {
  return results.map(result => ({
    label: result.description,
    value: result,
  }))
}

const AutoComplete = ({
  initValue,
  onChange,
  formlabel,
  google,
  name,
  formik,
}) => {
  const [suggestions, setSuggestions] = useState([])
  const autocompleteService = useRef(null)
  const [address, setAddress] = useState(initValue)

  useEffect(() => {
    autocompleteService.current = new google.maps.places.AutocompleteService()
    if (initValue) {
      inializeDefaultValue()
    }
    return () => {
      autocompleteService.current = null
    }
  }, [])

  const onInternalChange = (address) => {
    setAddress(address)
    onChange(address.value)
  }

  const inializeDefaultValue = () => {
    const initSuggestions = [{ label: initValue , value: {description: initValue}}]
    setSuggestions(initSuggestions)
    setAddress(initSuggestions[0])
  }

  const onInputChange = inputValue => {
    if (inputValue === '') {
      setSuggestions([])
      return
    }

    const createAutocompleteRequest = inputValue => ({ input: inputValue })

    autocompleteService.current.getPlacePredictions(
      createAutocompleteRequest(inputValue),
      (predictions, serviceStatus) => {
        if (serviceStatus !== google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions([])
          return
        }
        setSuggestions(resultsFormatter(predictions))
      }
    )
  }

  return (
    <IntegrationReactSelect
      placeholder="Choose a location..."
      options={suggestions}
      onInputChange={onInputChange}
      formlabel={formlabel}
      value={address}
      onChange={onInternalChange}
      required
      name={name}
      formik={formik}
    />
  )
}

AutoComplete.propTypes = {
  initValue: PropTypes.string,
  formlabel: PropTypes.string.isRequired,
}

export default GoogleApiWrapper({
  apiKey: API_KEY,
})(AutoComplete)
