import React from 'react'
import MaterialTable from 'material-table'
import { Box, Input } from '@material-ui/core'
import _ from 'lodash'

function OnlyAddRowToolbar(props) {
  // display only the add button on the tool bar
  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          right: 10,
          zIndex: 1000,
        }}
      >
        <props.components.Actions
          actions={[props.actions[0]]}
          components={props.components}
        />
      </div>
    </div>
  )
}

const EditableTable = ({ onDataChanged, onErrors, initData }) => {
  const [state, setState] = React.useState({
    columns: [
      {
        title: 'Incurred Charges',
        field: 'value',
        headerStyle: {
          width: '35%',
          color: '#000',
          fontWeight: 'bold',
        },
        cellStyle: { color: '#000' },
        editComponent: props => (
          <Input
            type="number"
            style={{ fontSize: '16px', color: '#000' }}
            placeholder={props.columnDef.title}
            onChange={e => props.onChange(e.target.value)}
          />
        ),
      },
      {
        title: 'Description',
        field: 'description',
        headerStyle: {
          color: '#000',
          fontWeight: 'bold',
        },
        cellStyle: { width: '240px', color: '#000' },
        editComponent: props => (
          <Input
            type="text"
            style={{ width: '100%', fontSize: '16px', color: '#000' }}
            placeholder={props.columnDef.title}
            onChange={e => props.onChange(e.target.value)}
          />
        ),
      },
    ],
    data: initData,
  })

  const validateRow = rowData => {
    if (_.isEmpty(rowData.value) || isNaN(rowData.value)) {
      const error = 'Charges must be a number'
      onErrors(error)
      console.log('validateRow', error)
      return false
    }

    if (_.isEmpty(rowData.description)) {
      const error = 'You must provide description'
      console.log('validateRow', error)
      onErrors(error)
      return false
    }

    onErrors(null)
    return true
  }

  const onRowWillChange = (type, newData, oldData) => {
    return new Promise((resolve, reject) => {
      if (validateRow(newData)) {
        const data = [...state.data]
        if (type === 'add') {
          data.push(newData)
        } else if (type === 'updated') {
          data[data.indexOf(oldData)] = newData
        }
        console.log('onRowWillChange', data)
        resolve()
        dataHasChanged(data)
      } else {
        reject()
      }
    })
  }

  const dataHasChanged = data => {
    setState({ ...state, data })
    onDataChanged(data)
  }

  return (
    <MaterialTable
      title="Incurred Charges"
      columns={state.columns}
      data={state.data}
      style={{
        width: '100%',
      }}
      icons={{
        Add: React.forwardRef((props, ref) => (
          <Box
            bgcolor="primary.main"
            color="white"
            fontSize={16}
            fontWeight="fontWeightBold"
            p={2}
            mt={2}
            borderRadius={4}
          >
            Add
          </Box>
        )),
      }}
      localization={{
        body: {},
        header: {
          actions: '',
        },
      }}
      options={{
        actionsColumnIndex: -1,
        maxBodyHeight: 250,
        search: false,
        showTitle: false,
        paging: false,
        addRowPosition: 'first',
        rowStyle: {
          // backgroundColor: '#EEE',
          color: '#000',
        },
      }}
      editable={{
        onRowAdd: newData => onRowWillChange('add', newData),
        onRowUpdate: (newData, oldData) =>
          onRowWillChange('updated', newData, oldData),
        onRowDelete: oldData =>
          new Promise(resolve => {
            const data = [...state.data]
            data.splice(data.indexOf(oldData), 1)
            dataHasChanged(data)
            resolve()
          }),
      }}
      components={{
        Toolbar: OnlyAddRowToolbar,
      }}
    />
  )
}

export default EditableTable
