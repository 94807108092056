import React, { Fragment } from 'react'
import FormControl from '@material-ui/core/FormControl'
import TextField from '../../../components/TextField.jsx'
import FilterIcon from '../../../assets/icons/FilterIcon'
import ReactSelect from '../../../components/ReactSelect.jsx'
import CaseLoadHeaderStyle from './CaseLoadHeaderStyle.jsx'
import GridContainer from '../../../layout/GridContainer.jsx'
import GridItem from '../../../layout/GridItem.jsx'
import SearchIcon from '@material-ui/icons/Search'

const continentOptions = [
  'Asia', 'Africa',
  'North America',
  'South America',
  'Europe', 'Australia'
].map(continent => ({ label: continent, value: continent }))

const ownerOptions = ['Name1', 'Name 2', 'Name 3']
  .map(name => ({ label: name, value: name }))

export default function CaseLoadHeader () {
  const classes = CaseLoadHeaderStyle()
  return (
    <Fragment>
      <div className={classes.title}>
        <h3>Case List</h3>
      </div>
      <div className={classes.filterForm}>
        <GridContainer>
          <GridItem xs={12} sm={8}>
            <FormControl className={classes.filterBy}>
              <h3 className={classes.filterBy2}>
                <span className={classes.filterIcon}>
                  <FilterIcon />
                </span>
                Filter&nbsp;By:
                </h3>
            </FormControl>
            <div className={classes.filterFormFields1}>
              <FormControl className={classes.formSelectControl}>
                <ReactSelect
                  name="continent"
                  formlabel="CONTINENT"
                  placeholder="All"
                  required
                  fullWidth
                  options={continentOptions}
                />
              </FormControl>
              <FormControl className={classes.formSelectControl}>
                <ReactSelect
                  name="owner"
                  formlabel="OWNER"
                  placeholder="ANY"
                  required
                  fullWidth
                  options={ownerOptions}
                />
              </FormControl>
            </div>
          </GridItem>
          <GridItem xs={12} sm={4} >
            <div className={classes.searchField}>
              <TextField
                name="caseLoadSearch"
                placeholder="Search"
                icon={<SearchIcon />}
              />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </Fragment>
  )
}
