import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  container: {
    margin: '0 30px 0 30px',
  },
  caseLoadData:{
    backgroundColor: theme.palette.text.alternate,
    boxShadow:'0px 1px 6px rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    marginTop: 13,
  },
  tblWrap: {
    margin: 16,
    boxShadow:'none',
  },
}))

