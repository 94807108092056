import client from '../client'
import { GET_CASE_ACTIVITIES_QUERY, GET_USER_NOTIFICATIONS_QUERY, MARK_ACTIVITY_AS_READ, DELETE_NOTIFICATION } from './queries'

const getCaseActivities = async (caseId) => {
  const activities = await client.query({
    query: GET_CASE_ACTIVITIES_QUERY,
    variables: { caseId }
  })
  return activities.data.getCaseActivities
}

const getUserNotifications = async () => {
  const activities = await client.query({
    query: GET_USER_NOTIFICATIONS_QUERY,
  })

  return activities.data.getUserNotifications
}

const markActivityAsRead = async (activityId) => {
  const activity = await client.mutate({
    mutation: MARK_ACTIVITY_AS_READ,
    variables: { activityId }
  })

  return activity.data.markActivityAsRead
}

const deleteNotification = async (activityId) => {
  const activity = await client.mutate({
    mutation: DELETE_NOTIFICATION,
    variables: { activityId }
  })

  return activity.data.deleteNotification
}

export default {
  markActivityAsRead,
  deleteNotification,
  getCaseActivities,
  getUserNotifications,
}
