import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  header: { 
    paddingLeft: 14,
    paddingTop: 4 
  },
  mainContainer: { 
    paddingLeft: 8,
  },
  headerDivider: { 
    backgroundColor: '#E1E1E3', 
    height: 1, 
    width: '100%',
    marginBottom: 15,
  },
  divider1: {
    border: '0.5px solid #E1E1E3',
    width: '93%',
    // marginTop: '10px',
    marginBottom: '25px', 
  },
  divider2: {
    border: '0.5px solid #E1E1E3',
    width: '93%',
    marginTop: '30px',
    // marginBottom: '10px', 
  },
  checkBox: { paddingLeft: 0 },
  textField: { marginBottom: '20px' },
  label: {
    marginBottom: '5px',
    color: '#232735',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
  text: {
    marginTop: '5px',
    fontSize: '16px',
    color: '#232735',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
  },
  viewSegmentLabel: {
    marginBottom: '15px',
    color: '#232735',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
  strategyAndTimeContainer: { padding: '10px' },
  button: {
    color: '#FFF',
    backgroundColor: theme.palette.primary.main,
    width: '105px',
    height: '36px',
    margin: '13px 10px 25px 0',
    '&:hover': {
      backgroundColor: '#1C0F66',
    },
  },
  deleteButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    width: '105px',
    height: '36px',
    margin: '13px 10px 25px 0',
    padding: 0,
  },
  headerTitle: {
    color: '#232735',
  },
  Quote:{
    fontSize:16,
    fontWeight:'normal',
    fontStyle:'normal',
    color:'#000000',
    paddingTop:2,
  },
}))
export default useStyles
