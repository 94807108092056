import { activityTypes, fieldNamesCaptialized, userRolesCaptitalized } from './../../constants'
import { formatActivityDate } from 'helpers/dates'


const cleanEventName = (eventName) => {
  return eventName.replace('-', ' ')
}

export const getActivityText = (activity) => {
  switch (activity.type) {
    case activityTypes.FIELD_CHANGED:
      activity.text = createFieldChangedActivityText(activity.fieldName, activity.nickname)
      break
    case activityTypes.STRATEGY_LOCK:
      activity.text = `${activity.strategyType} strategy approved `
      break
    case activityTypes.STRATEGY_RECOMMENDED:
      activity.text = `${activity.strategyType} strategy recommended `
      break
    case activityTypes.OWNER_ASSIGNED:
      activity.text = `${activity.ownerName}, ${userRolesCaptitalized[activity.ownerRole]} is assigned as Case Owner`
      break
    case activityTypes.NEW_FOLLOWER:
      activity.text = `${activity.nickname}, ${activity.role} is now following this case`
      break
    case activityTypes.NEW_STRATEGY:
      activity.text = `${activity.strategyType} strategy created `
      break
    case activityTypes.NEW_EVENT:
      activity.text =
        `${cleanEventName(activity.eventName)} added to ${activity.strategyType} `
      break
    case activityTypes.UPDATED_EVENT:
      activity.text =
        `${cleanEventName(activity.eventName)} updated in ${activity.strategyType} `
      break
    case activityTypes.REMOVE_EVENT:
      activity.text =
        `${cleanEventName(activity.eventName)} deleted from ${activity.strategyType} `
      break
    case activityTypes.URGENCY_CHANGED:
      activity.text = `Urgency changed to ${activity.urgencyValue} `
      break
    case activityTypes.PATIENT_DISCHARGED:
      activity.text = 'The patient was discharged'
      break
    case activityTypes.COMMENT:
      break
    default:
      throw new Error('invalid activity type' + activity.type)
  }
  return activity
}

export const getActivitiesText = (activities) => {
  return activities.map((activity) => {
    return getActivityText(activity)
  })
}

export const getUserNotificationsWithText = (activities) => {
  return activities.map((activity) => {
    return getUserNotificationWithText(activity)
  })
}

export const getUserNotificationWithText = (activity) => {
  const notification = {
    id: activity.id,
    time: formatActivityDate(activity.time),
    type: activity.type,
    isRead: activity.isRead,
    caseNumber: activity.caseNumber
  }
  if (activity.isNewCase) {
    notification.isNewCase = true
  }
  switch (activity.type) {
    case activityTypes.STRATEGY_LOCK:
      notification.details = `${activity.strategyType} strategy approved by ${activity.nickname}`
      notification.activityMessage = 'Strategy Locked'
      break
    case activityTypes.OWNER_ASSIGNED:
      notification.activityMessage = notification.isNewCase ?
        'You were made owner of a new case.' :
        `${activity.nickname}, made you owner of this case`
      notification.details = `You were made owner of this case by ${activity.nickname}`
      break
    case activityTypes.PATIENT_DISCHARGED:
      notification.activityMessage = 'Patient discharged'
      notification.details = 'The patient was discharged from the treating facility.'
      break
    case activityTypes.COMMENT:
      notification.activityMessage = `${activity.nickname} tagged you`
      notification.details = `comment: ${activity.text}`
      break
    default:
      console.error('invalid notification type')
  }
  return notification
}

const createFieldChangedActivityText = (fieldName, username, timestamp) => {
  return `${fieldNamesCaptialized[fieldName]} changed` // by ${username}
}




