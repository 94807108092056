import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import TextField from '../../../components/TextField.jsx'
import HoriziontalDivider from '../../../components/HoriziontalDivider.jsx'
import ReactSelect from '../../../components/ReactSelect.jsx'
import GridContainer from '../../../layout/GridContainer.jsx'
import GridItem from '../../../layout/GridItem.jsx'
import DatePicker from '../../../components/RcDatePicker.jsx'
import WizardButtons from '../../../layout/boxes/wizard/WizardButtons.jsx'
import { submitOnValidation } from '../../../../helpers/formik-helpers.js'

const styles = theme => ({
  root: {},
  separator: {
    padding: '30px 10px !important',
  },
  separator2: {
    marginTop: 5,
  },
})

const AdmissionAndClinical = inject('generalStore')(
  observer(({ classes, generalStore, formikProps, handleSubmit, isOnWizard, backStep, onCancel }) => {
    const [condition, setCondition] = React.useState(formikProps.values.condition)
    const [hospital, setHospital] = React.useState(formikProps.values.hospital)

    const onNext = async () => {
      const isValid = await submitOnValidation(formikProps, handleSubmit)
    }

    useEffect(() => {
      generalStore.fetchHospitals()
      generalStore.fetchConditions()
      return () => { }
    }, [])

    const onConditionChange = condition => {
      formikProps.values.condition = condition
      setCondition(condition)
    }

    const handleHospitalChange = hospital => {
      formikProps.values.hospital = hospital
      setHospital(hospital)
    }

    const editButtonProps = !isOnWizard ? { nextText: 'Submit', backText: 'Cancel' } : {}

    return (
      <div className={classes.root}>
        <form className={classes.container} noValidate autoComplete="off">
          <GridContainer justify="space-between" spacing={4}>
            <GridItem xs={12}>
              <ReactSelect
                name="condition"
                formlabel="Condition"
                rerender_dummay={generalStore.loaded.conditions}
                options={generalStore.conditions}
                placeholder="Please enter Condition"
                onChange={onConditionChange}
                formik={formikProps}
                value={condition}
                largeList
                required
                fullWidth
              />
            </GridItem>
            <GridItem className={classes.separator2} xs={12}>
              <TextField
                formlabel="Other Details"
                name="otherDetails"
                formik={formikProps}
                multiline
                fullWidth
                rows="3"
                rowsMax="3"
                placeholder="Other Details.."
                variant="outlined"
                height="inherit"
              />
            </GridItem>
            <GridItem className={classes.separator} xs={8}>
              <ReactSelect
                name="hospital"
                formlabel="Hospital Name"
                rerender_dummay={generalStore.loaded.hospitals}
                options={generalStore.hospitals}
                placeholder="Hospital name"
                onChange={handleHospitalChange}
                formik={formikProps}
                value={hospital}
                required
                fullWidth
              />
            </GridItem>
            <GridItem className={classes.separator} xs={4}>
              <DatePicker name="dateOfAdmission" formlabel="Date of Admission" formik={formikProps} required />
            </GridItem>
            <HoriziontalDivider />
            <GridItem xs={12}>
              <Box mt={5}>
                <WizardButtons
                  isOnWizard={isOnWizard}
                  backStep={backStep}
                  nextStep={onNext}
                  onCancel={onCancel}
                  {...editButtonProps}
                />
              </Box>
            </GridItem>
          </GridContainer>
        </form>
      </div>
    )
  })
)

AdmissionAndClinical.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AdmissionAndClinical)
