import React from 'react';
import ComponentDialog from './ComponentDialog.jsx'
import CancellationDialog from './ConfirmationDialog.jsx'
import LoadingDialog from './LoadingDialog.jsx'

const AppShared = () => {
  return  (
    <>
      <ComponentDialog />
      <CancellationDialog />
      <LoadingDialog />
    </>
  )
}

export default AppShared
