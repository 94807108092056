import React from 'react';
import clsx from 'clsx'

function CustomIcon({ className }) {
  return (
    <span className={clsx('icon-CustomIcon', className)}>
      <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="13" height="17" stroke="#443699" strokeWidth="2"/>
      </svg>
    </span>
  )
}

export default CustomIcon;


