import React from 'react'
import { observer, inject } from 'mobx-react'
import ContentBox from '../../../../layout/boxes/content-box/ContentBox.jsx'
import EventForm from './EventForm.jsx'

export let createEvent
export let updateEvent
export let createAllStrategiesEvent

export const NEW_EVENT = 'NEW_EVENT'
export const UPDATE_EVENT = 'UPDATE_EVENT'
export const ALL_STRATEGIES_EVENT = 'ALL_STRATEGIES_EVENT'

const EventFormInitiator = inject('dialogStore')(observer(({ dialogStore }) => {
    const onClose = () => {
      dialogStore.close()
    }

    const onCancel = () => {
      dialogStore.openConfimrationDialog(onClose)
    }

    /**
     * action is one of NEW_EVENT, UPDATE_EVENT, ALL_STRATEGIES_EVENT
     */
    const showEventForm = ({
      action,
      date,
      formTitle,
      buttonText,
      strategyId,
      event,
    }) => {
      dialogStore.open(
        <ContentBox
          mainTitle={formTitle}
          onCancel={onCancel}
          styleProps={{
            width: 415,
            marginBottom: 0,
            titleFontSize: 18,
            titleLineHeight: '23px',
          }}
        >
          <EventForm
            event={event}
            date={date}
            buttonText={buttonText}
            onCancel={onCancel}
            closeForm={onClose}
            strategyId={strategyId}
            action={action}
          />
        </ContentBox>
      )
    }

    createEvent = (strategyId, date) => {
      const formTitle = 'Add Event'
      const buttonText = 'Add'
      showEventForm({
        action: NEW_EVENT,
        date,
        formTitle,
        buttonText,
        strategyId,
      })
    }

    createAllStrategiesEvent = date => {
      const formTitle = 'Add Event'
      const buttonText = 'Add'
      showEventForm({
        action: ALL_STRATEGIES_EVENT,
        date,
        formTitle,
        buttonText,
      })
    }

    updateEvent = (strategyId, event) => {
      const formTitle = 'Update Event'
      const buttonText = 'Update'
      showEventForm({
        action: UPDATE_EVENT,
        formTitle,
        buttonText,
        strategyId,
        event,
      })
    }

  return 
}))

export default EventFormInitiator
