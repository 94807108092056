import React from 'react';
import Confirmation from '../../layout/boxes/Confirmation.jsx';
import { observer, inject } from 'mobx-react';

const Coverage = inject("caseStore")(observer(({ caseStore, backStep, nextStep }) => {
  const noText='No'
  const yesText='Confirmed'

  const getAnswer = (answer) => {
    const coverage = answer === yesText ? 'valid' : 'pending' 
    caseStore.merge({ coverage })
    nextStep()
  }

  return (
    <Confirmation 
      question='Is the coverage confirmed?'
      noText={noText}
      yesText={yesText}
      getAnswer={getAnswer}
      backStep={backStep}
    />
  );
}))

export default Coverage;
