import React from 'react';
import { observer, inject } from "mobx-react";
import { withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import Wizard from './app/views/wizard/Start.jsx';
import {
  WIZARD_PATIENT_AND_POLICY,
  WIZARD_SET_POLICY,
  WIZARD_ADMISSION_AND_CLINICAL,
  WIZARD_SET_COVERAGE,
  WIZARD_INCURRED_CHARGES,
  WIZARD_STATUS,
  WIZARD_SUMMARY,
  WIZARD_SUBMISSION,
} from './store/types'
import routes from './app/views/ViewsRoutes'

const styles = theme => ({
  list: {
    listStyle: 'none',
    '& li': {
      display: 'inline',
      margin: 5,
    },
    button: {
      textTransform: 'none',
    },
  },
});

const states= [
  WIZARD_PATIENT_AND_POLICY,
  WIZARD_SET_POLICY,
  WIZARD_ADMISSION_AND_CLINICAL,
  WIZARD_SET_COVERAGE,
  WIZARD_INCURRED_CHARGES,
  WIZARD_STATUS,
  WIZARD_SUMMARY,
  WIZARD_SUBMISSION,
]

const TempComponentList = inject('wizardStore', 'router')(
  ({wizardStore, classes, router}) => {
  console.log('router', router.params.state)
  const state = router.params.state || states[0]

  wizardStore.setState(state)

  const getComponentsList = () => {
    return (
      <div>
        <ul className={classes.list}>
            { states.map((st, index)  => {
              return (
                <li key={index}>
                  <MuiButton classes={{label: classes.button}} variant="outlined" onClick={() => setCurrent(st, index)} data-value={st} >
                    {st.replace('WIZARD_', '').replace(/_/g, ' ')}
                  </MuiButton>
                </li>
             )})
            }
        </ul>
        <Wizard />
      </div>
    )
  }

  const setCurrent = (state, index) => {
    router.goTo(routes.tempComponentList, {state})
    wizardStore.setState(state)
  }

  return getComponentsList()
})

export default withStyles(styles)(TempComponentList);

