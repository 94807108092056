import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Paper } from '@material-ui/core'
import WizStepper from './WizStepper'
import { WIZARD_SUBMISSION } from '../../../../store/types'
import ContentBox from '../content-box/ContentBox.jsx'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    background: '#FFF',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 15,
    "& .makeStyles-upperHeader-249":{
      lineHeight: 3,
    }
  },
}))

const WizardBox = ({ 
  currentStepNumber,
  currentStep,
  title,
  onCancel,
  isOnWizard,
  children,
  hideTitles,
}) => {
  const classes = useStyles()

  const hideCloseButton = currentStep === WIZARD_SUBMISSION

  return (
    <Paper className={classes.root}>
      <ContentBox
        mainTitle="NEW CASE"
        title={ title }
        onCancel={onCancel}
        hideClose={hideCloseButton}
        isOnWizard={isOnWizard}
        hideTitles={hideTitles}
      >
        {children}
      </ContentBox>
    </Paper>
  )
}

WizardBox.propTypes = {
}

export default WizardBox
