import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    '& .mainHeading': {
      cursor: 'pointer',
    },
    '& .ChevupIcon': {
      padding: 6,
      cursor: 'pointer',
    },
    '& .Navlinks': {
      margin: 0,
      padding: 7,
      backgroundColor: theme.palette.background.primary,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& li': {
        padding: '0px 15px',
        listStyle: 'none',
        borderRight: '2px solid white',
        '&:last-child': {
          border: 'none'
        }
      },
      '& a': {
        marginLeft: 20,
        display: 'block',
        color: theme.palette.text.alternate,
        textDecoration: 'none',
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
      },
    },
    '& .contentHeading': {
      marginLeft: 40,
    },
    '& .Stronglable': {
      marginLeft: 40,
      color: theme.palette.text.black,
      display: 'block',
    },
    '& .content': {
      paddingTop: 16,
      '& ul': {
        margin: 10,
      },
      '& li': {
        margin: '0px 0px 0px 20px',
        color: theme.palette.text.black,
        fontSize: 16,
        fontStyle: 'normal',
      },
    },
  },


}))

export default useStyles