import React from 'react';
import clsx from 'clsx'

function BorderLikeButton({ className, enabled }) {

  return (
    <span className={clsx('likeBtn', className)}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M17 7C18.8696 7 20 8.31752 20 10C20 15.9436 18.7551 20 16 20L9.0344 19.9994C8.34085 20.0121 7.50544 
        19.9196 6.66312 19.6182C6.29522 19.4865 5.95674 19.3228 5.65337 19.1266C5.29331 19.6544 4.68712 20.0009 4 20.0009H2C0.89543 20.0009 0 19.1055 0 
        18.0009V9.00089C0 7.89633 0.89543 7.00089 2 7.00089H4C4.19817 7.00089 4.38961 7.02972 4.57035 7.0834C5.2669 6.0689 7.03025 4.05393 8.447 
        2.43501L8.44701 2.435C9.21499 1.55743 9.88112 0.79624 10.2203 0.373885L10.5205 1.37205e-07H11C11.6148 -9.18361e-05 12.1423 0.0460282 12.7483 
        0.222486C14.0919 0.613718 15 1.52799 15 3C15 3.52011 14.9079 4.07463 14.7382 4.69013C14.626 5.09736 14.4872 5.50856 14.2971 6.01891C14.2699 6.09184 
        14.2044 6.26444 14.1371 6.44176L14.137 6.44207L14.137 6.44213C14.0609 6.64264 13.9826 6.8491 13.9548 6.92427L13.927 7H17ZM6 16C6 16.9022 6.45197 
        17.4185 7.33688 17.7351C7.91062 17.9404 8.52538 18.0085 8.96307 18.0006L9 18H16C17.0151 18 18 14.7907 18 10C18 9.35989 17.6912 9 17 9H13C11.8055 9 
        11.5555 8.07635 11.8047 7.07631C11.8633 6.84107 11.9489 6.58217 12.079 6.23055C12.1102 6.14617 12.1945 5.92393 12.2731 5.71663L12.2733 5.71628L12.2737 
        71523C12.3381 5.54541 12.3986 5.38586 12.4229 5.32066C12.5944 4.86037 12.7161 4.49958 12.8101 4.15861C12.9359 3.70232 13 3.31658 13 3C13 2.54903 12.7622 
        .3096 12.1891 2.14274C11.9484 2.07263 11.7114 2.03399 11.46 2.01513C10.9338 2.64409 10.1803 3.50372 9.40481 4.38851L9.40467 4.38868C7.90193 6.10314 
        6.31645 7.912 6.14171 8.31998C6.04443 8.54711 6 8.7598 6 9V9.00089V16Z" 
        fill={enabled ? '#443699' : '#B9B8BF'}
        />
      </svg>
    </span>
  )
}
export default BorderLikeButton;

