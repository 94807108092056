import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    margin: '0 0 20px 0',
    height: 1,
    backgroundColor: '#E5E5E5',
    border: 'none',
  },
})

const HoriziontalDivider = props => {
  const { classes } = props

  return <hr className={classes.root} />
}

HoriziontalDivider.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(HoriziontalDivider)
