import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles( theme => ({
  GridLeft: {
    backgroundImage: 'url(/assets/images/radar.png)',
    backgroundSize: 'cover',
    backgroundposition: 'center',
    [theme.breakpoints.down('md')]: {
      maxWidth: '50%',
      flexBasis: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',    
    },
  },
  GridRight: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '50%',
      flexBasis: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  card: {
    display:'block',
     height: '100vh',
     width: '100% auto',
     display: 'flex',
     justifyContent: 'center',
    '& .MuiCardContent-root': {
      padding: 0,
      paddingBottom: 24,
     width: '355px',
  },
  },
  CardContent: {
    backgroundColor: 'white',
    display: 'inline-flex',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    margin: 'auto',
    alignSelf: 'center',
  },
  LoginButtonWrap:{
    paddingLeft: 11,
    '& .MuiInputLabel-animated': {
      color: theme.palette.text.black,
      marginBottom: 5,
    },
   '& .MuiTextField-root': {
      marginBottom: 20,
      width: '100%',
   },
  },
  button: {
    width: '100%',
    padding:8,
    margin: '60px 0px 0px 0px',
  },
  buttonLabel: {
    fontStyle:'normal',
    fontSize:16,
    fontWeight:600,
  },
  RadarLogo: {
    '& img':{
      height:'48px',
      marginBottom: 10,
    },     
  },
  LoginHeading: {
    paddingTop:30,
    color:'#232735',
    fontStyle:'normal',
    fontSize: 24,
    fontWeight:600,
    margin: '0px 0px 65px 0px',
  },
  
}));

export default useStyles;
