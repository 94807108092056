import React from 'react';
import clsx from 'clsx';


function Dropdown(props) {
  return (
      <span className={clsx('DropdownIcon', props.className)}>
        <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M12 0L13.4142 1.41421L6.70711 8.12132L0 1.41421L1.41421 0L6.70711 5.29289L12 0Z" 
          fill="#443699"/>
        </svg>
      </span>
    );
  };

export default Dropdown;

