import React from 'react';
import clsx from 'clsx';


function ChevupIcon(props) {
  return (
      <span className={clsx('ChevupIcon', props.className)}>
        <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M6.70711 3.1213L1.41421 8.4142L0 6.99998L6.70711 0.292877L13.4142 6.99998L12 8.4142L6.70711 3.1213Z" fill="#443699"/>
        </svg>
      </span>
    );
  };

export default ChevupIcon;

