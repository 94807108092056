import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
    padding: '100px',
  },
  icon: {
    margin: theme.spacing(1),
    fill: '#68C1B4',
    fontSize: 32,
  },
  button: {
    height: 40,
    width: 250,
  },
  buttonContainer: {
    marginTop: 20,
    padding: 10,
  },
  ConfirmText:{
    color:'#232735',
  },
  caseSubmissionButton: {
    display: 'inline-block',
    cursor: 'pointer',
    textDecoration: 'underline',
    color:'#5E72E4',
    fontSize: 16,
    fontWeight:600,
    fontStyle:'normal',
  }
}))

export default useStyles
