import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, FormLabel } from '@material-ui/core'
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  select: {
    color: '#232735',
  },
  label: {
    color: '#232735',
    marginBottom: '5px',
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: props => (props.eventFormLabel ? '500' : 'normal'),
    fontSize: props => (props.eventFormLabel ? '18px' : '14px'),
    lineHeight: props => (props.eventFormLabel ? '23px' : '16px'),
    display: 'flex',
    alignItems: 'center',
    textTransform: props => (props.eventFormLabel ? 'capitalize' : 'uppercase'),
  },
}));

function SimpleSelect({ 
  label,
  options,
  onSelect,
  ...rest
}) {

  const classes = useStyles();

  function handleChange(event) {
    onSelect(event.target.value)
  }
  
  return (
    <FormControl 
      fullWidth
      className={classes.formControl}
      variant="outlined"
    >
      <FormLabel className={classes.label}>
        {label}
      </FormLabel>
      <Select
        onChange={handleChange}
        input={<OutlinedInput name="age" id="outlined-age-simple" />}
        autoWidth={true} 
        className={classes.select}
        {...rest}
      >
        {options.map((opt, index) => <MenuItem className={classes.select} key={index} value={opt.value}>{opt.label}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export default SimpleSelect
