import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const UserMenuStyle = makeStyles(theme => ({
    actionIcon: {
        margin: '0 10px',
        height: 30,
        width: 30,
        cursor: 'pointer',
        '& img': {
            display: 'block',
        },
        '& .MuiBadge-colorPrimary': {
            backgroundColor: '#9e1a1a',
            fontWeight: 'bold',
            padding: '0px 5px',
        },
    }
}))

export default UserMenuStyle
