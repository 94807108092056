import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '../../components/Button.jsx'
import WizardButtons from './wizard/WizardButtons.jsx'
import GridContainer from '../GridContainer.jsx'
import GridItem from '../GridItem.jsx'

const styles = theme => ({
  root: {
    marginTop: 80,
    width: 420,
    margin: '0 auto',
  },
  question: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 26,
  },
  yesButton: {
    background: theme.palette.primary.main,
    color: theme.palette.text.alternate,
    height: '36px',
    width: '120px',
  },
  noButton: {
    background: '#F0F1F3',
    color: theme.palette.text.primary,
    height: '36px',
    width: '120px',
  },
  buttonContainer: {
    marginBottom: 80,
  },
})

const Confirmation = props => {
  const { classes, question, noText, yesText, getAnswer, backStep } = props

  return (
    <div>
      <GridContainer
        justify="center"
        direction="column"
        alignItems="center"
        className={classes.root}
      >
        <GridItem className={classes.question}>{question}</GridItem>
        <GridContainer
          item
          justify="center"
          alignItems="center"
          className={classes.buttonContainer}
          spacing={2}
        >
          <GridItem>
            <Button
              onClick={() => getAnswer(noText)}
              classes={{ root: classes.noButton }}
            >
              {noText}
            </Button>
          </GridItem>
          <GridItem>
            <Button
              color="primary"
              onClick={() => getAnswer(yesText)}
              classes={{ root: classes.yesButton }}
            >
              {yesText}
            </Button>
          </GridItem>
        </GridContainer>
      </GridContainer>
      <WizardButtons backStep={backStep} />
    </div>
  )
}

Confirmation.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Confirmation)
