import React from 'react'
import GridContainer from '../../../../layout/GridContainer'
import GridItem from '../../../../layout/GridItem'
import UserAvatar from '../UserAvatar'
import useStyles from './ActivityStyles'
import InfoText from '../../../../components/InfoText'
import { formatStringDate, formatActivityDate } from '../../../../../helpers/dates'
import { userRolesCaptitalized } from './../../../../../constants' //have to use relative as constants is a used module

const ActivityComment = ({ activityInfo }) => {
  const classes = useStyles()
  const { nickname, role, time, text, commentId, avatar } = activityInfo
  const dateString = formatStringDate(time)

  const showComment = () => <GridContainer className={classes.comment}>
    <GridContainer direction="column" item xs={10}>
      <InfoText
        normalCase
        label={`${nickname} | ${userRolesCaptitalized[role]} | ${formatActivityDate(time)}`}
      >
        {text}
      </InfoText>
    </GridContainer>
    <GridItem xs={2}>
      <UserAvatar avatarImage={avatar} />
    </GridItem>
  </GridContainer>

  const showActivity = () => <GridContainer className={classes.comment}>
    <GridItem xs={2}>
      <UserAvatar avatarImage={avatar} />
    </GridItem>
    <GridContainer direction="column" item xs={10}>
      <InfoText
        normalCase
        label={`${nickname} | ${userRolesCaptitalized[role]} | ${formatActivityDate(time)}`}
      >
        {text}
      </InfoText>
    </GridContainer>
  </GridContainer>

  return (
    !commentId ? showComment() : showActivity()
  )

}
export default ActivityComment
