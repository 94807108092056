import React from 'react'
import { Provider } from 'mobx-react'
import { CssBaseline } from '@material-ui/core'
import { MobxRouter } from 'mobx-router'
import store from './store'
import AppScriptLoader from './AppScriptLoader.jsx'
import theme from './theme/radar-care/theme.js'
import withTheme from './helpers/withTheme.js'
import AppShared from './app/layout/boxes/dialogs/AppShared.jsx'
import AppSetup from 'app-setup.js'

AppSetup(store)

const App = () => {
  return (
    <AppScriptLoader>
      <Provider store={store} {...store}>
          <AppShared />
          <CssBaseline />
          <MobxRouter />
      </Provider>
    </AppScriptLoader>
  )
}

export default withTheme(theme)(App)
