export default ({ theme, white, attach, nest, ICON, INPUT_ADORNMENT }) => ({
  MuiSelect: {
    root: {
    },
    select: {
      '&:focus': {
        backgroundColor: white.text
      }
    }
  }
})
