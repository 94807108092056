import React from 'react'
import { inject } from 'mobx-react'
import { Paper, Box, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Activity from './activity/Activity.jsx'
import Team from './team/Team.jsx'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  tab: {
    height: 353,
  },
}))

const TeamAndActivity = inject('caseStore')(({ caseStore }) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(1)

  function handleChange(event, newValue) {
    setValue(newValue)
  }

  function getTab(event, newValue) {
    if (value === 0) {
      return <Team />
    } else {
      return <Activity />
    }
  }

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="team and activity"
        variant="fullWidth"
      >
          <Tab label="Team" id={1} />
          <Tab label="Activity" id={2} />
      </Tabs>
        <Box className={classes.tab} >
          {getTab()}
        </Box>
    </Paper>
  )
})

export default TeamAndActivity
