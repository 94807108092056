import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    CaseHeader:{
      color: '#232735',
      paddingLeft: 15,
      borderBottom: '1px solid #E1E1E3',
      margin: 5,
      '& p':{
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 18,
      },
    },
    caseButtons:{
      '& button':{
        margin: 10,
        width: '45%',
        padding: '5px 30px',
        backgroundColor:'#F1F1F1',
        color:'#443699',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
      },
    }

}));

export default useStyles;