import React, { Fragment } from 'react'
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import NotificationsStyle from './NotificationsStyle.jsx'
import Checkbox from '../../components/Checkbox'
import AddressbookIcon from '../../../app/assets/icons/AddressbookIcon'
import KeyIcon from '../../../app/assets/icons/KeyIcon'
import LockIcon from '../../../app/assets/icons/LockIcon'
import ClosedIcon from '../../../app/assets/icons/ClosedIcon'
import DeleteIcon from '../../../app/assets/icons/DeleteIcon'
import CrossIcon from '../../../app/assets/icons/CrossIcon'
import { observer, inject } from 'mobx-react'
import { activityTypes } from 'constants.js'

const Notifications = inject('activityStore')(
  observer(({ activityStore }) => {
    const classes = NotificationsStyle()

    const userNotifications = activityStore.userNotifications

    const getNotificationIcon = (notification) => {
      if (notification.type === activityTypes.STRATEGY_LOCK) {
        return <LockIcon />
      }
      if (notification.type === activityTypes.OWNER_ASSIGNED) {
        return <KeyIcon />
      }
      if (notification.type === activityTypes.COMMENT) {
        return <AddressbookIcon />
      }
      if (notification.type === activityTypes.PATIENT_DISCHARGED) {
        return <ClosedIcon />
      }
    }

    function onBodyClick(e) {
      const element = e.target.closest('[data-type]')
      if (!element)  return
      const type = element.getAttribute('data-type')
      const notificationId = element.closest('[data-id]').getAttribute('data-id')
      switch (type) {
        case 'row':
          activityStore.markNotificationAsRead(notificationId)
          break
        case 'delete':
          activityStore.deleteNotification(notificationId)
          break
      }
    }

    return (
      <>
        <div className={classes.TableHeaing}>
          <h2>Notifications</h2>
        </div>
        <div className={classes.container}>
          <div className='notificationsMessage'>
            <p>{userNotifications.length} Notifications</p>
          </div>
          <div className='MarkReadHeading'>
            <h4>Mark all as read <DeleteIcon /> </h4>
          </div>
          <Table className='Table'>
            <TableHead>
              <TableRow>
                <TableCell className='col1'>
                  <Checkbox />
                </TableCell>
                <TableCell>
                  ACTIVITY
              </TableCell>
                <TableCell>
                  CASE NUMBER
              </TableCell>
                <TableCell>
                  DETAILS
              </TableCell>
                <TableCell>
                  TIME

              </TableCell>
              </TableRow>
            </TableHead>

            <TableBody onClick={onBodyClick}>
              {
                userNotifications.map((notification, index) => (
                  <TableRow 
                    className={!notification.isRead ? 'notRead' : ''} 
                    key={index} 
                    data-id={notification.id}
                    data-type='row'
                  >
                    <TableCell className='col1'>
                      <Checkbox />
                    </TableCell>
                    <TableCell className='MuitblCell'>
                      {getNotificationIcon(notification)}
                      {notification.activityMessage}
                    </TableCell>
                    <TableCell className='MuitblCell'>
                      {notification.caseNumber}
                    </TableCell>
                    <TableCell className='MuitblCellData'>
                      {notification.details}
                    </TableCell>
                    <TableCell className='MuitblCellData'>
                      {notification.time}
                      <span data-type='delete'>
                        <CrossIcon />
                      </span>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </div>
      </>
    )
  })
)

export default Notifications
