import React from 'react';
import { observer, inject } from 'mobx-react'
import { Box, Dialog } from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';

const LoadingDialog = inject('dialogStore')(
  observer(({ dialogStore }) => {

    return (
      <Dialog open={dialogStore.loadingDialogInfo}>
          <Box 
            width="300px"
            height="200px"
            display="flex"
            flexDirection="column"
            justifyContent="center" 
            alignItems="center"
          >
            <Box m={1}>
              <h2>{dialogStore.loadingDialogInfo.text}</h2>
            </Box>
            <Box p={1} 
              width="250px"
              height="20px"
            >
              <LinearProgress />
            </Box>
          </Box>
      </Dialog>
    )
}))

export default LoadingDialog
