import React from 'react'
import { Formik } from 'formik'
import { observer, inject } from 'mobx-react'
import * as Yup from 'yup'
import PatientAndPolicy from './PatientAndPolicy.jsx'

const validationSchema = Yup.object({
  policyNumber: Yup.string('Enter Case Id').required('Case ID is required'),
  caseNumber: Yup.string('Please Enter name').required('Name is required'),
  homeAddress: Yup.string('Please Enter home address')
    .nullable()
    .required('Home Address is required'),
  dateOfBirth: Yup.string('Please Enter Date Of Birth')
    .nullable()
    .required('Date Of Birth is required'),
})

const PatientAndPolicyContainer = inject('caseStore')(
  observer(props => {
    const { caseStore, isOnWizard, backStep, nextStep, onCancel } = props

    const handleSubmit = async values => {
      caseStore.merge(values)
      if (isOnWizard) {
        nextStep()
      } else {
        const res = await caseStore.save()
        nextStep()
      }
    }

    const compProps = {
      isOnWizard,
      handleSubmit,
      backStep,
      onCancel,
    }

    return (
      <Formik
        initialValues={caseStore.case.toJSON()}
        onSubmit={handleSubmit}
        render={props => (
          <PatientAndPolicy formikProps={props} {...compProps} />
        )}
        validationSchema={validationSchema}
      />
    )
  })
)

export default PatientAndPolicyContainer
