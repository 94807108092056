import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
 dateTimeLine: {
   '& .item': {
     borderLeft: "1px solid #aaa",
     fontSize: 13,
     fontStyle:'normal',
     fontWeight:'normal',
     textAlign: "left",
     paddingLeft: 10,
     color: theme.palette.text.black,
     '& div': {
       color: theme.palette.text.black,
     },
   },
   '& .MuiGrid-item:first-child .item': {
      borderLeft: "none",
      margin: "0px"
   },
   '& .active': {
     color: "red",
     '&:before': {
        content: '""',
        position: "absolute",
        width: "8px",
        height: "8px",
        marginLeft: "43px",
        marginTop: "3px",
        backgroundColor: "red",
        borderRadius: "100%"
     }
   },
 }
}));

