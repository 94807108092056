import React from 'react'
import Button from '@material-ui/core/Button';
import casePreviewStyle from './CasePreviewStyle.jsx';
import { inject } from 'mobx-react';
import Popover from '@material-ui/core/Popover';
import routes from '../../ViewsRoutes';

const CasePreview = ({ 
  previewProps, 
  setPreviewProps,
  gotoCase,
}) => {

  const showPopover = () => {
    const classes = casePreviewStyle()
    const caseId = previewProps.caseId
    const anchorEl = previewProps.element

    const handlePreviewClose = () => {
      setPreviewProps(null)
    };

    return (
      <Popover
        id="simple-popover"
        open={true}
        anchorEl={anchorEl}
        onClose={handlePreviewClose}
      >
        <div className={classes.container}>
          <div className={classes.CaseHeader}>
            <p>114589 | Ken Wheely</p>
            <span></span>
          </div>
          <div className={classes.caseImage}>
            <img src="/assets/images/case-preview.png"/>
          </div>
          <div className={classes.caseButtons}>
            <Button variant="contained" className={classes.button}>
              Reassign
            </Button>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => gotoCase(caseId)}
            >
              View Case
            </Button>
          </div>
        </div>
      </Popover>
    )
  }

  return previewProps ? showPopover() : null
}

export default CasePreview;
