import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { inject } from 'mobx-react'
import Button from '@material-ui/core/Button'
import { goto } from 'app/views/ViewsRoutes'
import useStyles from 'app/components/navbar/NavbarStyle.jsx'
import GridContainer from 'app/layout/GridContainer.jsx'
import GridItem from 'app/layout/GridItem.jsx'
import ClipboardList from 'app/assets/icons/ClipboardList'
import Bell from "app/components/navbar/Bell"
import UserMenu from "app/components/navbar/user-menu/UserMenu.jsx"

const Navbar = inject("store")(({
  showNewCase,
  showCaseList,
  store 
}) => {
  const onCaseLoad = () => {
    if (store.router.currentPath === '/new-case') {
      store.wizardStore.showWizardCancellationDialog()
      return
    }
    goto.caseLoad()
  }

  const classes = useStyles();

  return (
    <div className={classes.grow}>
      <AppBar className={classes.navBarTop} elevation={0} position="static"  color='secondary'>
        <Toolbar>
          <GridContainer justify="space-between">
            <GridItem className={classes.Burger}>
              <img src="/assets/images/hamburger.svg"/>
            </GridItem>
            <GridItem className={classes.headerLogoWrap}>
              <a href="/">
                <img src="/assets/images/radar-care.svg"/>
              </a>
            </GridItem>
            <GridItem className={classes.buttons}>
              <div className={classes.buttonsInner}>
                { showNewCase && 
                  <Button
                    to="/case"
                    variant="contained"
                    className={classes.button}
                    onClick={goto.newCase}
                  >New Case</Button>
                }
                {showCaseList ?
                  <Button onClick={onCaseLoad} className={classes.clipboardbtn}>
                    <ClipboardList className={classes.actionIcon} /> 
                  </Button> : <div className={classes.spacer} />
                }
                <Bell />
                <UserMenu />
              </div>
            </GridItem>
          </GridContainer>
        </Toolbar>
      </AppBar>
    </div>
  )
})

export default Navbar;
