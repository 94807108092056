import React from 'react'
import { inject, observer } from 'mobx-react'
import clsx from 'clsx'
import { Box, makeStyles } from '@material-ui/core'
import GridContainer from '../../../layout/GridContainer.jsx'
import GridItem from '../../../layout/GridItem.jsx'
import InfoText from '../../../components/InfoText.jsx'
import InfoBox from './InfoBox.jsx'
import { formatStringDate } from '../../../../helpers/dates'
import { fieldNames } from "constants.js"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  InfoBox: {
    marginTop: 20,
  },
}))

const PatientAndPolicyInfo = inject('caseStore')(
  observer(({ className, caseStore }) => {
    const classes = useStyles()

    const rootClass = clsx(classes.root, className)

    return (
      <Box mb={9}>
        <InfoBox title="Patient & Policy" componentType="patient_and_policy" className={classes.InfoBox}>
          <GridContainer spacing={3}>
            <GridItem xs={2}>
              <InfoText label="POLICY">{caseStore.case.get(fieldNames.POLICY_NUMBER)}</InfoText>
            </GridItem>
            <GridItem xs={5}>
              <InfoText label="NAME">{caseStore.case.get(fieldNames.PATIENT_NAME)}</InfoText>
            </GridItem>
            <GridItem xs={3}>
              <InfoText label="DATE OF BIRTH">{formatStringDate(caseStore.case.get(fieldNames.DATE_OF_BIRTH))}</InfoText>
            </GridItem>
            <GridItem xs={2}>
              <InfoText label="CASE">{caseStore.case.get(fieldNames.CASE_NUMBER)}</InfoText>
            </GridItem>
            <GridItem xs={2}>
              <InfoText label="STATUS">{caseStore.case.get(fieldNames.POLICY_STATUS)}</InfoText>
            </GridItem>
            <GridItem xs={8}>
              <InfoText label="ADDRESS">{caseStore.case.get(fieldNames.HOME_ADDRESS)}</InfoText>
            </GridItem>
            <GridItem xs={2}>
              <InfoText label="COVERAGE">{caseStore.case.get(fieldNames.COVERAGE)}</InfoText>
            </GridItem>
          </GridContainer>
        </InfoBox>
      </Box>
    )
  })
)

export default PatientAndPolicyInfo
