import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  guidelineheading:{
    fontSize: 24,
    fontWeight: 600,
    fontStyle: 'normal',
    margin: '30px 0px 0px 0px',
  },
}));

export default useStyles;
