import React, { Fragment } from 'react'
import { inject } from 'mobx-react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { goto } from 'app/views/ViewsRoutes'
import User from 'lib/user'
import useStyles from './LoginStyle.jsx'
import GridContainer from 'app/layout/GridContainer.jsx'
import GridItem from 'app/layout/GridItem.jsx'
import PasswordInputField from './PasswordInputField.jsx'
// import TextField from '@material-ui/core/TextField';
import TextField from 'app/components/TextField'
import CheckboxLabels from './CheckBox.jsx'
import { InputLabel } from '@material-ui/core'

const validationSchema = Yup.object({
  email: Yup.string('Please Enter Email').required('Email is required'),
  password: Yup.string('Please Enter Password').required('Password is required')
})

function LoginForm ({ formikProps }) {
  const [failMessage, setFailMessage] = React.useState(null)
  const classes = useStyles()

  async function login () {
    const email = formikProps.values.email
    const password = formikProps.values.password
    const loginResult = await User.signIn(email, password)
    if (loginResult.status === 'OK') {
      goto.caseLoad()
    } else {
      setFailMessage(loginResult.message)
    }
  }

  return (
    <form autoComplete='off'>
      <GridContainer justify='space-between'>
        <GridItem sm={8} className={classes.GridLeft} />
        <GridItem sm={4} className={classes.GridRight}>
          <Card className={classes.card}>
            <CardContent className={classes.CardContent}>
              <a href='/' className={classes.RadarLogo}>
                <img src='/assets/images/radar-care.svg' />
              </a>
              <div className={classes.LoginButtonWrap}>
                <Typography className={classes.LoginHeading} variant='h5' component='h2'>Login</Typography>
                <div className={classes.UserTextField}>
                  <TextField
                    formlabel='USERNAME'
                    name='email'
                    placeholder='@yourname'
                    className={classes.TextFieldInput}
                    formik={formikProps}
                  />
                </div>
                <div className={classes.PassTextField}>
                  <InputLabel className={classes.InputLable}>PASSWORD</InputLabel>
                  <PasswordInputField
                    name='password'
                    formik={formikProps}
                  />
                </div>
                <CheckboxLabels />
              <Button 
                color="primary"
                variant="contained"
                classes={{root: classes.button, label: classes.buttonLabel}}
                onClick={login}
              >
                Login
              </Button>
              </div>
              <div>
                {failMessage}
              </div>
            </CardContent>
          </Card>
        </GridItem>
      </GridContainer>
    </form>
  )
}

const Login = inject('store')(({ store }) => {
  function handleSubmit () {
    console.log('handleSubmit', handleSubmit)
  }

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={handleSubmit}
      render={props => (
        <LoginForm formikProps={props} />
      )}
      validationSchema={validationSchema}
    />
  )
})

export default Login
