import React, { useState, useRef, useEffect } from 'react'
import GridContainer from '../../../../layout/GridContainer'
import GridItem from '../../../../layout/GridItem'
import useStyles from './ActivityStyles'
import { InputBase, Button, InputAdornment } from '@material-ui/core'
import clsx from 'clsx'
import { observer, inject } from 'mobx-react'
import ActivityComment from './ActivityComment'
import AttachIcon from '../../../../assets/icons/AttachIcon.jsx'
import UserAvatar from '../UserAvatar'

const Activity = inject('activityStore', 'caseStore')(
  observer(({ activityStore, caseStore }) => {
    const classes = useStyles()
    const caseActivities = activityStore.caseActivities.toJSON()
    const caseData = caseStore.case.toJSON()
    const [comment, setComment] = useState('')
    const messageEndRef = useRef(null)

    useEffect(() => {
      if (messageEndRef.current) {
        const isScrolledToBottom = messageEndRef.current.scrollHeight - messageEndRef.current.clientHeight <= messageEndRef.current.scrollTop + 1
        if (!isScrolledToBottom) {
          messageEndRef.current.scrollTop = messageEndRef.current.scrollHeight - messageEndRef.current.clientHeight
        }
      }
    }, [caseActivities.length])

    const addComment = () => {
      if (comment) {
        activityStore.saveComment(caseData.id, {
          strategy: 'repatriate', // fetch from store
          comment,
        })
        setComment('')
      }
    }

    const handleCommentChange = e => {
      setComment(e.target.value)
      if (e.key === 'Enter') {
        addComment()
      }
    }

    return (
      <GridContainer justify="flex-end" className={classes.root}>
        <GridContainer
          item
          alignContent="flex-start"
          className={classes.commentContainer}
          ref={(el) => { messageEndRef.current = el }}
        >
          {caseActivities && caseActivities.map((activityInfo, index) => {
            return (
              <ActivityComment
                key={index}
                activityInfo={activityInfo} />
            )
          })}
        </GridContainer>
        <GridContainer
          item
          direction="row"
          className={clsx(classes.inputContainer, classes.padding10)}
          justify="space-between"
        >
          <GridItem xs={8}>
            <InputBase
              placeholder="Comment here..."
              inputProps={{ 'aria-label': 'naked' }}
              variant="standard"
              className={classes.textField}
              value={comment}
              onChange={handleCommentChange}
              onKeyDown={handleCommentChange}
            />
          </GridItem>
          <GridItem className={classes.ButtonWrap}>
            <AttachIcon />
            <Button
              color="primary"
              variant="contained"
              onClick={addComment}
            >
              Post
            </Button>
          </GridItem>
        </GridContainer>
      </GridContainer>
    )
  }),
)

export default Activity
