import React from 'react'
import { observer, inject } from 'mobx-react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Box, Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'
import UnderLineButton from '../../../components/UnderLineButton.jsx';

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: 'none',
    height: 36,
    fontSize: 16,
    fontWeight: 600,
  },
  next: {
    marginLeft: 5,
    width: 148,
  },
  WizardBack: {
    backgroundColor:'transparent !important',
    color: '#232735', 
    '& span:hover': {
      borderBottom: '1px solid  #B9B8BF',
      color: '#B9B8BF',
    }
  },
}))

const WizardButtons = inject('wizardStore')(
  observer(({ 
    wizardStore,
    backStep,
    nextStep,
    backText,
    nextText,
    isOnWizard,
  }) => {

  const classes = useStyles()

  function onExitWizard() {
    wizardStore.showWizardCancellationDialog()
  }

  return (
    <Grid container spacing={4}>
      <Grid item sm={12}>
        <React.Fragment>
          <Box display="inline-flex" width="50%" justifyContent="flex-start">
            {isOnWizard && (
              <UnderLineButton text="Exit Wizard" onClick={onExitWizard}/>
            )}
          </Box>
          <Box width="50%" display="inline-flex" justifyContent="flex-end">
            {backStep && (
              <Button
                color="secondary"
                variant="contained"
                className={clsx(classes.button, classes.back)}
                onClick={() => backStep()}
              >
                {backText || 'Back'}
              </Button>
            )}
            {nextStep && (
              <Button
                color="primary"
                variant="contained"
                className={clsx(classes.button, classes.next)}
                onClick={nextStep}
              >
                {nextText || 'Next'}
              </Button>
            )}
          </Box>
        </React.Fragment>
      </Grid>
    </Grid>
  )
}))

WizardButtons.propTypes = {}

export default WizardButtons
