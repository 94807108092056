import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MuiToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButtonStyle from './ToggleButtonStyle'

const ToggleButton = ({ 
  initState,
  label,
  states,
  onChange
}) => {

  const classes = ToggleButtonStyle()

  const [toggleState, setToggleState] = React.useState(initState);

  const handleChange = (event, state) => {
    if (!state) return;
    setToggleState(state);
    onChange && onChange(state)
  };

  return (
    <div className={classes.root}>
      <div className={classes.label}> 
        {label}
      </div>
      <ToggleButtonGroup value={toggleState} className={classes.group} size="small" exclusive onChange={handleChange}>
        <MuiToggleButton 
          key={1} 
          className={clsx(classes.leftButton, classes.button)} 
          value={states[0].value}
        >
          {states[0].label}
        </MuiToggleButton>,
        <MuiToggleButton
          key={2}
          className={clsx(classes.rightButton, classes.button)}
          value={states[1].value}
        >
          {states[1].label}
        </MuiToggleButton>,
      </ToggleButtonGroup>
    </div>
  );
}

ToggleButton.propTypes = {
  initState: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  states: PropTypes.array.isRequired,
};

export default ToggleButton

