export default ({ nest, primary, NOTCHED_OUTLINE }) => ({
  MuiOutlinedInput: {
    root: {
      '& svg': {
        color: '#443699',
        fontSize: '26px'
      },
      [nest(NOTCHED_OUTLINE.focused)]: {
        border: `2px solid ${primary.main}`
      },
      backgroundColor: '#fafafa',
      borderRadius: 3
    },
    notchedOutline: {
      border: '1px solid #dbdbdb'
    },
    input: {
      padding: '12.5px 10px 12.5px 10px',
      fontSize: 16,
      lineHeight: '18px'
    }
  }
})
