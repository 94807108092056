import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '730px',
    padding: '15px 70px',
  },
  button: {
    backgroundColor: theme.palette.secondary.light,
    marginRight: '15px',
  },
  listItem: {
    padding: '15px 5px',
  },
  listItemContainer: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  greyCircle: {
    height: '8px',
    width: '8px',
    borderRadius: '50%',
    color: theme.palette.secondary.dark,
    marginRight: '15px',
    marginLeft: '15px',
  },
  listName: {
    width: '350px',
    paddingLeft: '10px',
  },
  list: {
    color: theme.palette.text.black,
    fontSize: '16px',
    lineHeight: '18px',
  },
  divider: {
    backgroundColor: '#E1E1E3',
    margin: 0,
  },
}))

export default useStyles
