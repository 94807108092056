import React from 'react';
import GridContainer from 'app/layout/GridContainer.jsx';
import GridItem from 'app/layout/GridItem.jsx';
import { addDays } from 'date-fns'
import { formatDate, strategyDay } from 'helpers/dates.js'
import useStyles from './DateTimeLineStyle.jsx'

export default function DateTimeLine({ startDay, dateOfAdmission }) {
  const classes = useStyles();

  const today = new Date()
  const currentDay = strategyDay(today, dateOfAdmission)

  const getDaysItems = () => {
    return [...Array(10)].map((i, index) => {
      const day = index + 1 + startDay
      const className = day === currentDay ? 'item active' : 'item' 
      const dateString = formatDate(addDays(dateOfAdmission, index))
      return (
        <GridItem xs key={index}>
          <div className={className}>
            DAY {day} 
            <div>{dateString}</div>
          </div>
        </GridItem>
      )
    })
  }

  return (
    <GridContainer spacing={0} className={classes.dateTimeLine} >
      {getDaysItems()}
    </GridContainer>
  );
}
