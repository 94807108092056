import React from 'react'
import clsx from 'clsx'
import { observer, inject } from 'mobx-react'
import { Paper, Toolbar, Typography, Box, makeStyles } from '@material-ui/core'
import Button from '../../../components/Button.jsx'
import HoriziontalDivider from '../../../components/HoriziontalDivider.jsx'
import ContentBox from '../../../layout/boxes/content-box/ContentBox.jsx'
import PatientAndPolicyContainer from '../../wizard/patient-and-policy/PatientAndPolicyContainer.jsx'
import AdmissionAndClinicalContainer from '../../wizard/admission-and-clinical/AdmissionAndClinicalContainer.jsx'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: 10,
    '& .MuiToolbar-dense':{
      minHeight:50,
    },

  },
  grow: {
    flexGrow: 1,
  },
  button: {
    width: 71,
    height: 38,
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.text.black,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: 1,
  },
  contentBox: {
    padding: '0 20px 20px 20px',
  },
}))

const InfoBox = inject('dialogStore')(
  observer(({ dialogStore, title, children, componentType, className }) => {
    const classes = useStyles()

    const rootClass = clsx(classes.root, className)

    const onSubmit = () => {
      dialogStore.close()
    }

    const closeDialog = () => {
      dialogStore.close()
    }

    const onCancel = () => {
      dialogStore.openConfimrationDialog(closeDialog)
    }

    const getEditComponent = () => {
      let editComponent
      switch (componentType) {
        case 'patient_and_policy':
          editComponent = (
            <PatientAndPolicyContainer
              nextStep={onSubmit}
              backStep={onCancel}
              isOnWizard={false}
            />
          )
          break
        case 'admission_and_clinical':
          editComponent = (
            <AdmissionAndClinicalContainer
              nextStep={onSubmit}
              backStep={onCancel}
              isOnWizard={false}
            />
          )
          break
      }

      return (
        <ContentBox
          mainTitle="Edit"
          title={title}
          onCancel={onCancel}
          isOnWizard={false}
        >
          {editComponent}
        </ContentBox>
      )
    }

    const onEdit = () => {
      dialogStore.open(getEditComponent())
    }

    return (
      <div className={rootClass}>
        <Paper>
          <Toolbar variant="dense">
            <Typography className={classes.title}>{title}</Typography>
            <div className={classes.grow} />
            <Button className={classes.button} onClick={onEdit}>
              Edit
            </Button>
          </Toolbar>
          <Box>
            <div className={classes.grow} />
          </Box>
          <HoriziontalDivider />
          <Box p={2} className={classes.contentBox}>
            {children}
          </Box>
        </Paper>
      </div>
    )
  }))

export default InfoBox
