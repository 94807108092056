import React from 'react';
import Confirmation from '../../layout/boxes/Confirmation.jsx';
import { observer, inject } from 'mobx-react';

const PolicyValidation = inject("caseStore")(observer(({ caseStore, backStep, nextStep }) => {
  const noText='Pending'
  const yesText='Valid'

  const getAnswer = (answer) => {
    const policyStatus = answer === yesText ? 'valid' : 'pending' 
    caseStore.merge({policyStatus})
    nextStep()
  }

  return (
    <Confirmation 
      question='Is the policy valid?'
      noText={noText}
      yesText={yesText}
      getAnswer={getAnswer}
      backStep={backStep}
    />
  );
}))

export default PolicyValidation;
