import gql from 'graphql-tag'


export const SAVE_COMMENT_QUERY = gql`
    mutation($caseId:ID!,$comment: JSON){
      saveComment(caseId:$caseId, comment: $comment) {
        id
        comment
        date
        type
        tagged
        userId
        caseId
      }
    }`

