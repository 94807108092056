import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  underLineButton: {
    display: 'inline-block',
    cursor: 'pointer',
    textDecoration: 'underline',
    color:'#B9B8BF',
    fontSize: 16,
  }
}));

const UnderLineButton = ({ text, onClick }) => {

  const classes = useStyles();

  return (
    <span onClick={onClick} className={classes.underLineButton}>
      {text}
    </span>
  );
}

export default UnderLineButton;
