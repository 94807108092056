import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'

const URI = process.env.REACT_APP_GRAPHQL_URI

const httpLink = createHttpLink({ uri: URI })

const authLink = setContext((_, { headers }) => {
  const token = window.app.jwtToken

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`
    }
  }
})

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      if (message.includes('must be logged')) {
        localStorage.removeItem('userDataAndToken')
        window.location.replace('/')
      }
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    })
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const link = ApolloLink.from([
  authLink.concat(httpLink),
  onErrorLink
])

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
})

export default client
