import SocketClient from 'lib/socket/client.js'

const SOCKET_URI = process.env.REACT_APP_SOCKET_URI

class SocketManager {
  constructor (store) {
    this.sc = null
    this.store = store
    this.initSocket()
  }

  initSocket () {
    console.log('initSocket')
    const jwtToken = window.app.jwtToken
    if (jwtToken) {
      this.sc = new SocketClient(SOCKET_URI, jwtToken)
      this.sc.on('close', this.restartConnection.bind(this))
      this.sc.on('activity', this.onActivity.bind(this))
      window.app.tsc = this.sc
      console.log('socket client has created', window.app.tsc)
    }
  }

  onActivity (activityObject) {
    this.store.activityStore.addActivity(activityObject)
  }

  restartConnection () {
    this.sc = null
    this.initSocket()
  }

  close () {
    if (this.sc) {
      this.sc.close()
    }
  }
}

export default SocketManager
