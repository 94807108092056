const _ = require('lodash') 

export const validateFrom = async (formikProps) => {
  formikProps.submitForm()
  const errors = await formikProps.validateForm()
  const isValid = _.isEmpty(errors)

  return isValid
}

export const submitOnValidation = async (formikProps, handleSubmit) => {
  const isValid = await validateFrom(formikProps)
  isValid && handleSubmit(formikProps.values)

  return isValid
}
