export const DATE_FORMAT = 'dd.MM.yy'
export const ACTIVITY_DATE_FORMAT = 'dd.MM.yy hh:mm'
export const ADMISSION = 'admission'
export const PROCEDURE = 'procedure'
export const AIR_AMBULANCE = 'air-ambulance'
export const COMMERCIAL_FLIGHT = 'commercial-flight'
export const OTHER = 'other'
export const DISCHARGE = 'discharge'
export const STAY = 'stay'
export const REPATRIATE = 'repatriate'
export const TRANSFER = 'transfer'
export const EVACUATE = 'evacuate'
export const CUSTOM = 'custom'

export const CASE_MANAGER = 'case_manager'
export const OPERATIONS = 'operations'
export const MEDICAL_DIRECTOR = 'medical_director'

export const userRoles = {
  CASE_MANAGER,
  OPERATIONS,
  MEDICAL_DIRECTOR
}

const PENDNG = 'pending'
const VALID = 'valid'

export const coverage = { PENDNG, VALID }
export const policyStatus = { PENDNG, VALID }

const LIKE = 'like'
const UNLIKE = 'unlike'
export const likeTypes = { LIKE, UNLIKE }

export const eventTypes = {
  ADMISSION,
  DISCHARGE,
  PROCEDURE,
  AIR_AMBULANCE,
  COMMERCIAL_FLIGHT,
  OTHER
}

export const eventTypeOptions = {
  ADMISSION,
  PROCEDURE,
  AIR_AMBULANCE,
  COMMERCIAL_FLIGHT,
  OTHER,
  DISCHARGE
}

export const eventTypesWithLabels = {
  [ADMISSION]: 'Admission',
  [PROCEDURE]: 'Procedure',
  [AIR_AMBULANCE]: 'Air Ambulance',
  [COMMERCIAL_FLIGHT]: 'Commercial Flight',
  [OTHER]: 'Other',
  [DISCHARGE]: 'Discharge'
}

export const eventTypesSelectOptions = Object.keys(eventTypesWithLabels)
  .reduce((filtered, eType) => {
    if (eType !== ADMISSION && eType !== DISCHARGE) {
      filtered.push({
        value: eType,
        label: eventTypesWithLabels[eType]
      })
    }
    return filtered
  }, [])

const FIELD_CHANGED = 'field_changed'
const STRATEGY_LOCK = 'strategy_lock'
const STRATEGY_RECOMMENDED = 'strategy_recommended'
const OWNER_ASSIGNED = 'owner_assigned'
const NEW_FOLLOWER = 'new_follower'
const NEW_STRATEGY = 'new_strategy'
const NEW_EVENT = 'new_event'
const UPDATED_EVENT = 'updated_event'
const REMOVE_EVENT = 'remove_event'
const PATIENT_DISCHARGED = 'patient_discharged'
const URGENCY_CHANGED = 'urgency_changed'
const COMMENT = 'comment'

export const activityTypes = {
  FIELD_CHANGED,
  STRATEGY_LOCK,
  OWNER_ASSIGNED,
  NEW_FOLLOWER,
  NEW_STRATEGY,
  NEW_EVENT,
  UPDATED_EVENT,
  REMOVE_EVENT,
  PATIENT_DISCHARGED,
  URGENCY_CHANGED,
  STRATEGY_RECOMMENDED,
  COMMENT
}


const POLICY_NUMBER = 'policyNumber'
const CASE_NUMBER = 'caseNumber'
const PATIENT_NAME = 'patientName'
const DATE_OF_BIRTH = 'dateOfBirth'
const HOME_ADDRESS = 'homeAddress'
const OTHER_DETAILS = 'otherDetails'
const HOSPITAL = 'hospital'
const CONDITION = 'condition'
const DATE_OF_ADMISSION = 'dateOfAdmission'
const CURRENCY = 'currency'
const NOTIFY = 'notify'
const OWNER_ID = 'ownerId'
const URGENCY = 'urgency'
const NOTES = 'notes'
const POLICY_STATUS = 'policyStatus'
const COVERAGE = 'coverage'
const INCURRED_CHARGES = 'incurredCharges'
const ADDITIONAL_MEMBERS = 'additionalMembers'
const STRATEGIES = 'strategies'
const HOSPITAL_RELATIONS = 'hospitalRelations'

export const fieldNames = {
  POLICY_NUMBER,
  CASE_NUMBER,
  PATIENT_NAME,
  POLICY_STATUS,
  DATE_OF_BIRTH,
  HOME_ADDRESS,
  OTHER_DETAILS,
  HOSPITAL,
  CONDITION,
  DATE_OF_ADMISSION,
  CURRENCY,
  NOTIFY,
  OWNER_ID,
  URGENCY,
  NOTES,
  COVERAGE,
  INCURRED_CHARGES,
  ADDITIONAL_MEMBERS,
  STRATEGIES,
  HOSPITAL_RELATIONS
}

export const fieldNamesCaptialized = {
  [fieldNames.POLICY_NUMBER]: 'Policy Number',
  [fieldNames.DATE_OF_BIRTH]: 'Date Of Birth',
  [fieldNames.CASE_NUMBER]: 'Case Number',
  [fieldNames.POLICY_STATUS]: 'Policy Status',
  [fieldNames.HOME_ADDRESS]: 'Patient Home Address',
  [fieldNames.COVERAGE]: 'Coverage',
  [fieldNames.OTHER_DETAILS]: 'Other Details',
  [fieldNames.NOTIFY]: 'Notify',
  [fieldNames.PATIENT_NAME]: 'Patient Name',
  [fieldNames.CONDITION]: 'Condition',
  [fieldNames.HOSPITAL]: 'Hospital',
  [fieldNames.NOTES]: 'Notes',
  [fieldNames.CURRENCY]: 'Currency',
  [fieldNames.HOSPITAL_RELATIONS]: 'Hospital Relations',
  [fieldNames.DATE_OF_ADMISSION]: 'Date Of Admission',
}

export const userRolesCaptitalized = {
  [userRoles.CASE_MANAGER]: 'Case Manager',
  [userRoles.OPERATIONS]: 'Operations',
  [userRoles.MEDICAL_DIRECTOR]: 'Medical Director'
}

export const strategyTypes = {
  STAY,
  REPATRIATE,
  TRANSFER,
  EVACUATE,
  CUSTOM
}
