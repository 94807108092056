import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import clsx from 'clsx';

const style = {
  grid: {}
};

const GridItem = (props) => {
  const { classes, className, children, ...rest } = props;
  const gridItemClasses = clsx(classes.grid, className)
  return (
    <Grid item {...rest} className={gridItemClasses}>
      {children}
    </Grid>
  );
}

export default withStyles(style)(GridItem);
