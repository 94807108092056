import React from 'react'
import PropTypes from 'prop-types'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles, Box } from '@material-ui/core'
import WizardButtons from 'app/layout/boxes/wizard/WizardButtons.jsx'
import GridContainer from 'app/layout/GridContainer.jsx'
import GridItem from 'app/layout/GridItem.jsx'
import { submitOnValidation } from 'helpers/formik-helpers.js'
import TextField from 'app/components/TextField.jsx'
import HoriziontalDivider from 'app/components/HoriziontalDivider.jsx'
import DatePicker from 'app/components/RcDatePicker.jsx'
import AutoComplete from 'app/components/react-places-autocomplete/AutoComplete.jsx'
import ToggleButton from 'app/components/toggle-button/ToggleButton.jsx'
import { fieldNames, coverage, policyStatus } from "constants.js"

const useStyles = makeStyles(theme => ({
  root: {},
  separator: {
    padding: '15px 8px !important',
    '& hr': {
      margin: 0
    }
  },
  separator2: {
    padding: '8px 8px !important',
  },
}))

const PatientAndPolicy = props => {
  const { handleSubmit, formikProps, isOnWizard, backStep, onCancel } = props

  const [homeAddress, setHomeAddress] = React.useState(
    formikProps.values.homeAddress
  )
  const classes = useStyles()

  const onNext = async () => {
    const isValid = await submitOnValidation(formikProps, handleSubmit)
  }

  const onAddressSelected = address => {
    if (address.description) {
      formikProps.values.homeAddress = address.description
      setHomeAddress(formikProps.values.homeAddress)
    }
  }
  const editButtonProps = !isOnWizard
    ? { backStep, nextText: 'Submit', backText: 'Cancel' }
    : {}

  const onCoverageChange = newState => {
    const value = newState === 'valid' ? coverage.VALID : coverage.PENDNG
    formikProps.values.coverage = value
  }

  const onPolicyChange = newState => {
    const value = newState === 'valid' ? policyStatus.VALID : policyStatus.PENDNG
    formikProps.values.policyStatus = value
  }

  return (
    <form autoComplete="off">
      <GridContainer justify="space-between" spacing={4}>
        <GridItem xs={6}>
          <TextField
            name={fieldNames.POLICY_NUMBER}
            formlabel="Policy *"
            required
            formik={formikProps}
            placeholder="Enter Policy Number"
            icon={<SearchIcon className={classes.inputAdornmentIcon} />}
            fullWidth
          />
        </GridItem>
        <GridItem xs={6}>
          <TextField
            name={fieldNames.CASE_NUMBER}
            formlabel="Case *"
            required
            formik={formikProps}
            icon={<SearchIcon className={classes.inputAdornmentIcon} />}
            placeholder="Enter Case Number"
            fullWidth
          />
        </GridItem>
        <GridItem xs={12} className={classes.separator}>
          <HoriziontalDivider />
        </GridItem>
        <GridItem xs={6}>
          <TextField
            formlabel="Patient Name"
            name={fieldNames.PATIENT_NAME}
            placeholder="Enter patient full name"
            icon={<SearchIcon className={classes.inputAdornmentIcon} />}
            required
            formik={formikProps}
            fullWidth
          />
        </GridItem>
        <GridItem xs={6}>
          <DatePicker
            name={fieldNames.DATE_OF_BIRTH}
            formlabel="Date of Birth *"
            openTo="year"
            views={["year", "month", "date"]}
            disableFuture
            required
            formik={formikProps}
            icon={<SearchIcon className={classes.inputAdornmentIcon} />}
          />
        </GridItem>
        <GridItem xs={12} className={classes.separator2}>
        </GridItem>
        <GridItem xs={12}>
          <AutoComplete
            formlabel="Home Address"
            onChange={onAddressSelected}
            initValue={homeAddress}
            name="homeAddress"
            formik={formikProps}
          />
        </GridItem>
        {!isOnWizard &&
          <GridItem xs={12}>
            <Box p={2}>
              <GridContainer justify="space-around" >
                <ToggleButton
                  initState={formikProps.values.policyStatus}
                  label="Policy Status"
                  onChange={onPolicyChange}
                  states={[
                    { label: 'Pending', value: policyStatus.PENDNG },
                    { label: 'Valid', value: policyStatus.VALID }
                  ]}
                />
                <ToggleButton
                  initState={formikProps.values.coverage}
                  label="Coverage"
                  onChange={onCoverageChange}
                  states={[
                    { label: 'Pending', value: coverage.PENDNG },
                    { label: 'Valid', value: coverage.VALID }
                  ]}
                />
              </GridContainer>
            </Box>
          </GridItem>
        }

        <GridItem xs={12}>
          <Box mt={4}>
            <WizardButtons
              onCancel={onCancel}
              isOnWizard={isOnWizard}
              nextStep={onNext}
              {...editButtonProps}
            />
          </Box>
        </GridItem>
      </GridContainer>
    </form>
  )
}

PatientAndPolicy.propTypes = {
  // classes: PropTypes.object.isRequired,
}

export default PatientAndPolicy
