import React, {Fragment} from 'react';
import Button from '@material-ui/core/Button';
import EllipseIcon from '../../../assets/icons/EllipseIcon';
import MapIcon from '../../../assets/icons/MapIcon';
import ListIcon from '../../../assets/icons/ListIcon';
import ButtonsStyle from './ActionsButtonsStyle.jsx'
import GridContainer from '../../../layout/GridContainer.jsx'
import GridItem from '../../../layout/GridItem.jsx'


export default function ContainedButtons({enableEditButtons, showMap, setShowMap}) {
  const classes = ButtonsStyle()
  return (
    <Fragment>
    <div className={classes.headerButtons}>
      <GridContainer>
        <GridItem xs={12} lg={6}>
          <p className="resultMessage">4 Cases, From All Continents, Any Owners</p>
        </GridItem>
        <GridItem xs={12} lg={6} >
          <span className="btnSet">
            <span className="btnSet2">
              <Button className="list-icon" onClick={ () => setShowMap(false)}>
                <ListIcon />
              </Button>
              <Button className="map-icon" onClick={ () => setShowMap(true)}>
                 <MapIcon />
              </Button>
              <Button className="ellipse-icon">
                <EllipseIcon />
              </Button>
            </span>
            { showMap || 
            <span className="btnSet1">
              <Button variant="contained" disabled={!enableEditButtons} className={classes.button}>
                Archive
              </Button>
              <Button variant="contained" disabled={!enableEditButtons} className={classes.button}>
                Discharged
              </Button>
              <Button variant="contained" disabled={!enableEditButtons} className={classes.button}>
                Send
              </Button>
              <Button variant="contained" disabled={!enableEditButtons} className={classes.button}>
                Reassign
              </Button>
            </span>
            }
          </span>
        </GridItem>
      </GridContainer>
    </div>
   </Fragment> 
  );
}
