import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import {
  FormControlLabel,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core'
import ReactSelect from '../../../components/ReactSelect.jsx'
import Checkbox from '../../../components/Checkbox.jsx'
import UrgencySlider from '../../../components/UrgencySlider.jsx'
import WizardButtons from '../../../layout/boxes/wizard/WizardButtons.jsx'
import TextField from '../../../components/TextField.jsx'
import { fieldNames } from "constants.js"
import { submitOnValidation } from '../../../../helpers/formik-helpers.js'

const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    marginLeft: '60px'
  },
  statsWizard: {
    color: theme.palette.text.black,
  },
}))

const StatusAndNotifications = inject('generalStore', 'caseStore')(
  observer(({ generalStore, caseStore, formikProps, handleSubmit, backStep, nextStep, isOnWizard, onCancel }) => {
    const classes = useStyles()

    const [owner, setOwner] = React.useState(null)
    const getOwner = () => {
      if (!owner && generalStore.usersSelectOptions.length) {
        const currentOwner = generalStore.usersSelectOptions.find(u => u.value == formikProps.values.ownerId)
        if (currentOwner) {
          setOwner(currentOwner)
        }
      }
    }

    const onOwnerChange = option => {
      formikProps.values.ownerId = option.value
      setOwner(option)
    }
      
    const onNext = async () => {
      const owner = generalStore.users.find(u => u.id == formikProps.values.ownerId)
      console.log('onNext owner', owner)
      formikProps.values.owner = owner
      console.log('formikProps', formikProps.values)
      const isValid = await submitOnValidation(formikProps, handleSubmit)
    }

    const notifyOptions = {
      case_manager: 'Case Manager',
      medical_director: 'Medical Director',
      operations: 'Operations',
    }

    useEffect(() => {
      generalStore.fetchUsers()
    }, [])

    const onNotesChange = event => {
      const textValue = event.target.value
      caseStore.case.set(fieldNames.NOTES, textValue)
    }

    const onCheckboxesChange = value => {
      const notifyState = caseStore.case.get(fieldNames.NOTIFY)
      notifyState[value] = !notifyState[value]
      caseStore.case.set(fieldNames.NOTIFY, notifyState)
    }

    const getNotifyCheckboxes = () => {
      return Object.keys(notifyOptions).map(option => (
        <FormControlLabel
          key={option}
          control={
            <Checkbox
              className={classes.Checkbox}
              color="primary"
              checked={caseStore.case.get(fieldNames.NOTIFY)[option]}
              onChange={() => onCheckboxesChange(option)}
            />
          }
          label={notifyOptions[option]}
        />
      ))
    }

    const editButtonProps = !isOnWizard ? { nextText: 'Submit', backText: 'Cancel' } : {}

    return (
      <div className={classes.statsWizard}>
        <form noValidate autoComplete="off">
          <Grid container spacing={4}>
            <Grid item md={7} sm={12}>
              <div className="flex-column flex-space-between h-100">
                {getOwner()}
                <ReactSelect
                  formlabel="Select Owner"
                  name='owner'
                  options={generalStore.usersSelectOptions}
                  onChange={onOwnerChange}
                  value={owner}
                  rerender_dummay={generalStore.loaded.users}
                  formik={formikProps}
                  required
                />
              </div>
            </Grid>
            <Grid item md={5} sm={12}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Notify</FormLabel>
                <FormGroup>{getNotifyCheckboxes()}</FormGroup>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <Typography variant="subtitle1" gutterBottom>
                Urgency
              </Typography>
              <UrgencySlider
                onChange={value => formikProps.values.urgency = value }
                initialValue={formikProps.values.urgency}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                name='notes'
                formlabel="Notes"
                multiline
                rows="3"
                rowsMax="3"
                placeholder=""
                fullWidth
                formik={formikProps}
              />
            </Grid>
            <Grid item xs={12}>
              <Box mt={1}>
                <WizardButtons
                  onCancel={onCancel}
                  isOnWizard={isOnWizard}
                  backStep={backStep}
                  nextStep={onNext}
                  {...editButtonProps} 
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
    )
  })
)

export default StatusAndNotifications
