import api from '../api'
import WizardStore from './wizardStore'
import CaseStore from './caseStore'
import GeneralStore from './generalStore'
import ActivityStore from "./activityStore";
import DialogStore from "./dialogStore";
import { RouterStore, startRouter } from 'mobx-router'

const store = {
  wizardStore: new WizardStore(api),
  caseStore: new CaseStore(api),
  activityStore: new ActivityStore(api),
  generalStore: new GeneralStore(api),
  dialogStore: new DialogStore(),

  router: new RouterStore()
}

export default store
