import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { makeStyles, Box } from '@material-ui/core'
import EditableTable from './EditableTable.jsx'
import CurrencySelector from './CurrencySelector.jsx'
import WizardButtons from '../../../layout/boxes/wizard/WizardButtons.jsx'
import { fieldNames } from "constants.js"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: -25,
  },
  editTable: {
    width: 600,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  totalWrapper: {
    width: 257,
    fontWeight: 'bold',
  },
  totalLabel: {
    width: 257,
    color: '#000',
  },
  totalValue: {
    color: '#000',
  },
}))

let incurredChargesUpdatedData

const IncurredCharges = inject('caseStore')(
  observer(({ caseStore, backStep, nextStep, isOnWizard, onCancel }) => {
    const classes = useStyles()
    const [errors, setErrors] = React.useState(null)

    const convertToPlainObject = charges => {
      console.log('charges', charges)
      return charges.map(({ value, description }) => ({ value, description }))
    }

    const getTotal = incurredCharges => {
      return incurredCharges.reduce(
        (sum, single) => sum + (parseInt(single['value']) || 0),
        0
      )
    }

    const initData = convertToPlainObject(caseStore.case.get(fieldNames.INCURRED_CHARGES))
    const [total, setTotal] = React.useState(getTotal(initData))

    const onCurrencySelected = newValue => {
      caseStore.merge({ currency: newValue })
    }

    const onDataChanged = data => {
      const incurredCharges = convertToPlainObject(data)
      caseStore.merge({ incurredCharges })
      const total = getTotal(data)
      setTotal(total)
    }

    const onErrors = errors => {
      setErrors(errors)
    }

    return (
      <div className={classes.root}>
        <form className={classes.container} noValidate autoComplete="off">
          <Box>
            <CurrencySelector onSelected={onCurrencySelected} />
          </Box>
          {errors && <Box color="red">{errors}</Box>}
          <Box mt={3}>
            <EditableTable
              initData={initData}
              className={classes.editTable}
              onDataChanged={onDataChanged}
              onErrors={onErrors}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Box width="50%">
              <span className={classes.totalLabel}>Total</span>
              <hr />
              <Box
                display="flex"
                justifyContent="flex-end"
                className={classes.totalValue}
              >
                {total} {caseStore.case.get(fieldNames.CURRENCY)}
              </Box>
            </Box>
          </Box>
          <div className={classes.totalWrapper}></div>
          <Box mt={12}>
            <WizardButtons
              onCancel={onCancel}
              isOnWizard={isOnWizard}
              backStep={backStep}
              nextStep={nextStep}
            />
          </Box>
        </form>
      </div>
    )
  })
)

IncurredCharges.propTypes = {}

export default IncurredCharges
