import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  input: {
    display: 'none',
  },
  button: {
    margin: theme.spacing(1),
    color: '#443699',
    backgroundColor: '#F1F1F1',
  },
  '.muiButton': {
   float:'right',
  },
  headerButtons: {
    paddingTop: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .btnSet': {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    '& .ellipse-icon svg': {
      fill:'#443699',
    },
    '& .list-icon svg': { 
      fill:'#1C0F66',
    },
    '& .map-icon svg':{
      fill: '#1C0F66',
    },
    '& .map-icon.active svg':{
      fill: '#DADADA',
    },
    '& .resultMessage': {
      color:'#646470',
      paddingLeft: 21,
      margin: 0,
      fontSize:14,
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
  },
}));
