import React from 'react';
import useStyles from './GuidelinesStyle.jsx';
import GridContainer from '../../../../layout/GridContainer.jsx'
import GridItem from '../../../../layout/GridItem.jsx'
import RecoveryCourse from './RecoveryCourse.jsx'
import ExtendedStay from './ExtendedStay.jsx'

const Guidelines = () => {
  const classes = useStyles();
  return(
  <React.Fragment>
    <div className={classes.guidelineContainer}>
      <GridContainer spacing={0}>
        <GridItem xs={12}>
          <h4 className={classes.guidelineheading}>
            <span className={classes.DropdownIcon}>
            </span>
            Guidelines
          </h4>
        </GridItem>
      </GridContainer> 
    </div>
    <RecoveryCourse />
    <ExtendedStay />
   </React.Fragment>
  );
};
  
export default Guidelines;
