import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  title: {
    color:'#232735',
    '& h3': {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 24,
      paddingTop: 0,
      color: '#443699',
      marginBottom: 1,
    }
  },
  filterBy: {
    minWidth: 165,
    paddingTop: 25,
    paddingRight: 20,
    marginBottom: -7,
    '& h3': {
      color: '#000000',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 18,
      paddingLeft: 30,
    },
  },
  filterIcon: {
    padding: 10,
  },
  filterFormFields1: {
    display: 'inline-block',
  },
  formSelectControl: {
    width: '260px',
    paddingTop: 10,
    paddingRight: '20px',
    '& select': {
      border: '1px solid #B9B8BF', 
      padding:12,
    },
  },
  filterForm: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '& .makeStyles-root-177':{
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiOutlinedInput-root':{
      backgroundColor: theme.palette.text.alternate,
    },
  },
  searchField : {
    float: 'right',
    width: 240,
    marginTop: 28,
    '@media only screen and (min-width: 855px) and (max-width: 1102px)': {
      marginTop: 108,
    },
    '@media only screen and (max-width: 854px)': {
      marginTop: 190,
    },
    '@media only screen and (max-width: 600px)': {
      marginTop: 22,
      float: 'left',
    }
  },
}));
