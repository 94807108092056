import React from 'react'
import clsx from 'clsx'

function ApproveIcon({ className, enabled }) {
  return (
    <span className={clsx('icon-approve', className)}>
      <svg
        width="18"
        height="21"
        viewBox="0 0 18 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.59091 0C7.48281 0 6.44146 0.346476 5.72338 0.954545H4.77273C3.71837 0.954545 2.86364 1.80927 2.86364 2.86364H1.90909C0.854729 2.86364 0 3.71837 0 4.77273V19.0909C0 20.1453 0.854729 21 1.90909 21H15.2727C16.3271 21 17.1818 20.1453 17.1818 19.0909V4.77273C17.1818 3.71837 16.3271 2.86364 15.2727 2.86364H14.3182C14.3182 1.80927 13.4635 0.954545 12.4091 0.954545H11.4584C10.7404 0.346476 9.699 0 8.59091 0ZM14.0628 4.77273C13.7327 5.34336 13.1157 5.72727 12.4091 5.72727H4.77273C4.06609 5.72727 3.44913 5.34336 3.11904 4.77273H1.90909V19.0909H15.2727V4.77273H14.0628ZM7.63637 12.9683L11.7341 8.87049L13.0841 10.2204L7.63637 15.6681L4.09776 12.1295L5.4477 10.7796L7.63637 12.9683Z"
          fill={enabled ? '#443699' : '#B9B8BF'}
        />
      </svg>
    </span>
  )
}

export default ApproveIcon
