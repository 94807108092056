import React from 'react';
import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  container: {
    padding: '10px 36px 36px 10px',
  },
  paa: {
    '@media (min-width: 1280px)': {
      marginRight: 37,
    }
  },
  pap: {
    marginBottom: 38,
    width: '100%',
  },
  teamAndActivity: {
    height: 406,
  },

}));

