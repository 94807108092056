const OPEN = 'open'
const CLOSE = 'close'
const NOTIFICATION = 'notification'
const ACTIVITY = 'activity'
const AUTH_FAILED = 'auth-failed'
const eventsTypes = [OPEN, CLOSE, NOTIFICATION, ACTIVITY, AUTH_FAILED]

export default class Client {
  constructor (url, jwtToken, _WebSocket) {
    // use both on browser and client
    const WSocket = (typeof WebSocket !== 'undefined') ? WebSocket : _WebSocket
    this.ws = new WSocket(url, jwtToken)
    window.tws = this.ws
    this.ws.onopen = this.onOpen.bind(this)
    this.ws.onclose = this.onClose.bind(this)
    this._send = this._send.bind(this)
    this.events = {}
  }

  on (type, fn) {
    if (!eventsTypes.includes(type)) {
      throw new Error(`subscription of type ${type} is unknown`)
    }

    if (typeof fn !== 'function') {
      throw new Error('subscription need Function type')
    }

    this.events[type] = fn
  }

  _triggerEvent (type, payload) {
    if (this.events[type]) {
      this.events[type](payload)
    }
  }

  onOpen () {
    this.isOpen = true
    this.ws.onmessage = this.onMessage.bind(this)
    this._triggerEvent('open')
  }

  onMessage (message) {
    console.log('message', message)
    const messageObject = JSON.parse(message.data)
    console.log('messageObject', messageObject)
    this._triggerEvent(messageObject.type, messageObject.message)
  }

  onClose (message) {
    console.log('connection closed')
    this._triggerEvent('close')
  }

  sendChatMessage (caseId, message) {
    this._send('chat', { caseId, message })
  }

  sendActivity (activityMessage) {
    this._send('activity', activityMessage)
  }

  _send (type, messageObject) {
    const objectToSend = Object.assign({ type }, messageObject)
    this.ws.send(JSON.stringify(objectToSend))
  }

  close () {
    this.ws.close()
  }
}
