import React, { Fragment } from 'react'

function GraphCell({ los, totalLos }) {
  const width = (los / totalLos) * 100

  return (
    <Fragment>
      <div className="los clearfix">
        <div className="los-inner">
          <span className="current" style={{ width: `${width}%` }}>
            {los}
          </span>
          <span className="total">{totalLos}</span>
        </div>
      </div>
    </Fragment>
  )
}

export default GraphCell
