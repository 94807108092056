import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  table: {
    boxShadow:'none',
    '& .clearfix': {  
      display: 'table',
      width: '100%',
    },
    '& table':{
      fontSize:10,
    },
    '& .custom-icon': {
      display: 'inline-block',
      width: '20px',
      height: '20px',
    },
    backgroundColor: '#FFFFFF',
    '& tbody td': {
      color: '#1B153D',
    },
    '& tbody tr:hover td': {
      color: '#000',
      cursor: 'pointer',
      textShadow: '0 0 1px #888',
    },
    '& thead th': {
      color: 'white',
      fontSize: '14px',
      borderBottom: 'none',
    },
    '& td': {
      borderBottomColor: '#B7B7B7',
    },
    '& .los-inner': {
      width: '100%',
      float: 'left',
      backgroundColor: '#C4C4C4',
      borderRadius: 4,
      padding: '0',
      position: 'relative',
      color: '#fff',
      '& .current': {
        textAlign: 'center',
        padding: '5px',
        display: 'block',
        borderRadius: 4,
        backgroundColor: '#8783A3'
      },
      '& .total': {
        float: 'right',
        padding: '5px',
        position: 'absolute',
        right: 0,
        top: 0,
      },
    },
    '& .los': {
      clear: 'both'
    },
    '& .MuiTableCell-head .line': {
      backgroundColor: 'white',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '1px',
      height: '200%',
    },
    '& .MuiTableCell-head': {
      backgroundColor: '#1C0F66',
      margin: '0 1px',
      padding: '8px 25px 8px 10px',
      position: 'relative',
      '& .sortIcons': {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        justifyContent: 'center',
        '& .iconArrow': {
          height: '13px',
          alignItems: 'center',
          display: 'flex',
        },
      },
      '& .sortIcons svg': {
        fill: '#443699',
      },
    },
    '& .MuiTableCell-body': {
          position:'relative',
          padding:5,
          paddingLeft: 4,
      },
     '& .MuiFormControlLabel-root':{
      margin: 0,
     },
    '& .hospitalRelations': {
      width: '5px',
      height: '20px',
      display: 'block',
      marginLeft: 14,
    },
    '& .hospitalRelations.blacklisted': {
      backgroundColor: '#C87575',
    },
    '& .hospitalRelations.neutral':{
      backgroundColor: '#FFFFFF',
      border:'1px solid #443699',
    },
    '& .hospitalRelations.preferred':{
      backgroundColor: '#17C548',
    },
    '& .icon-star': {
        display: 'flex',
        top: 0,
        bottom:0,
        alignItems:'center',
    },
    '& .icon-star svg': {
      fill:'#DADADA',
    },
    '& tr:hover .icon-star svg': {
      fill:'#443699',
    },
    '& .icon-checkBox': {
      fill: '#443699',

    },
    '& .icon-star.active svg': {
      fill: '#443699',
    },
    '& .starIconWrap .icon-star.icon-star-filled': {
      display: 'none',
    },
    '& .starIconWrap.active .icon-star': {
      display: 'none',
    },
    '& .starIconWrap.active .icon-star.icon-star-filled': {
      display: 'flex',
      fill:'red',
    },
    '@media only screen and (max-width: 900px)': {
      '& .medCellHide': {
        display: 'none',
      }
    }
  },
}))

