import React from 'react';
import clsx from 'clsx'

function CrossIcon({ className }) {

  return (
    <span className={clsx('icon-CrossIcon', className)}>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.70711 7.12132L1.41421 11.4142L0 10L4.29289 5.70711L0 1.41421L1.41421 0L5.70711 4.29289L10 0L11.4142 1.41421L7.12132 5.70711L11.4142 10L10 11.4142L5.70711 7.12132Z" fill="#B9B8BF"/>
      </svg>
    </span>
  )

}

export default CrossIcon;


