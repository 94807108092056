import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
  },
  commentContainer: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: 260,
    marginBottom: 10,
  },
  inputContainer: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 5,
    margin: 10,
    padding: '6px 10px',
    alignItems: 'center',
  },
  comment: {
    marginBottom: 10,
    '& .MuiGrid-grid-xs-9': {
      maxWidth: '50%',
    },
  },
  ButtonWrap: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default useStyles
