import React from 'react'
import { Formik } from 'formik'
import { observer, inject } from 'mobx-react'
import * as Yup from 'yup'
import StatusAndNotifications from './StatusAndNotifications.jsx'

const validationSchema = Yup.object({
  owner: Yup.string('Choose Owner').required('Owner is required'),
})

const StatusAndNotificationsContainer = inject('caseStore')(
  observer(({ caseStore, isOnWizard, backStep, nextStep, onCancel }) => {
    const handleSubmit = async values => {
      caseStore.merge(values)
      if (isOnWizard) {
        nextStep()
      } else {
        const res = await caseStore.save()
        nextStep()
      }
    }

    const compProps = {
      handleSubmit,
      isOnWizard,
      onCancel,
      backStep,
      nextStep,
    }

    return (
      <Formik
        initialValues={caseStore.case.toJSON()}
        onSubmit={handleSubmit}
        render={props => <StatusAndNotifications formikProps={props} {...compProps} />}
        validationSchema={validationSchema}
      />
    )
  })
)

export default StatusAndNotificationsContainer
