import SocketManager from 'lib/socket/socket-manager'
import { startRouter } from 'mobx-router'
import routes from 'app/views/ViewsRoutes'

function AppSetup (store) {
  const app = window.app = {}

  const _initApp = () => {
    const dataObj = JSON.parse(localStorage.getItem('userDataAndToken'))
    if (!dataObj) {
      return
    }
    app.isLoggedIn = true
    _setLocalStorageDataGlobally(dataObj)

    store.activityStore.fetchUserNotifications()
    // when login to a different user
    if (app.socketManager) {
      app.socketManager.close()
    }
    app.socketManager = new SocketManager(store)
  }

  const _setLocalStorageDataGlobally = (data) => {
    const userData = { name: data.name, avatar: data.avatar, userId: data.userId }
    app.jwtToken = data.jwtToken
    app.userData = userData
  }

  _initApp()
  app.initApp = _initApp
  startRouter(routes, store)

}

export default AppSetup

