import React, {Fragment}  from 'react'
import { observer, inject } from 'mobx-react'
import { Paper } from '@material-ui/core'
import clsx from 'clsx'
import { goto } from '../ViewsRoutes'
import ActionsButtons from './actions-buttons/ActionsButtons.jsx'
import CaseLoadStyle from './CaseLoadStyle.jsx'
import CaseLoadHeader from './header/CaseLoadHeader.jsx'
import CasePreview from './preview/CasePreview'
import CaseLoadMap from './map/CaseLoadMap'
import CaseLoadTable from './table/CaseLoadTable'
import { formatStringDate } from '../../../helpers/dates.js'

const CaseLoad = inject('store', 'caseStore')
(observer(({
  store,
  caseStore
}) => {

  const [showMap, setShowMap]= React.useState(false)
  const classes = CaseLoadStyle()
  const [previewProps, setPreviewProps] = React.useState(null)
  const [enableEditButtons, setEnableEditButtons] = React.useState(false)

  function gotoCase(id) {
    goto.case(id)
  }

  function getCasePreview() {
    if (previewProps) {
      return (
        <CasePreview
          previewProps={previewProps}
          setPreviewProps={setPreviewProps}
          gotoCase={gotoCase}
        />
      )
    }
  }

  function onCheckBoxChanged(selectedRows) {
    setEnableEditButtons(!!Object.keys(selectedRows).length)
  }

  return (
    <Fragment>
      <div className={classes.container}>
        <CaseLoadHeader />
        <div className={classes.caseLoadData}>
          <ActionsButtons 
            enableEditButtons={enableEditButtons} 
            showMap={showMap} 
            setShowMap={setShowMap}
          />
          <Paper className={classes.tblWrap}>
            <div className={clsx(classes.caseLoadWrap)}>
              {getCasePreview()}
              {showMap ?
                  <CaseLoadMap /> :
                  <CaseLoadTable
                    isCaseListLoaded={caseStore.isCaseListLoaded}
                    caseList={caseStore.caseList}
                    setPreviewProps={setPreviewProps}
                    gotoCase={gotoCase}
                    onCheckBoxChanged={onCheckBoxChanged}
                  />
              }
            </div>
          </Paper>
        </div>
      </div>
    </Fragment>
  )
}))

export default CaseLoad
