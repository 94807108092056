import { observable, action, computed } from "mobx";
import { wizardStates } from "../types";

class WizardStore {
  @observable currentStateNumber = 0;

  @observable openWizardCancellationDialog = false

  @computed get currentState() {
    return wizardStates[this.currentStateNumber];
  }

  @action reset() {
    this.currentStateNumber = 0;
  }

  @action setState(state) {
    this.currentStateNumber = wizardStates.findIndex(elem => elem === state);
  }

  @action backState() {
    this.currentStateNumber--;
  }

  @action nextState() {
    this.currentStateNumber++;
  }

  @action showWizardCancellationDialog() {
    this.openWizardCancellationDialog = true
  }

  @action hideWizardCancellationDialog() {
    this.openWizardCancellationDialog = false
  }
}

export default WizardStore;
