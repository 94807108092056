export function numbersPrettier (number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function moneyDispaly (amount) {
  if (amount > 1000) {
    return Math.round(amount / 1000) + 'k'
  } else {
    return amount
  }
}
