import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Select, { createFilter } from 'react-select'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NoSsr from '@material-ui/core/NoSsr'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import { FormControl, FormLabel } from '@material-ui/core'
import { FixedSizeList as List } from 'react-window'

const useStyles = makeStyles(theme => ({
  root: {},
  input: {
    display: 'flex',
    padding: 0,
    height: 'inherit',
    boxSizing: 'border-box',
    borderRadius: '4px',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    color: '#232735',
    margin: '12px',
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
    color: '#232735',
  },
  singleValue: {
    fontSize: 16,
    color: '#232735',
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    padding: '12.5px 10px 12.5px 10px',
    fontSize: '16px',
    fontWeight: '100',
    lineHeight: '18px',
    color: '#657786',
    opacity: 0.6,
  },
  paper: {
    zIndex: 1,
    marginTop: theme.spacing(1),
  },
  divider: {
    height: theme.spacing(2),
  },
  formControl: {
    minWidth: '100%',
  },
  label: {
    marginBottom: '5px',
    color: '#232735',
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: props => (props.eventFormLabel ? '500' : 'normal'),
    fontSize: props => (props.eventFormLabel ? '18px' : '14px'),
    lineHeight: props => (props.eventFormLabel ? '23px' : '16px'),
    display: 'flex',
    alignItems: 'center',
    textTransform: props => (props.eventFormLabel ? 'capitalize' : 'uppercase'),
  },
}))

const NoOptionsMessage = props => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
}

const inputComponent = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
}

const Control = props => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props

  return (
    <FormControl className={props.selectProps.classes.formControl}>
      <FormLabel className={props.selectProps.classes.label}>
        {TextFieldProps.formlabel}
      </FormLabel>
      <TextField
        variant="outlined"
        InputProps={{
          inputComponent,
          inputProps: {
            className: classes.input,
            ref: innerRef,
            children,
            ...innerProps,
          },
        }}
        {...TextFieldProps}
      />
    </FormControl>
  )
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired,
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  selectProps: PropTypes.object.isRequired,
}

const Option = props => {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        color: '#232735',
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

Option.propTypes = {
  children: PropTypes.node,
  // innerProps: PropTypes.shape({
  // id: PropTypes.string.isRequired,
  // key: PropTypes.string.isRequired,
  // onClick: PropTypes.func.isRequired,
  // onMouseMove: PropTypes.func.isRequired,
  // onMouseOver: PropTypes.func.isRequired,
  // tabIndex: PropTypes.number.isRequired,
  // }).isRequired,
  // innerRef: PropTypes.oneOfType([
  // PropTypes.oneOf([null]),
  // PropTypes.func,
  // PropTypes.shape({
  // current: PropTypes.any.isRequired,
  // }),
  // ]).isRequired,
  // isFocused: PropTypes.bool.isRequired,
  // isSelected: PropTypes.bool.isRequired,
}

const Placeholder = props => {
  const { selectProps, innerProps = {}, children } = props
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  )
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
}

const SingleValue = props => {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

SingleValue.propTypes = {
  children: PropTypes.node,
  // innerProps: PropTypes.any.isRequired,
  selectProps: PropTypes.object.isRequired,
}

const ValueContainer = props => {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  )
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
}

const Menu = props => {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  )
}

Menu.propTypes = {
  children: PropTypes.element.isRequired,
  selectProps: PropTypes.object.isRequired,
}

class MenuList extends React.Component {
  render() {
    const height = 35
    const { options, children, maxHeight, getValue } = this.props
    const [value] = getValue()
    const initialOffset = options.indexOf(value) * height

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    )
  }
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  IndicatorSeparator: () => null,
}

const IntegrationReactSelect = ({
  name,
  formlabel,
  formik,
  required,
  options,
  onInputChange,
  placeholder,
  largeList,
  ...props
}) => {
  const [single, setSingle] = React.useState(null)

  if (largeList) {
    Object.assign(components, { MenuList })
  }

  const validationProps = () => {
    if (required && formik) {
      return {
        helperText: formik.touched[name] ? formik.errors[name] : '',
        error: formik.touched[name] && Boolean(formik.errors[name]),
        touched: formik.touched[name] ? 'true' : 'false',
      }
    }
  }

  const classes = useStyles(props)
  const theme = useTheme()

  const handleChangeSingle = value => {
    setSingle(value)
  }

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
  }
  return (
    <div className={classes.root}>
      <NoSsr>
        <Select
          // remove typing lag
          // https://blog.johnnyreilly.com/2019/04/react-select-with-less-typing-lag.html
          filterOption={createFilter({ ignoreAccents: false })}
          menuPortalTarget={document.body}
          classes={classes}
          styles={selectStyles}
          inputId="react-select-single"
          TextFieldProps={{
            formlabel,
            inputProps: {
              htmlFor: 'react-select-single',
              shrink: true,
            },
            ...validationProps(),
          }}
          placeholder={placeholder}
          components={components}
          value={single}
          onChange={handleChangeSingle}
          options={options}
          onInputChange={onInputChange}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              text: '#232735',
            },
          })}
          {...props}
        />
      </NoSsr>
    </div>
  )
}

IntegrationReactSelect.propTypes = {
  formlabel: PropTypes.string.isRequired,
  options: PropTypes.array,
  onInputChange: PropTypes.func,
}
export default IntegrationReactSelect
