import React, { useEffect } from 'react'
import { inject } from 'mobx-react'
import { Box, makeStyles } from '@material-ui/core'
import GridContainer from '../../../layout/GridContainer.jsx'
import GridItem from '../../../layout/GridItem.jsx'
import WizardButtons from '../../../layout/boxes/wizard/WizardButtons.jsx'
import InfoText from '../../../components/InfoText.jsx'
import { formatStringDate } from '../../../../helpers/dates'

const useStyles = makeStyles(theme => ({
  root: {},
  bottom: {
    marginBottom: '50px',
  },
}))

const SummaryAndConfirmation = inject(
  'caseStore',
  'generalStore',
)(
  ({
    caseStore,
    generalStore,
    backStep,
    submitNewCase,
    isOnWizard,
    onCancel,
  }) => {
    const classes = useStyles()
    const caseData = caseStore.case.toJSON()
    let hospitalLabel = ''
    let conditionLabel = ''
    useEffect(() => {
      generalStore.fetchHospitals()
      generalStore.fetchConditions()
    })
    return (
      <form noValidate autoComplete="off">
        <GridContainer spacing={4}>
          <GridItem sm={4}>
            <InfoText label="POLICY">
              {caseData.policyNumber} | {caseData.policyStatus}
            </InfoText>
          </GridItem>
          <GridItem sm={4}>
            <InfoText label="CASE">
              {caseData.caseNumber} | {caseData.coverage}
            </InfoText>
          </GridItem>
          <GridItem xs={4}>
            <InfoText label="URGENCY">{caseData.urgency}</InfoText>
          </GridItem>
          <GridItem sm={4}>
            <InfoText label="PATIENT NAME">{caseData.patientName}</InfoText>
          </GridItem>
          <GridItem sm={8}>
            <InfoText label="INCURRED CHARGES">
              {caseData.totalIncurredCharges}
            </InfoText>
          </GridItem>
          <GridItem xs={12}>
            <InfoText label="CONDITION">{conditionLabel}</InfoText>
          </GridItem>
          <GridItem xs={3}>
            <InfoText label="DOA">
              {formatStringDate(caseData.dateOfAdmission)}
            </InfoText>
          </GridItem>
          <GridItem xs={6}>
            <InfoText label="HOSPITAL">{hospitalLabel}</InfoText>
          </GridItem>

          {caseData.notes && (
            <GridItem xs={12}>
              <InfoText label="NOTES">{caseData.notes}</InfoText>
            </GridItem>
          )}

          <GridItem xs={12}>
            <Box mt={1}>
              <WizardButtons
                backStep={backStep}
                nextStep={submitNewCase}
                nextText="Submit"
              />
            </Box>
          </GridItem>
        </GridContainer>
      </form>
    )
  },
)

export default SummaryAndConfirmation
