import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import Grid from "@material-ui/core/Grid"
import clsx from 'clsx'

const style = {
  grid: {}
}

const GridContainer = React.forwardRef((props, ref) => {
  const { classes, className, children, ...rest } = props
  const gridContainerClasses = clsx(classes.grid, className)

  return (
    <Grid container ref={ref} {...rest} className={gridContainerClasses}>
      {children}
    </Grid>
  )
})

export default withStyles(style)(GridContainer)

