import client from '../client'
import { SAVE_COMMENT_QUERY, GET_COMMENTS_QUERY } from './queries'

const saveComment = async (caseId, jsonObject) => {
    const res = await client.mutate({
        mutation: SAVE_COMMENT_QUERY,
        variables: { caseId, comment: jsonObject }
    })

    return res.data.saveComment
}

export default {
    saveComment,
}