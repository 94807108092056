import React from 'react';
import clsx from 'clsx'

function KeyIcon({ className }) {

  return (
    <span className={clsx('icon-Key', className)}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.8049 5.67689C17.8049 4.9105 17.6542 4.16779 17.3593 3.46598C17.073 2.79001 16.6639 2.18292 16.143 1.66195C15.622 1.14097 15.0149 
        0.731946 14.3389 0.445626C13.6393 0.148542 12.8944 0 12.128 0C11.3616 0 10.6189 0.150695 9.91711 0.445626C9.24116 0.731946 8.63405 1.14097 
        8.11308 1.66195C7.5921 2.18292 7.18307 2.79001 6.89675 3.46598C6.59967 4.16564 6.45113 4.9105 6.45113 5.67689C6.45113 6.63488 6.69224 7.57349 
        7.15078 8.40877L0.354447 15.1986C0.197294 15.3558 0.14778 15.5948 0.244655 15.8208C0.26403 15.8682 0.296322 15.9112 0.332919 15.9478L1.85494
        17.4698C1.89153 17.5064 1.93459 17.5366 1.98195 17.5581C2.20799 17.655 2.44695 17.6076 2.6041 17.4483L3.54487 16.5075L4.89897 17.8616C4.93557
        17.8982 4.97862 17.9284 5.02596 17.9499C5.25201 18.0468 5.49099 17.9994 5.64812 17.8401L7.14861 16.3396C7.1852 16.303 7.21534 16.26 7.23687 
        16.2126C7.33375 15.9866 7.28639 15.7476 7.12708 15.5905L5.79451 14.2579L9.39396 10.6563C10.2271 11.1148 11.1679 11.3559 12.1258 11.3559C12.8922 
        11.3559 13.6349 11.2052 14.3367 10.9103C15.0127 10.624 15.6198 10.215 16.1408 9.69398C16.6617 9.17303 17.0708 8.56595 17.3571 7.88995C17.6542
        7.18599 17.8049 6.44328 17.8049 5.67689ZM14.1172 7.66821C13.5855 8.19995 12.8772 8.49273 12.1259 8.49273C11.3746 8.49273 10.6663 8.19995
        10.1346 7.66821C9.60282 7.13648 9.30789 6.42821 9.30789 5.67689C9.30789 4.92342 9.60067 4.2173 10.1324 3.68556C10.6641 3.15383 11.3724
        2.86105 12.1237 2.86105C12.8751 2.86105 13.5833 3.15383 14.1151 3.68556C14.6468 4.2173 14.9396 4.92557 14.9396 5.67689C14.9439 6.42821 
        14.6511 7.13648 14.1172 7.66821Z" fill="white"/>
      </svg>
    </span>
  )

}

export default KeyIcon;


