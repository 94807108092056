import client from '../client'
import {
  GET_LIST_QUERY,
  GET_CASE_QUERY,
  SAVE_CASE,
  ADD_STRATEGY,
  SAVE_EVENT,
  DELETE_EVENT,
  LOCK_STRATEGY,
  TOGGLE_LIKE,
  DELETE_STRATEGY
} from './queries'

const getCaseList = async () => {
  const icase = await client.query({
    query: GET_LIST_QUERY
  })

  return icase.data.icases
}

const getCase = async id => {
  const icase = await client.query({
    query: GET_CASE_QUERY,
    variables: { id: id }
  })

  return icase.data.icase
}

const saveCase = async jsonObject => {
  /// QUICK FIX REMOVING fields HERE as not sure where theyre put in and trying to test update
  delete jsonObject.owner
  /// /////////////////////////////////////////////////////////////////////
  const icase = await client.mutate({
    mutation: SAVE_CASE,
    variables: { icase: jsonObject }
  })

  return icase.data.saveIcase
}

const save = async jsonObject => {
  const res = await saveCase(jsonObject)
  return res
}

const addStrategy = async (caseId, strategyType) => {
  const icase = await client.mutate({
    mutation: ADD_STRATEGY,
    variables: { caseId, strategyType }
  })

  return icase.data.addStrategy
}

const saveEvent = async (caseId, event) => {
  const icase = await client.mutate({
    mutation: SAVE_EVENT,
    variables: { caseId, event }
  })

  return icase.data.saveEvent
}

const deleteEvent = async (caseId, eventId) => {
  const icase = await client.mutate({
    mutation: DELETE_EVENT,
    variables: { caseId, eventId }
  })

  return icase.data.deleteEvent
}

const lockStrategy = async (caseId, strategyId) => {
  const icase = await client.mutate({
    mutation: LOCK_STRATEGY,
    variables: { caseId, strategyId }
  })

  return icase.data.lockStrategy
}

const deleteStrategy = async (caseId, strategyId) => {
  const icase = await client.mutate({
    mutation: DELETE_STRATEGY,
    variables: { caseId, strategyId }
  })

  return icase.data.deleteStrategy
}

const toggleLike = async (caseId, strategyId, likeType) => {
  const icase = await client.mutate({
    mutation: TOGGLE_LIKE,
    variables: { caseId, strategyId, likeType }
  })

  return icase.data.toggleLike
}

export default {
  save,
  saveCase,
  getCase,
  getCaseList,
  addStrategy,
  saveEvent,
  deleteEvent,
  lockStrategy,
  toggleLike,
  deleteStrategy
}
