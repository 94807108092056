import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


export default function CheckBox() {

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox color="primary" />
        }
      />
    </FormGroup>
  );
}
