import React from 'react';
import clsx from 'clsx'

function DeleteIcon({ className }) {

  return (
    <span className={clsx('icon-DeleteIcon', className)}>
      <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.0615 2.39617H10.9483C10.793 1.04952 9.64728 0 8.25963 0C6.87203 0 5.72645 1.04948 5.57115 2.39617H2.45783C1.19795 2.39617 0.173096 3.42131 0.173096 4.68114V4.79839C0.173096 5.76114 0.772562 6.58518 1.61704 6.92094V17.715C1.61704 18.9749 2.64202 20 3.90181 20H12.6175C13.8774 20 14.9023 18.9748 14.9023 17.715V6.92098C15.7467 6.58518 16.3462 5.76114 16.3462 4.79843V4.68119C16.3462 3.42131 15.3213 2.39617 14.0615 2.39617ZM8.25963 1.08364C9.04885 1.08364 9.7076 1.64932 9.85336 2.39617H6.6662C6.81192 1.64928 7.47071 1.08364 8.25963 1.08364ZM13.8186 17.715C13.8186 18.3774 13.2797 18.9164 12.6175 18.9164H3.90177C3.23958 18.9164 2.70064 18.3774 2.70064 17.715V7.08336H13.8186V17.715ZM15.2626 4.79839C15.2626 5.46078 14.7236 5.99976 14.0614 5.99976H2.45783C1.79563 5.99976 1.2567 5.46078 1.2567 4.79839V4.68114C1.2567 4.01875 1.79563 3.47977 2.45783 3.47977H14.0615C14.7237 3.47977 15.2626 4.01875 15.2626 4.68114V4.79839H15.2626Z" fill="#C4C4C4"/>
      </svg>

    </span>
  )

}

export default DeleteIcon;


