import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  TableHeaing: {
    margin: '35px 0px 40px 25px',
  },
  container: {
    backgroundColor: '#FFFFFF',
    margin: '0 20px 0 20px',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.12)',
    borderRadius: 5,
    '& .MuiTableCell-root': {
      padding: 0,
      margin: 0,
    },
    '& .MuiFormControlLabel-root': {
      margin: 0,
    },
    '& .PrivateSwitchBase-root-220': {
      padding: 0,
    },
    '& .notificationsMessage': {
      color: theme.palette.text.disabled,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      float: 'left',
      margin: '10px 0px 0px 25px',
    },
    '& .MarkReadHeading': {
      float: 'right',
      margin: '0px 40px 0px 0px',
    },
    '& .icon-DeleteIcon': {
      marginLeft: 30,
      cursor: 'pointer',
    },
    '& .Table': {
      '& .col1 .MuiFormGroup-row': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& .MuiTableRow-head': {
        backgroundColor: '#1C0F66',
        '& .MuiTableCell-head': {
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: 14,
          color: theme.palette.text.alternate,
          borderLeft: '2px solid white',
          paddingLeft: 10,
          '& .MuiCheckbox-colorPrimary.Mui-checked svg': {
            fill: 'white'
          }
        },
      },
      '& .MuiTableCell-body': {
        paddingLeft: 10,
        cursor: 'pointer',
        '& .icon-Addressbook': {
          backgroundColor: '#68C1B4',
          width: 30,
          height: 30,
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '100%',
          marginRight: 12, 
        },
        '& .icon-Key': {
          backgroundColor: '#443699',
          width: 30,
          height: 30,
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '100%',
          marginRight: 10,
        },
        '& .icon-Lock': {
          backgroundColor: '#17C548',
          width: 30,
          height: 30,
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '100%',
          marginRight: 10,
        },
        '& .icon-Closed': {
          backgroundColor: '#ffff',
          border: '2px solid #443699',
          width: 30,
          height: 30,
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '100%',
          marginRight: 10,
        },
      },
      '& tbody tr': {
        transition: '0.1s all ease-in-out',
      },
      '& tbody tr td': {
        padding: '10px',
      },
      '& tbody tr:hover': {
        boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.25)',
        transform: 'scale(1.001)',
        boxSizing: 'border-box',
      },
      '& tbody tr .icon-CrossIcon': {
        paddingRight: 10,
        visibility: 'hidden',
        float: 'right',

      },
      '& tbody tr:hover .icon-CrossIcon': {
        visibility: 'visible',
      },
      '& tbody tr:hover td': {
        color: '#000',
        cursor: 'pointer',
        textShadow: '0 0 1px #888',
      },
      '& .MuitblCell': {
        color: theme.palette.text.black,
      },
      '& .MuitblCellData': {
        color: '#828282',
      },
      '& .notRead': {
        backgroundColor: '#FFFAF0',
      },
      '& .MuiIconButton-colorPrimary': {
        color: '#B9B8BF',
      },
      '& .MuiCheckbox-colorPrimary.Mui-checked': {
        color: '#1C0F66',
      },   
      '& .MuiIconButton-label': {},
    },
  },
}));
