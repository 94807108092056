import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  root: {
    marginBottom: 20,
    border: '1px solid #F1F1F3',
  },
  activeRoot: {
    marginBottom: 20,
    border: '1px solid #F1F1F3',
    boxShadow: '0px 2px 9px rgba(0, 0, 0, 0.4)',
    borderRadius: 10,
  },
  activeSideBar: {
    position: 'absolute',
    marginLeft: -1,
    width: 7,
    height: 168.5,

    /* Primary Purple */

    background: '#443699',
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    mixBlendMode: 'normal',
    transform: 'matrix(-1, 0, 0, 1, 0, 0)',
  },
  eventItem: {
    marginLeft: 10,
    '& .item': {
      backgroundColor: '#E9E7F3',
      color: '#5B4EA5',
      margin: '4px',
      borderRadius: '4px',
      minHeight: '100px',
      textAlign: 'center',
      display: 'flex',
      cursor: 'pointer',
      position: 'relative',
    },
    '& .disabled': {
      opacity: '0.5',
      pointerEvents: 'none',
    },
    '& .add-event': {
      display: 'none',
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      zIndex: 1,
      padding: '5px',
      textAlign: 'center',
      fontSize: '10px',
      boxShadow: '0 0 3px #aaa',
      borderRadius: '3px',
      fontWeight: 'bold',
    },
    '& .item:hover .add-event': {
      display: 'block',
    },
  },
  eventsHeading: {
    color: '#4B3E9D',
    margin: '0',
    padding: 5,
    float: 'left',
    fontSize: '16px',
    borderBottom: '1px solid #E5E5E6',
    '& h2': {
      color: '#4B3E9D',
      margin: '0',
      padding: '5px 0 3px 10px',
      float: 'left',
      fontSize: '16px',
      display: "flex",
      alignItems: "center",
    },
    '& .currStayHeading': {
      marginLeft: 10,
    },
    '& .StrategyIcon': {
      fontSize: 16,
      marginRight: 11,
      '& .icon-RepatriateIcon': {
        display: 'block',
        marginTop: 8,
      },
      '& .icon-TransferIcon': {
        display: 'block',
        marginTop: 5,
      },
      '& .icon-CustomIcon': {
        display: 'block',
        marginTop: 3,
      },
    },
    '& .icons': {
      float: 'right',
      fontSize: '10px',
      padding: '9px 0 0',
      cursor: 'pointer',
      '& .icon': {
        display: 'inline-block',
        padding: '0 10px 0 0',
        color: '#564AA3',
      },
      '& .active': {
        color: 'green',
      },
    },
    '& .icon': {
      position: 'relative',
    },
    '& .icon .likes': {
      display: 'none',
    },
    '& .icon.liked .likes': {
      display: 'inline-block',
    },
    '& .icon .likedBtnfilled': {
      display: 'none',
    },
    '& .icon.liked .likeBtnBorder': {
      display: 'block',
    },
    '& .icon.liked .likedBtnfilled': {
      display: 'block',
    },
    '& .icon.liked  .likeBtnBorder': {
      display: 'none',
    },
    '& .likes':{
      position: 'absolute',
      top: -10,
      right: 4,
      fontSize: 14,
    },
    '& .date': {
      display: 'flex',
      '& span': {
        margin: '12px 0px 0px 25px',
        fontSize: '13px',
        position: 'relative',
        display: 'inline-block',
      },
      '& .event-icon': {
        margin: '30px 10px 0px 0px',
      },
    },
  },
  detailsDisplay: {
    height: '100%',
    '& div': {
      margin: '3px 44px',
      display: 'block',
      padding: '7px 20px',
      borderRadius: '15px',
      backgroundColor: '#F1F1F1',
      textAlign: 'center',
      cursor: 'pointer',
    },
  },
  eventsList: {
    padding: '6px',
  },
}))

