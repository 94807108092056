import gql from 'graphql-tag'
import client from '../client'

const GET_HOSPITALS = gql`{ hospitals }`
const GET_CONDITIONS = gql`{ conditions }`
const GET_USERS = gql`{ users }`

export const getHospitals = async () => {
  const res = await client
    .query({
      query: GET_HOSPITALS
    })

  return res.data.hospitals
}

export const getConditions = async () => {
  const res = await client
    .query({
      query: GET_CONDITIONS
    })

  return res.data.conditions
}

export const getUsers = async () => {
  const res = await client
    .query({
      query: GET_USERS
    })

  return res.data.users
}