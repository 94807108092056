import React from 'react';
import clsx from 'clsx'

function EvacuateIcon({ className }) {
  return (
    <span className={clsx('icon-EvacuateIcon', className)}>
      <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.19859 10.2509C7.74503 9.48398 7.68406 8.67625 7.30515 7.22329C6.55578 4.34977 6.85418 2.77478
      9.43752 0.91011L10.6984 0L11.0034 1.52483C11.3275 3.14536 11.8597 4.14766 13.2264 6.07559C13.2807 6.15217 13.2807 6.15217 13.3352 6.22909C15.3038 
      9.00636 16.0228 10.6806 16.0228 13.721C16.0228 17.4093 12.2941 20.721 8.02279 20.721C3.75119 20.721 0.0227862 17.4098 0.0227862 13.721C0.0227862 
      13.652 0.0228569 13.6541 0.0111747 13.3494C-0.079181 10.9928 0.356596 9.14832 2.11985 7.15681C2.4924 6.73604 2.91587 6.33163 3.39241 5.94466L4.4444
      5.09041L4.95045 6.34753C5.32437 7.27642 5.76248 8.00662 6.25739 8.54246C6.67657 8.9963 6.98753 9.56702 7.19859 10.2509ZM3.61727 8.48261C2.2599 
      10.0157 1.9372 11.3816 2.00971 13.2728C2.02321 13.6249 2.02279 13.6125 2.02279 13.721C2.02279 16.2488 4.80639 18.721 8.02279 18.721C11.2389 18.721 
      14.0228 16.2484 14.0228 13.721C14.0228 11.1792 13.4545 9.85598 11.7035 7.38565C11.6493 7.30913 11.6493 7.30913 11.5947 7.2322C10.5313 5.73206 
      9.89747 4.68854 9.47812 3.52147C8.79326 4.34213 8.83353 5.15834 9.24042 6.71861C9.98979 9.59213 9.6914 11.1671 7.10805 13.0318L5.63506 14.095L5.52464 
      12.2817C5.45408 11.1232 5.19185 10.3365 4.78818 9.89944C4.38935 9.46763 4.02925 8.96209 3.70634 8.38374C3.67622 8.41661 3.64653 8.44956 3.61727 
      8.48261Z" fill="#443699"/>
      </svg>
    </span>
  )
}

export default EvacuateIcon;


