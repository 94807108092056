import React from 'react'
import { Icon } from '@material-ui/core'
import GridContainer from '../../GridContainer.jsx'
import GridItem from '../../GridItem.jsx'
import ContentBoxStyle from './ContentBoxStyle.jsx'

const ContentBox = ({
  title,
  mainTitle,
  onCancel,
  children,
  isOnWizard,
  className,
  hideClose,
  hideTitles,
  styleProps,
}) => {

  const classes = ContentBoxStyle(styleProps)

  const closeButton = () => {
    if (hideClose || isOnWizard) {
      return null
    }

    return (
      <GridItem className={classes.closeButton} onClick={onCancel}>
        <Icon className="pointer">close</Icon>
      </GridItem>
    )
  }

  return (
    <div>
      { hideTitles ||
        <GridContainer justify="space-between" className={classes.upperHeader}>
          <GridItem>{mainTitle}</GridItem>
          {closeButton()}
        </GridContainer>
      }
      <div className={classes.root} >
        { hideTitles || <div className={classes.header}>{title}</div> }
        {children}
      </div>
    </div>
  )
}

export default ContentBox
