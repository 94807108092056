import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import useStyles from './ExtendedStayStyle.jsx'
import ChevupIcon from '../../../../assets/icons/ChevupIcon.jsx'
import Dropdown from '../../../../assets/icons/DropdownIcon.jsx'
import { inject, observer } from 'mobx-react'
import { checkForLinksAndRetrieveFromContent } from './guidelineHelpers'

const ExtendedStay = inject('caseStore')(
  observer(({ caseStore }) => {
    const mcgGuideLines = caseStore.case.get('mcgGuideLines')
    const extendedStayData = mcgGuideLines ? mcgGuideLines.extendedStayData : null
    const [showExtendedStay, setExtendedStay] = React.useState(false)
    const [showIcon, setIcon] = React.useState(false)
    const classes = useStyles()

    const transformContentItemObject = (contentItem) => {
      const heading = contentItem.Text
      const bulletPoints = contentItem.ContentItems
      return { heading, bulletPoints }
    }

    const formatHeadingAndBulletPoints = (contentItem, index) => {
      const possibleHeadingWithCitations = checkForLinksAndRetrieveFromContent(contentItem)
      const { heading, bulletPoints } = transformContentItemObject(contentItem)

      return (
        <div key={index}>
          {addJSXToHeading(possibleHeadingWithCitations, heading)}
          <ul>
            {bulletPoints ? bulletPoints.map((bulletPoint, index) => addJSXtoBulletPoint(bulletPoint, index)) : null}
          </ul>
        </div>
      )
    }

    const addJSXToHeading = (possibleHeadingWithCitations, plainHeading) => {
      if (possibleHeadingWithCitations) {
        const { text, links } = possibleHeadingWithCitations
        return (
          <strong className='Stronglable'>
            {text}{' '}
            {links.map(
              (link) => <a href={link.link} target="_blank" key={link.displayId}>({link.displayId})</a>
            )}
          </strong>
        )
      } else {
        return <strong className='Stronglable'> {plainHeading}</strong>
      }
    }

    const addJSXtoBulletPoint = (bulletPoint, index) => {
      const possibleCitations = checkForLinksAndRetrieveFromContent(bulletPoint)
      if (possibleCitations) {
        const { text, links } = possibleCitations
        return (
          <li key={index}>
            {text}{' '}
            {links.map(
              (link) => <a href={link.link} target="_blank" key={link.displayId}>({link.displayId})</a>
            )}
          </li>)
      } else {

        return (
          <li key={index}>
            <a>{bulletPoint.Text}</a>
          </li>)
      }
    }

    const addJSXtoTopHeaderStrings = () => {
      const topHeaderStrings = ['MINIMAL (A FEW HOURS TO 1 DAY)', 'BRIEF (1 TO 3 DAYS)', 'MODERATE (4 TO 7 DAYS)', 'PROLONGED (4 TO 7 DAYS)']
      return topHeaderStrings.map((headerString, index) => <li key={index}><a>{headerString}</a></li>)
    }

    if (extendedStayData) {
      const mainHeaderData = checkForLinksAndRetrieveFromContent(extendedStayData.Content[0])
      return (
        <Paper className={classes.root}>
          <h3 className='mainHeading' onClick={() => { setIcon(!showIcon); setExtendedStay(!showExtendedStay) }}>
            <span className='ChevupIcon'>
              { showIcon ? <ChevupIcon /> : <Dropdown /> }
            </span>
            Extended Stay
          </h3>
          {showExtendedStay &&
            <div className='tbl'>
              <ul className='Navlinks'>
                {addJSXtoTopHeaderStrings()}
              </ul>
              {' '}
              <div className='content'>
                {addJSXToHeading(mainHeaderData, null)}
                {extendedStayData.Content[0].ContentItems.map((contentItem, index) => formatHeadingAndBulletPoints(contentItem, index))}
              </div>
            </div>
          }
        </Paper>
      )
    } else return null

  }))

export default ExtendedStay
