import React from 'react'
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles'
import MuiButton from '@material-ui/core/Button'
import Wizard from './app/views/wizard/Start.jsx'
import {
  WIZARD_PATIENT_AND_POLICY,
  WIZARD_SET_POLICY,
  WIZARD_ADMISSION_AND_CLINICAL,
  WIZARD_SET_COVERAGE,
  WIZARD_INCURRED_CHARGES,
  WIZARD_STATUS,
  WIZARD_SUMMARY,
  WIZARD_SUBMISSION,
} from './store/types'
import routes from './app/views/ViewsRoutes'
import { subDays } from 'date-fns'

const TestHospitals = {
  nyc: { value: '6210965', label: 'NYC Health + Hospitals / Woodhull' },
  inter: { value: '6870141', label: 'Intermountain Medical Center' },
  black: { value: '6450180', label: 'Black River Memorial Hospital' },
  texas: { value: '6741890', label: 'Memorial Hermann - Texas Medical Center' },
}

const TestConditions = {
  angina: { value: 'M-40', label: 'Angina' },
  rupt: {
    value: 'P-30',
    label: 'Appendectomy for Ruptured Appendix with Abscess or Generalized Peritonitis, Open or Laparoscopic, Pediatric',
  },
  cabg: { value: 'S-390', label: 'Coronary Artery Bypass Graft (CABG)' },
  orif: {
    value: 'S-740',
    label: 'Femur Fracture, Intercondylar, Open Reduction, Internal Fixation (ORIF)',
  },
  suba: {
    value: 'M-79',
    label: 'Subarachnoid Hemorrhage, Nonsurgical Treatment',
  },
}
const jsonTransform = jsObject => JSON.parse(JSON.stringify(jsObject))

const dummyTestData = jsonTransform({
  policyNumber: '123213123',
  caseNumber: '5454354',
  patientName: 'Melik Brown',
  dateOfBirth: new Date(),
  homeAddress: 'Some Home Address',
  policyStatus: 'pending',
  condition: TestConditions.cabg,
  otherDetails: 'Other details string',
  hospital: TestHospitals.nyc,
  hospitalRelations: 'preferred',
  dateOfAdmission: subDays(new Date(), 1),
  coverage: 'pending',
  currency: 'USD',
  incurredCharges: [
    { value: 124, description: 'some desc' },
    { value: 324, description: 'some desc 2' },
  ],
  totalIncurredCharges: 490,
  notify: {
    case_manager: false,
    medical_director: false,
    operations: false,
  },
  ownerId: 1,
  additionalMembers: [],
  urgency: 'high', // mellow, low, medium, high, critical,
  notes: 'some notes',
  originalLengthOfStay: 15,
})

const styles = theme => ({
  list: {
    listStyle: 'none',
    '& li': {
      display: 'inline',
      margin: 5,
    },
    button: {
      textTransform: 'none',
    },
  },
})

const states = [
  WIZARD_PATIENT_AND_POLICY,
  WIZARD_SET_POLICY,
  WIZARD_ADMISSION_AND_CLINICAL,
  WIZARD_SET_COVERAGE,
  WIZARD_INCURRED_CHARGES,
  WIZARD_STATUS,
  WIZARD_SUMMARY,
  WIZARD_SUBMISSION,
]

const TempComponentList = inject(
  'wizardStore',
  'caseStore',
  'router'
)(({ wizardStore, caseStore, classes, router }) => {
  console.log('router', router.params.state)
  const state = router.params.state || states[6]

  caseStore.set(dummyTestData)

  wizardStore.setState(state)

  const getComponentsList = () => {
    return (
      <div>
        <ul className={classes.list}>
          {states.map((st, index) => {
            return (
              <li key={index}>
                <MuiButton classes={{ label: classes.button }} variant="outlined" onClick={() => setCurrent(st, index)} data-value={st}>
                  {st.replace('WIZARD_', '').replace(/_/g, ' ')}
                </MuiButton>
              </li>
            )
          })}
        </ul>
        <Wizard />
      </div>
    )
  }

  const setCurrent = (state, index) => {
    router.goTo(routes.tempComponentList, { state })
    wizardStore.setState(state)
  }

  return getComponentsList()
})

export default withStyles(styles)(TempComponentList)
