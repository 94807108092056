import React from 'react'
import { Formik } from 'formik'
import { observer, inject } from 'mobx-react'
import * as Yup from 'yup'
import AdmissionAndClinical from './AdmissionAndClinical.jsx'

const validationSchema = Yup.object({
  condition: Yup.string('Enter other').required('Condition is required'),
  dateOfAdmission: Yup.string('Enter date')
    .nullable()
    .required('Date Of Admission is required'),
  hospital: Yup.string('Enter other').required('Hospital is required'),
  otherDetails: Yup.string('Enter other Details'),
})

const AdmissionAndClinicalContainer = inject('caseStore')(
  observer(({ caseStore, isOnWizard, backStep, nextStep, onCancel }) => {
    const handleSubmit = async values => {
      caseStore.merge(values)
      if (isOnWizard) {
        nextStep()
      } else {
        const res = await caseStore.save()
        nextStep()
      }
    }

    const compProps = {
      handleSubmit,
      isOnWizard,
      backStep,
      onCancel,
    }

    return (
      <Formik
        initialValues={caseStore.case.toJSON()}
        onSubmit={handleSubmit}
        render={props => <AdmissionAndClinical formikProps={props} {...compProps} />}
        validationSchema={validationSchema}
      />
    )
  })
)

export default AdmissionAndClinicalContainer
