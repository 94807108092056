import React from 'react'
import { inject, observer } from 'mobx-react'
import useStyles from './NavbarStyle.jsx'
import { goto } from 'app/views/ViewsRoutes'
import Badge from '@material-ui/core/Badge'

const Bell = inject('activityStore')(observer(({ activityStore }) => {
  const classes = useStyles()

  return (
    <div className={classes.actionIcon} onClick={() => goto.notifications()} >
      <Badge badgeContent={activityStore.notificationCount} color="primary">
        <img src="/assets/images/Bell.svg" />
      </Badge>
    </div>
  )
}))

export default Bell
