import React from 'react'
import { observer, inject } from 'mobx-react'
import { Paper, Box } from '@material-ui/core'
import GridContainer from '../../../../layout/GridContainer.jsx'
import GridItem from '../../../../layout/GridItem.jsx'
import useStyles from './StrategyStyle.jsx'
import EventContainer from '../event/EventContainer.jsx'
import {
  strategyDay,
  getDateFromStrategyDay,
} from '../../../../../helpers/dates.js'
import { createEvent } from '../event/EventFormInitiator.jsx'
import { strategyTypes } from '../../../../../constants.js'
import StayIcon from '../../../../assets/icons/StayIcon.jsx'
import RepatriateIcon from '../../../../assets/icons/RepatriateIcon.jsx'
import TransferIcon from '../../../../assets/icons/TransferIcon.jsx'
import EvacuateIcon from '../../../../assets/icons/EvacuateIcon.jsx'
import CustomIcon from '../../../../assets/icons/CustomIcon.jsx'
import ApproveIcon from '../../../../assets/icons/ApproveIcon.jsx'
import { numbersPrettier } from '../../../../../helpers/numbers.js'
import BorderLikeButton from '../../../../assets/icons/BorderLikeButton.jsx'
import { likeTypes } from "./../../../../../constants"
import { Delete } from '@material-ui/icons'

const Strategy = inject('dialogStore')(
  observer(({
    dialogStore,
    startDay,
    startDate,
    strategy,
    caseStore
  }) => {

  const { id, name, type, lengthOfStay, cost, events, isSelected, likes, isLiked } = strategy
  const classes = useStyles()

  const toggleLike = () => {
    const likeType = isLiked ? likeTypes.UNLIKE : likeTypes.LIKE
    caseStore.toggleLike(id, likeType)
  }

  const deleteStrategy = () => {
    caseStore.deleteStrategy(id)
  }

  const confirmDeleteStrategy = () => {
    const confirmProps = {
      text: 'Are you sure you want to delete strategy?',
      cancelText: 'Cancel',
      continueText: 'Yes'
    }
    dialogStore.openConfimrationDialog(deleteStrategy, confirmProps)
  }

  const getEventsByDay = () => {
    const eventsByDay = {}
    if (events) {
      events.forEach(event => {
        const eventDate = new Date(event.date)
        const day = strategyDay(eventDate, startDate)
        eventsByDay[day] || (eventsByDay[day] = [])
        eventsByDay[day].push(event)
      })
    }

    return eventsByDay

  }

  const openEventForm = (id, day) => {
    const date = getDateFromStrategyDay(startDate, day)
    createEvent(id, date)
  }

  const getEventsContainers = () => {
    const eventsByDay = getEventsByDay()
    return [...Array(10)].map((i, index) => {
      const day = index + 1 + startDay
      const className = day <= lengthOfStay ? 'item' : 'item disabled'

      return (
        <GridItem xs key={day}>
          <Box className={className} display="flex" flexDirection="column">
            <EventContainer
              events={eventsByDay[day] || []}
              strategyId={id}
            />
            <div className="add-event" onClick={() => openEventForm(id, day)}>
              Add Event
            </div>
            <div
              // Event container number should be check if this is the right
              // implementation and if so move it to style
              style={{
                margin: 'auto',
                position: 'absolute',
                top: '40px',
                left: 0,
                bottom: 0,
                right: 0,
              }}
            >
              {day}
            </div>
          </Box>
        </GridItem>
      )
    })
  }
  let icon =
    type == strategyTypes.STAY ? (
      <StayIcon />
    ) : type == strategyTypes.REPATRIATE ? (
      <RepatriateIcon />
    ) : type == strategyTypes.TRANSFER ? (
      <TransferIcon />
    ) : type == strategyTypes.EVACUATE ? (
      <EvacuateIcon />
    ) : type == strategyTypes.CUSTOM ? (
      <CustomIcon />
    ) : null

  return (
    <Paper
      className={isSelected ? classes.activeRoot : classes.root}
    >
      {isSelected ? <div className={classes.activeSideBar} /> : null}
      <GridContainer spacing={0} className={classes.eventsHeading}>
        <GridItem xs={12} className="heading">
          <h2 className="currStayHeading">
            <span className="StrategyIcon">{icon}</span>
            {isSelected ? 'Current - ' : ''}{name}
          </h2>
          <div className="icons">
            <span
              className="icon"
              onClick={() => {
                if (!isSelected) {
                  caseStore.lockStrategy(id)
                }
              }}
            >
              <ApproveIcon enabled={isSelected} className="">
                assignment_turned_in
              </ApproveIcon>
            </span>
            <span
              className={`icon likeBtnWrap ${likes && 'liked'}`}
              onClick={toggleLike}
            >
              <sup className="likes">{likes}</sup>
              <i className="material-icons likedBtnfilled">thumb_up</i>
              <BorderLikeButton
                className="material-icons likeBtnBorder"
                enabled={isLiked}
              />
            </span>
            <span className="icon">
              <i className="material-icons active">chat_bubble_outline</i>
            </span>
            {strategy.type !== strategyTypes.STAY ?
              <span className="icon" onClick={confirmDeleteStrategy}>
                <Delete />
              </span>
              : null
            }

          </div>
        </GridItem>
      </GridContainer>
      <GridContainer spacing={0} className={classes.eventsList}>
        <GridItem xs={10}>
          <GridContainer spacing={0} className={classes.eventItem}>
            {getEventsContainers()}
          </GridContainer>
        </GridItem>
        <GridItem xs={2}>
          <Box
            className={classes.detailsDisplay}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <div>{lengthOfStay} Days</div>
            <div>${numbersPrettier(cost)}</div>
          </Box>
        </GridItem>
      </GridContainer>
    </Paper>
  )
}))

export default Strategy
