import React from 'react'
import { observer, inject } from 'mobx-react'
import { Button } from '@material-ui/core'
import useStyles from './TeamStyles'
import GridContainer from '../../../../layout/GridContainer'
import GridItem from '../../../../layout/GridItem'
import InfoText from '../../../../components/InfoText'
import StatusAndNotificationsContainer from '../../../wizard/status-and-notifications/StatusAndNotificationsContainer'
import ContentBox from '../../../../layout/boxes/content-box/ContentBox'
import UserAvatar from '../UserAvatar'
import { userRolesCaptitalized } from './../../../../../constants' //have to use relative as constants is a used module

const EditTeam = inject('caseStore', 'dialogStore')(
  observer(({ caseStore, dialogStore }) => {
    const classes = useStyles()

    const onSubmit = () => {
      dialogStore.close()
    }

    const onClose = () => {
      dialogStore.close()
    }

    const onCancel = () => {
      dialogStore.openConfimrationDialog(onClose)
    }

    const onEdit = () => {
      dialogStore.open(
        <ContentBox
          mainTitle="Edit"
          title={'Status & Notifications'}
          onCancel={onCancel}
          isOnWizard={false}
        >
          <StatusAndNotificationsContainer
            nextStep={onSubmit}
            backStep={onCancel}
            isOnWizard={false}
          />
        </ContentBox>
      )
    }

    return (
      <Button className={classes.editButton} onClick={onEdit}>
        Edit
      </Button>
    )
  }))

const Team = inject('caseStore', 'dialogStore')(
  observer(({ caseStore, dialogStore }) => {
    const classes = useStyles()

    return (
      <GridContainer className={classes.root} direction="column">
        <GridContainer
          item
          justify="space-between"
          className={classes.marginBottom}
        >
          <GridItem>
            <InfoText label="OWNER">
              <GridContainer justify="space-around" alignItems="center">
                <GridItem xs={2}>
                  <UserAvatar avatarImage={caseStore.case.get('owner').avatar} classes={classes} />
                </GridItem>
                <GridContainer item xs={8}>
                  <GridItem>{caseStore.case.get('owner').name},</GridItem>
                  <GridItem>{userRolesCaptitalized[caseStore.case.get('owner').role]}</GridItem>
                </GridContainer>
              </GridContainer>
            </InfoText>
          </GridItem>
          <GridItem>
            <EditTeam />
          </GridItem>
        </GridContainer>
        <GridContainer item className={classes.marginBottom}>
          <GridItem>
            <InfoText label="ADDITIONAL MEMBERS" />
          </GridItem>
          <GridContainer>
            {(caseStore.case.get('additionalMembers') || []).map(member => (
              <GridItem key={member.id} className={classes.padding10}>
                <UserAvatar avatarImage={member.avatar} classes={classes} />
              </GridItem>
            ))}
          </GridContainer>
        </GridContainer>
        <GridItem className={classes.marginBottom}>
          <InfoText label="URGENCY">{caseStore.case.get('urgency')}</InfoText>
        </GridItem>
      </GridContainer>
    )
  }))

export default Team
