import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    margin: '0px 32px 16px 32px',
    width: props => props.width || 608,
  },
  header: {
    marginBottom: props =>
      props.marginBottom === 0 || props.marginBottom
        ? props.marginBottom
        : '22px',
    marginTop: '-2px',
    fontSize: 24,
    fontWeight: 600,
    color: 'black',
  },
  upperHeader: {
    padding: '22px 0 0 32px',
    color: theme.palette.primary.main,
    fontSize: props => props.titleFontSize || 14,
    lineHeight: props => props.titleLineHeight || 5,
    fontWeight: 600,
  },
  closeButton: {
    marginRight: 32,
  },
  'MuiFormControl-root': {
    color: 'black !important',
  },
  box: {
    width: '300px',
    border: '1px solid grey',
    padding: '50px',
    margin: '20px',
    'border-radius': '6px',
  },
}))

export default useStyles
