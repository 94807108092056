import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';


const useStyles = makeStyles( theme => ({
  Checkbox: {
   marginTop: 15,
   '& .MuiTypography-root': {
    color: '#87878C',
   },
  }
}));

export default function CheckboxLabels() {
  const classes = useStyles()

  const [state, setState] = React.useState({
    checkedB: false,
  });

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  return (
    <FormGroup>
      <FormControlLabel className={classes.Checkbox}
        control={
          <Checkbox
            checked={state.checkedB}
            onChange={handleChange('checkedB')}
            value="checkedB"
            color="primary"
          />
        }
        label="Remember Me"
     />
    </FormGroup>
  );
}
