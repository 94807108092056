import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { makeStyles, Dialog, Box, Button, Typography } from "@material-ui/core"
import { goto } from '../../../views/ViewsRoutes';

const useStyles = makeStyles(theme => ({
  root: {
  },
}))

export const CANCEL_RESULT_DISCARD='discard'
export const CANCEL_RESULT_SAVE_DRAFT='draft'
export const CANCEL_RESULT_BACK='back'

const WizardCancellation = inject('wizardStore', 'caseStore')(
  observer(({ wizardStore }) => {

  const classes = useStyles()

  const onCancel = type => {
        console.log('type', type)
    switch (type) {
      case CANCEL_RESULT_BACK:
        break
      case CANCEL_RESULT_SAVE_DRAFT:
      case CANCEL_RESULT_DISCARD:
        goto.caseLoad()
        break
    }
    wizardStore.hideWizardCancellationDialog()
  }


  return (
    <Dialog open={wizardStore.openWizardCancellationDialog}>
      <Box p={10}>
        <Box p={3} mb={4}>
          <Typography variant="h6">
            Are you sure about exiting the wizard?
          </Typography>
        </Box>
        <Button 
          color="secondary"
          variant="contained"
          onClick={() => onCancel(CANCEL_RESULT_DISCARD)}
         >Close & Discard</Button>
        <Box component="span" p={4}>
          <Button 
            color="secondary"
            variant="contained"
            onClick={() => onCancel(CANCEL_RESULT_SAVE_DRAFT)}
           >Save As Draft</Button>
        </Box>
        <Button 
          color="primary"
          variant="contained"
          onClick={() => onCancel(CANCEL_RESULT_BACK)}
         >Back To Wizard</Button>
      </Box>
    </Dialog>
  )
}))

WizardCancellation.propTypes = {
}

export default WizardCancellation

