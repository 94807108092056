import React from 'react'
import { makeStyles, MenuItem, FormControl, Select } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    margin: theme.spacing(3),
    minWidth: 70,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  currencySelectorWraper: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'left',
  },
}));



const CurrencySelector = ({ currency, onSelected }) => {
  const classes = useStyles()
  const [values, setValues] = React.useState({
    currency: 'USD',
  })

  function handleChange(event) {
    const newValue = event.target.value
    onSelected(newValue)
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }))
  }

  return (
    <div className={classes.currencySelectorWraper}>
      <span className={classes.text}>Currency: </span>
      <FormControl required className={classes.formControl}>
        <Select
          value={values.currency}
          onChange={handleChange}
          name="currency"
          className={classes.selectEmpty}
        >
        {
          ['USD', 'EUR', 'JPY', 'CAD'].map((item , index)=>{
            return <MenuItem value={item} key={index} className={classes.currencyOption}>{item}</MenuItem>
          })
        }

        </Select>
      </FormControl>
    </div>
  )
}

export default CurrencySelector
