import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepConnector from '@material-ui/core/StepConnector'
import StepLabel from '@material-ui/core/StepLabel'
import { RadioButtonUnchecked, RadioButtonChecked } from '@material-ui/icons'
import GridItem from '../../GridItem'
import GridContainer from '../../GridContainer'

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.secondary.main,
    padding: 0,
  },
 
  stepHorizontal: {
    padding: 0,
  },
  connectorRoot: {
    top: 10,
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: theme.palette.primary.main,
    },
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: theme.palette.primary.main,
    },
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: theme.palette.secondary.disabled,
    },
  },
  connectorLine: {
    transition: theme.transitions.create('border-color'),
    borderTopWidth: 2,
  },
  stepDisabled: {
    color: '#B9B8BF',
    fontSize: 18,
  },
  stepActive: {
    color: '#443699',
    fontSize: 18,
  },
  '@global': {
    '.MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      marginTop: 2,
    },
  },
}))

const WizStepper = props => {
  const { currentStepNumber } = props
  const classes = useStyles()
  const steps = [
    'Case Details',
    'Condition',
    'Incurred Charges',
    'Status',
    'Summary',
  ]
  // const [activeStep, setActiveStep] = React.useState(1);
  const [activeStep] = React.useState(1)

  const connector = (
    <StepConnector
      classes={{
        root: classes.connectorRoot,
        active: classes.connectorActive,
        completed: classes.connectorCompleted,
        disabled: classes.connectorDisabled,
        line: classes.connectorLine,
      }}
    />
  )

  return (
    <GridContainer
      className={classes.wizFooter}
      justify="center"
      alignItems="center"
    >
      <GridItem xs={8}>
        <Stepper
          classes={{ root: classes.root }}
          activeStep={activeStep}
          connector={connector}
        >
          {steps.map((label, index) => {
            const stepProps = {
              active: false,
            }
            const labelProps = {
              StepIconComponent: () => (
                <RadioButtonUnchecked className={classes.stepDisabled} />
              ),
            }
            if (index < currentStepNumber) {
              stepProps.completed = true
              labelProps.StepIconComponent = () => (
                <RadioButtonChecked className={classes.stepDisabled} />
              )
            } else if (index === currentStepNumber) {
              stepProps.active = true
              labelProps.StepIconComponent = () => (
                <RadioButtonChecked className={classes.stepActive} />
              )
            } else {
              stepProps.completed = false
              stepProps.active = false
              stepProps.disabled = true
            }
            return (
              <Step
                key={label}
                classes={{ horizontal: classes.stepHorizontal }}
                {...stepProps}
              >
                <StepLabel
                  {...labelProps}
                  classes={{
                    label: classes.label,
                    alternativeLabel: classes.alternativeLabel,
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </GridItem>
    </GridContainer>
  )
}

WizStepper.propTypes = {}

export default WizStepper
