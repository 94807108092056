import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import {
  FormControl,
  FormLabel,
  InputAdornment,
  IconButton,
  TextField as MuiTextField,
} from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  label: {
    marginBottom: '5px',
    color: '#232735',
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
  input: props => ({
    height: 48,
    display: 'flex',
    padding: props.multiline ? '40px 14px' : 0,
    boxSizing: 'border-box',
    borderRadius: '4px',
  }),
})

const TextField = ({ className, icon, formik, name, ...props }) => {
  const fieldName = name
  const classes = useStyles(props)
  const textFieldClasses = clsx(classes.root, className)
  const addIcon = () => {
    if (icon) {
      return (
        <InputAdornment position="end">
          <IconButton className={classes.iconButton} aria-label="Search">
            {icon}
          </IconButton>
        </InputAdornment>
      )
    }
  }

  const onTextFieldChanged = (name, e) => {
    formik.handleChange(e)
    formik.setFieldTouched(name, true, false)
    props.onChange && props.onChange(name, e)
  }

  const validationProps = () => {
    if (formik) {
      const formikProps = {
        value: formik.values[fieldName] || '',
        onChange: event => onTextFieldChanged(fieldName, event),
        helperText: formik.touched[fieldName] ? formik.errors[fieldName] : '',
        error: formik.touched[fieldName] && Boolean(formik.errors[fieldName]),
        touched: formik.touched[fieldName] ? 'true' : 'false',
      }

      return formikProps
    }
  }

  return (
    <FormControl className={classes.root}>
      <FormLabel className={classes.label}>{props.formlabel}</FormLabel>
      <MuiTextField
        variant="outlined"
        className={textFieldClasses}
        {...validationProps()}
        name={fieldName}
        InputProps={{ endAdornment: addIcon(), className: classes.input }}
        {...props}
      />
    </FormControl>
  )
}

TextField.propTypes = {
  // formlabel: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
}

export default TextField
