import React from 'react'
import { inject } from 'mobx-react'
import { makeStyles } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'
import { Box, Button } from '@material-ui/core'
import GridContainer from '../../../layout/GridContainer.jsx'
import { goto } from '../../ViewsRoutes'
import GridItem from '../../../layout/GridItem.jsx'
import useStyles from './CaseSubmissionStyle.jsx'

const CaseSubmission = inject('store', 'wizardStore')(
  ({ store, wizardStore }) => {
    const classes = useStyles()

    const gotoCaseView = () => {
      goto.case(store.caseStore.case.get('id'))
      wizardStore.reset()
    }

    const gotoCaseLoad = () => {
      goto.caseLoad()
      wizardStore.reset()
    }

    return (
      <GridContainer
        justify="center"
        direction="column"
        alignItems="center"
        className={classes.root}
      >
        <DoneIcon className={classes.icon} />
        <GridItem className={classes.ConfirmText}display="flex" mt={8}>
          Successfully submitted case
        </GridItem>
        <GridContainer
          justify="center"
          alignItems="center"
          spacing={2}
          className={classes.buttonContainer}
        >
          <GridItem>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={ gotoCaseView }
            >
              View Case
            </Button>
          </GridItem>
        </GridContainer>
        <span 
          className={classes.caseSubmissionButton}
          onClick={ gotoCaseLoad } 
        >
          Go to Case Load
        </span>
      </GridContainer>
    )
  }
)

export default CaseSubmission
