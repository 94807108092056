import React from 'react';
import clsx from 'clsx'

function AddressbookIcon({ className }) {

  return (
    <span className={clsx('icon-Addressbook', className)}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.5312 3.0125H11.2067L8.33144 0.137313C8.24356 0.049375 8.12431 0 8 0C7.87566 0 7.75644 0.049375 7.66856 0.137313L4.79338
        3.0125H0.46875C0.209875 3.0125 0 3.22237 0 3.48125V15.5312C0 15.7901 0.209875 16 0.46875 16H15.5312C15.7901 16 16 15.7901 
        16 15.5312V3.48125C16 3.22237 15.7901 3.0125 15.5312 3.0125ZM8 6.025C9.08906 6.025 9.97503 6.911 9.97503 8C9.97503 9.089 9.08906 
        9.975 8 9.975C6.91097 9.975 6.02503 9.089 6.02503 8C6.02503 6.911 6.91097 6.025 8 6.025ZM4.13703 15.0625V15.0625C4.33337 14.2802
        4.7655 13.5706 5.37975 13.0335C6.10497 12.3993 7.03556 12.05 8 12.05C8.96447 12.05 9.89506 12.3993 10.6203 13.0335C11.2345 13.5706 
        11.6667 14.2802 11.8631 15.0625H4.13703Z" fill="white"/>
      </svg>
    </span>
  )

}

export default AddressbookIcon;


