import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
  },
  editButton: {
    backgroundColor: theme.palette.secondary.light,
  },
  avatar: {
    height: 30,
    width: 30,
  },
  header: {
    color: theme.palette.grey[400],
    textTransform: 'uppercase',
  },
  text: {
    color: theme.palette.grey[900],
  },
  ownerContainer: {
    padding: 10,
  },
  marginBottom: {
    marginBottom: 20,
  },
  padding10: {
    padding: 10,
  },
}))

export default useStyles
