import React from 'react'
import { Box, Container } from '@material-ui/core'
import CarePlan from './care-plan/CarePlan.jsx'
import TeamAndActivity from './team-and-activity/TeamAndActivity.jsx'
import AdmissionAndClinicalInfo from './info/AdmissionAndClinicalInfo.jsx'
import PatientAndPolicyInfo from './info/PatientAndPolicyInfo.jsx'
import GridContainer from '../../layout/GridContainer.jsx'
import GridItem from '../../layout/GridItem.jsx'
import useStyles from './DashboardStyle.jsx'
import Strategies from './strategies/Strategies.jsx'
import GuideLines from './care-plan/guidelines/Guidelines.jsx'

export default function Dashboard() {
  const classes = useStyles()

  return (
    <Container maxWidth="lg" className={classes.container}>
      <GridContainer justify="space-between" alignItems="baseline" spacing={2}>
        <GridItem xs={12} lg={8}>
          <Box className={classes.paa}>
            <PatientAndPolicyInfo className={classes.pap} />
            <AdmissionAndClinicalInfo />
          </Box>
        </GridItem>
        <GridItem xs={12} lg={4} className={classes.teamAndActivity}>
          <TeamAndActivity />
        </GridItem>
      </GridContainer>
      <CarePlan />
      <Strategies />
      <GuideLines />
    </Container>
  )
}
