import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { makeStyles } from '@material-ui/core'
import InfoText from '../../../components/InfoText.jsx'
import InfoBox from './InfoBox.jsx'
import GridContainer from '../../../layout/GridContainer.jsx'
import GridItem from '../../../layout/GridItem.jsx'
import { cutText } from '../../../../helpers/text'
import { formatStringDate } from '../../../../helpers/dates'

const useStyles = makeStyles(theme => ({
  root: {
    height: 180,
    background: '#FFF',
  },
}))

const AdmissionAndClincalInfo = inject(
  'caseStore',
  'generalStore'
)(
  observer(({ caseStore, generalStore }) => {
    const classes = useStyles()
    const caseData = caseStore.case.toJSON()

    useEffect(() => {
      generalStore.fetchHospitals()
      generalStore.fetchConditions()
    }, [])

    return (
      <InfoBox title="Admission & Clinical" componentType="admission_and_clinical">
        <GridContainer spacing={3}>
          <GridItem xs={6}>
            <InfoText label="CONDITION">{caseData.condition.label}</InfoText>
          </GridItem>
          <GridItem xs={6}>
            <InfoText label="OTHER DETAILS">{cutText(caseData.otherDetails, 44)}</InfoText>
          </GridItem>
          <GridItem xs={6}>
            <InfoText label="ADMISSION">{formatStringDate(caseData.dateOfAdmission)}</InfoText>
          </GridItem>
          <GridItem xs={6}>
            <InfoText label="HOSPITAL">{caseData.hospital.label}</InfoText>
          </GridItem>
        </GridContainer>
      </InfoBox>
    )
  })
)

export default AdmissionAndClincalInfo
