import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ExitToApp from "@material-ui/icons/ExitToApp"
import User from 'lib/user'
import UserMenuStyle from './UserMenuStyle'

export default function UserMenu () {
    const classes = UserMenuStyle()

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleLogout = () => {
        handleClose()
        User.logout()
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
            <img src={window.app.userData.avatar} className={classes.actionIcon} onClick={handleClick} />
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <MenuItem onClick={handleLogout}  >
                    <ExitToApp /> Logout
                </MenuItem>
            </Menu>
        </div>
    )
}
