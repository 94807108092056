import { withStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    '& .mainHeading': {
      cursor: 'pointer',
    },
   '& .RecoveryHeading': {
    cursor: 'pointer',
   },
  '& .MuiTableCell-head': {
    padding: '5px 20px 5px 9px',
    borderRight: '2px solid #ffff',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
   }, 
  '& .MuiTableBody-root': {
  '& .MuiTableCell-root': {
     verticalAlign: 'text-top',
     borderBottom: 'none',
     borderRight: '2px solid #ffff', 
    '& .TableRow': {
      padding: '0px 0px 0px 5px',
      margin: 0,
      '& li': {
        color: theme.palette.text.black,
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
      },  
    },  
   },
  },
   '& .ChevupIcon': {
    padding: 6,
    cursor: 'pointer',
  }, 
  },
  table: {
    minWidth: 700,
  },
}));

export default useStyles;